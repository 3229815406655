@import 'styles/library.scss';

.DeletePopSide {
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 508px;
  justify-content: flex-end;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  height: 130px;
  transition: height 0.5s ease-out;
  padding: 24px 16px 16px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .Button {
    margin-right: $defaultMargin * 0.9;
  }

  .delete-checkbox {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
}
