@import '../../../styles/library.scss';

.chartLine-scope {
  width: 100%;
}

.chartLine_wrapper {
  width: 100%;
  display: flex;
  border: solid 2px #414145;
  border-radius: $defaultBorderRadius;
  box-sizing: border-box;
}

.chartLine_line {
  height: 2px;
  border: solid 1px #414145;
  border-radius: $defaultBorderRadius;
}
