@import '../../../../../../styles/library.scss';

.panel-header-container {
  margin: 0 13px 0 26px;
  font-size: 1.1rem;
  font-family: Rubik;
  & .panel-header-host {
    font-size: 1.4rem;
    color: $gray2;
    word-break: break-all;
  }
  & .panel-header-discovery {
    font-size: 1.1rem;
    color: $gray2;
    padding-top: 5px;
    font-weight: 200;
  }
  & .panel-header-divider {
    margin: 10px -10px 10px -20px;
    border-color: #414145 !important;
  }
  & .panel-header-score {
    font-size: 4rem;
    font-family: Rajdhani;
    font-weight: $fontBold;
    position: relative;
    top: -5px;
  }
  & .importance-type {
    padding-top: 0px;
    color: $gray2;
    font-size: 2rem;
  }
  & .panel-header-text {
    color: $gray2;
  }
  & .panel-header-importance {
    & .cyberpion-ui-text {
      padding-top: 0px;
      color: $gray2;
      font-size: 2rem;
    }
  }
  & .importance-container {
    padding-top: 5px;
  }
}
