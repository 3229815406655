@import '../../../../styles/library.scss';

.collapse-panel-discovery-internalAssets,
.collapse-panel-discovery-digitalSupplyChain,
.collapse-panel-discovery-loginAssets {
  .top-panel-discovery-internal-expand-wrapper,
  .top-panel-discovery-external-expand-wrapper,
  .top-panel-discovery-login-expand-wrapper {
    // padding: 20px 0 0 20px;
    height: 100%;
    flex-wrap: nowrap;
    & .top-panel-tech-icon {
      padding-bottom: 4px;
      padding-left: 4px;
      svg {
        path,
        line,
        rect {
          stroke: #ffffff !important;
        }
      }
    }
    & .panel-title {
      //padding-top: 10px !important;
    }
    & .org-assets-wrapper {
      padding-top: 18px;

      & .data-panel-item {
        // margin-right: 8px;
      }
    }

    & .fqdn-wrapper {
      // @media only screen and (max-width: 1730px) {
      // 	width: 1190px;
      // }
      // @media only screen and (min-width: 1731px) {
      // 	width: 1090px;
      // }
      // @media only screen and (min-width: 2400px) {
      // 	width: 2022px;
      // }
      // max-width: 600px;

      & .fqdn-icons {
        padding-top: 12px;

        & .fqdn-other {
          position: absolute;
          right: 0;
          top: 60px;
        }

        & .data-panel-item {
          margin-right: 8px;
        }

        & .fqdn-item {
          min-width: 70px;
          margin-right: 10px;

          & .fqdn-data-panel {
            padding-top: 4px;

            & .data-panel-title > div {
              width: 70px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  & .distribution-by-urgency {
    & .urgency-wrapper {
      padding-top: 13px;
    }
    & .urgency-wrapper:not(:last-child) {
      // width: 70px;
      padding-right: 15px;
    }
    & .urgency-icon {
      padding-bottom: 8px;
      padding-left: 4px;

      & .data-panel-container {
        width: 58px;
      }
    }
  }

  & .top-panel-discovery-internal-collapse-wrapper,
  .top-panel-discovery-external-collapse-wrapper,
  .top-panel-discovery-login-collapse-wrapper {
    align-items: center;
    // padding-right: 10px;

    & .top-panel-login-title {
      padding-left: 20px;
    }
    & .org-assets-value,
    .login-value {
      position: relative;
      margin-left: 8px;
      top: 0px;
    }
    & .with-svg {
      display: flex;
      align-items: center;
      svg {
        // transform: scale(0.7);
        width: 8px;
        height: 8px;
        margin-left: 2px;
        // padding-left: 4px;
        position: relative;
        top: 2px;
      }
      svg.rotate {
        transform: rotate(180deg);
        // transform: scale(0.7);
      }
    }
    & .urgency-line {
      width: 500px;
      padding-left: 12px;
    }
    & .external-assets-first-title {
      padding-left: 20px;
    }
    & .login-title {
      margin-left: 15px;
    }
  }
  & .org-assets-title-item {
    padding-right: 16px;
  }

  & .collapse-panel-discovery-loginAssets {
    //padding: 20px 20px 0 20px;

    & .insecure-data-panel,
    .invalid-data-panel,
    .login-last-30 {
      padding-top: 36px;
      .data-panel-content {
        flex-wrap: nowrap;
        & .last-30 {
          padding-top: 15px;
        }
      }
    }

    & .insecure-chart,
    .invalid-chart,
    .login-last-30 {
      margin-left: 40px;
    }

    & .data-panel-wrapper {
      flex-wrap: nowrap !important;
    }
    & .chart-size {
      // @media only screen and (max-width: 1730px) {
      // 	width: 460px;
      // }
      // @media only screen and (min-width: 1731px) {
      // 	width: 560px;
      // }
      // @media only screen and (min-width: 2400px) {
      // 	width: 880px;
      // }
    }

    & .data-panel-trend {
      padding-top: 13px;
      padding-left: 5px;
      margin-left: 0;
      width: auto;
    }
  }
}
