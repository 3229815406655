.Jira-empty-state-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .add-button {
    margin-top: 15px;
    border: 1px solid #dadde0;
    color: #5d6576;
    background-color: #ffffff;
    width: 120px;
    height: 28px;

    &:hover {
      background-color: #f7f7f7;
      border-color: #bec4c9;
    }
  }
}
