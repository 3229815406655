@import 'styles/library.scss';

.DownloadReportRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray1;
  padding-left: 16px;
  height: 40px;
  box-sizing: border-box;
  color: $gray5;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    box-shadow: 0 0 5px $gray9;
  }
  > div {
    .latest {
      background: $lightGreen2;
      color: $darkGreen2;
      padding: 2px $paddingSmall;
      border-radius: 2px;
      margin-right: $marginHalf;
      font-size: 12px;
    }
  }
  button {
    border: 0;
    background: none;
    font-size: 12px;
    color: $primaryBlue;
    font-weight: 500;
    cursor: pointer;
  }
}
