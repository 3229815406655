@import '../../../../../styles/library.scss';

.notification-add-edit-panel {
  height: 100%;
  padding: 24px;

  & .add-panel-content {
    height: 100%;
    // overflow-y: scroll;

    & .notification-properties {
      overflow: hidden;
      flex-wrap: nowrap;
      padding-right: 10px;
    }
  }

  // & .MuiOutlinedInput-root.Mui-error {
  //   border: 1px solid $red;
  // }

  & .notification-name {
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    background-color: $lighterBlue;
    height: 34px;
    font-family: Rubik;

    font-size: 13px;

    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid #ecedff;
    }
    & .MuiInputBase-input {
      color: $gray24;
    }
  }

  & .notification-filter-wrapper {
    & .trash {
      // width: 14px;
      // height: 14px;
      cursor: pointer;

      // &:hover {

      // }
    }
  }

  & .filter-menu-wrapper {
    padding-top: 0 !important;
  }
}
