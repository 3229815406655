@import 'styles/library.scss';

.LoginBackgroundAnimation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .right,
  .left {
    width: 50%;
    position: absolute;
    height: 100%;
    opacity: 0;
    transition: opacity 1s;
    transform-origin: 0;
    overflow: hidden;
    &.right {
      right: 0;
      background: linear-gradient(0deg, $brandPink 0%, $brandBlue 50%, $brandYellow 100%);
      .animation & {
        opacity: 1;
      }
    }
    &.left {
      left: 0;
      background: linear-gradient(0deg, $brandYellow 0%, $brandBlue 50%, $brandPink 100%);
      .animation & {
        opacity: 1;
      }
    }
  }
  .top,
  .bottom {
    background: $white;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: border-radius 1s;
    &.top {
      top: -50%;
      border-radius: 0;
    }
    &.bottom {
      bottom: -50%;
      border-radius: 0;
    }
  }
  .animation & {
    .top {
      border-radius: 50% 50% 50% 50% / 40% 40% 60% 60%;
    }
    .bottom {
      border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    }
  }
}
