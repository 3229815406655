@import 'styles/library.scss';

.ActionItemsOverTimeContainer {
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid $backgroundBlue;
  color: $gray5;
  border-radius: $defaultBorderRadius;
  margin-bottom: $defaultMargin;
  height: 100%;

  .overtime-wrapper {
    overflow: hidden;
    height: 329px;
    position: relative;
    width: 100%;
  }
  // position: relative;
  // padding: $defaultPadding;
  // flex: 1 0;
}
