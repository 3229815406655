@import '../../../../../../styles/library.scss';

.risk-render-scope {
  display: flex;
  align-items: center;
}
.risk-text-render {
  padding: 0 ($paddingHalf * 0.5);
  font-weight: 400;
}
