@import '../../../styles/library.scss';

.Search {
  border-bottom: 1px solid $gray2;
  display: flex;
  align-items: center;
  padding: $paddingHalf 0;
  input {
    border: 0;
    font-size: 1.2rem;
    width: 100%;
    outline: 0;
  }
}
