@import 'styles/library.scss';

.connections {
  margin: $defaultMargin $defaultMargin;

  .connections-header {
    font-size: 1rem;
    margin-bottom: $marginHalf;
  }

  .connections-content {
    display: flex;
    > div {
      margin-right: $defaultMargin;
      color: $white;
      font-weight: $fontThin;
      span {
        display: inline-block;
        margin-left: $marginSmall;
        font-weight: $fontExtraBold;
      }
    }
  }
}
