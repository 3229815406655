@import 'styles/library.scss';

.ForgotPasswordForm {
  background: $white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  opacity: 0;
  transition: opacity 1s;
  &.visible {
    opacity: 1;
  }
  > div {
    width: 100%;
    h6 {
      margin-bottom: $defaultMargin;
    }
    p {
      margin-bottom: $defaultMargin * 1.5;
      font-size: 1.2rem;
    }
  }
  .Button {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
