@import '../../styles/library.scss';
.OldDashboard {
  .top-panel-loading-state {
    position: sticky;
    z-index: 2;
    background-color: #2c2c30;
    border: 1px solid #454549;
    border-radius: 12px 12px 0 0;
  }

  .dashboard-grid {
    margin-top: 60px;
  }

  .dashboard-top {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    justify-content: flex-end;
    top: 40px;
    width: 100%;
    left: 0;
    padding-right: $paddingHalf;
    margin-bottom: 2px;
    box-sizing: border-box;
    .DownloadReports {
      border-right: 0;
      padding-right: 0;
      margin-left: 10px;
    }
    .GroupFilter header .trigger-wrapper {
      border-bottom: 0;
      margin: 0;
    }
  }

  .dashboard-row {
    display: flex;
  }

  .dashboard-item-a {
    margin-right: $defaultMargin;
    flex: 2 0;
    display: flex;
    flex-direction: column;
  }

  .dashboard-item-ticker {
    // margin-right: $defaultMargin;
    flex: 2 0;
    display: flex;
    flex-direction: column;
  }

  .dashboard-item-b {
    flex: 3 0;
    display: flex;
    flex-direction: column;
  }

  .dashboard-item-d {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .dashboard-foot-scope {
    display: flex;
    margin: $defaultMargin 0;
    flex-direction: row;
    > div {
      flex: 8 0;
      &:first-child {
        flex: 6 0;
      }
      &:last-child {
        flex: 7 0;
      }
    }
  }

  .collapse-panel-is-expand {
    #top-panel-action_items {
      padding: 20px 0px 0 20px;
      #org_assets,
      #external_assets {
        padding-right: 35px;
      }
      #connections {
        padding-right: 30px;
      }
    }

    #top-panel-org_risk_score {
      padding-top: 20px;
      & .panel {
        display: flex;
        align-items: center;
        padding-top: 15px;
      }
      & .overall-risk-score-overall_rank {
        margin-left: 60px;
        margin-bottom: 15px;

        & .letter-0,
        .letter-1 {
          color: #ffffff;
        }
      }
      & .data-panel-overall_score {
        & .data-panel-container {
          top: -17px;
          position: relative;
          left: 40px;

          & .data-panel-trend {
            padding-top: 13px;
            padding-left: 5px;
            margin-left: 0;
          }
        }
      }
    }

    #top-panel-active_protection {
      padding: 20px 20px 0 0px;

      #current_protected,
      #added_protected,
      #resolved_protected {
        padding-right: 40px;
      }
    }
  }
}
