@import 'styles/library.scss';

.PopupHeader {
  background: #2c2c30;
  margin: 8px 5px;
  border-radius: 6px;
  position: relative;
  color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: auto;
  &.error {
    border: 0 !important;
    // background: $red;
    .text {
      .input-wrapper {
        .error {
          position: absolute;
          right: 25px;
          color: $red;
          border: 0 !important;
          font-size: 1.2rem;
          padding-left: $defaultPadding;
          top: 7px;
        }
      }
    }
  }
  .close {
    transform: rotate(45deg);
    font-size: 3rem;
    font-weight: 100;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
  .text {
    padding-left: $defaultPadding;
    font-size: 2rem;
    .input-wrapper {
      position: relative;
      img {
        position: absolute;
        right: 0;
        bottom: 2px;
      }
      input {
        background: transparent;
        border: 0;
        border-bottom: 1px solid $gray9;
        font-size: 2rem;
        color: $white;
        outline: 0;
        width: 300px;
      }
    }
  }
}
