@import 'styles/library.scss';

.ErrorMessage {
  position: absolute;
  background: $white;
  bottom: -175px;
  width: 540px;
  right: 7px;
  height: auto;
  max-height: 0px;
  transition: all 0.3s;
  z-index: 999999999;
  border-radius: 0 0 6px 6px;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;

  &.visible {
    max-height: 200px;
    padding: $defaultPadding;
    bottom: 10px;
    box-shadow: 0 -1px 5px $paleBlue;
  }

  h6 {
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    margin-bottom: 18px;
    font-weight: 500;
  }

  p {
    color: $gray6;
    font-size: 1.3rem;
    line-height: 140%;
    margin-bottom: 10px;
  }

  .error-action {
    color: $gray6;
    font-size: 1.1rem;
    display: flex;
    justify-content: flex-end;
    > div {
      display: flex;
      align-items: center;
    }
    .Button:first-child {
      margin-right: $defaultMargin;
      width: 100px;
    }
  }
}
