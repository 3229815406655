@import 'styles/library.scss';

.InvestigationLeftPanel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 129px);
  background-color: #f5f6ff;

  .panel-header-open {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;

    &:after {
      content: '';
      height: 100%;
      width: 15px;
      background-image: linear-gradient(to right, $white, $gray5);
      opacity: 0.05;
    }
    .investigation-panel-tabs-wrapper {
      display: flex;
      align-items: center;
      padding: 20px 0px 20px 16px;
      width: 100%;

      .investigation-panel-tabs {
        display: flex;
        align-items: center;
        .cyberpion-ui-text {
          &:hover {
            cursor: pointer;
          }
        }
      }
      .arrows-icon {
        position: relative;
        top: 1px;
        svg {
          transform: scale(1.3) rotate(0deg);
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .investigation-panel-tab-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .panel-header-closed {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    height: 100%;
    justify-content: center;
    position: relative;
    background-color: #fff;
    .arrows-icon {
      position: absolute;
      left: 18px;
      top: 20px;
      svg {
        transform: scale(1.3) rotate(180deg);
      }
      &:hover {
        cursor: pointer;
      }
    }
    .investigation-panel-tabs {
      display: flex;
      justify-content: center;
      -webkit-transform-origin: 100% 100%;
      -webkit-transform: rotate(-90deg);
      transform-origin: 100% 100%;
      transform: rotate(-90deg);
      white-space: nowrap;
    }
    .cyberpion-ui-text {
      margin-right: 2px;
      display: inline-block;
      cursor: pointer;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
