@import 'styles/library.scss';

.AssesmentsArrayLinkRenderer {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin: $marginHalf 0;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }
}

.arrayRenderer-circle {
  width: 4px;
  height: 4px;
  margin: 0 $marginHalf;
  border-radius: 50%;
  background: $gray2;
}
