@import 'styles/library.scss';

.MuiTooltip-popper:has(> .MuiTooltip-tooltip) {
  z-index: 9999999 !important;
}
.action-item-popside-comment-menu {
  .MuiPaper-root {
    box-shadow: 0 4px 10px #e7e8ed !important;
  }
}
.action-item-popside-row-selected {
  .MuiDataGrid-cell {
    background-color: darken(#f9faff, 2%) !important;
  }
}
.action-items-popside + .popUp-scope {
  z-index: 9999999 !important;
}
.action-items-popside {
  .PopSide {
    .PopSide-inner {
      width: 905px;
      padding: 0 !important;

      .close-trigger {
        top: 21px !important;
      }
      .ActionItemsPopside {
        display: flex;
        flex-direction: column;
        // overflow-x: hidden;

        .scrollbar-common {
          scrollbar-color: white #fff !important;
        }

        .popside-content {
          .PopSideDetailsTab {
            .data-item {
              // width: 850px;
              padding: 20px 20px 0 20px;
              .cyberpion-ui-text {
                line-height: 1.4;
              }
              .groups-list {
                display: flex;
                align-items: center;

                .group-item {
                  padding: 2px 5px;
                  border: 1px solid #dfe1e4;
                  border-radius: 4px;
                  margin-right: 8px;
                }
              }
              .details-chips-list {
                display: flex;
                flex-wrap: wrap;

                .tag-item {
                  display: flex;
                  align-items: center;
                  border: 1px solid #dfe1e4;
                  background-color: #fff;
                  border-radius: 4px;
                  // padding: 2px 6px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  .delete-tag {
                    display: flex;
                    padding: 2px;
                    // margin-left: 6px;

                    img {
                      transform: scale(0.6);
                    }
                    transition: transform 0.3s;
                    &:hover {
                      transform: rotate(90deg);
                      cursor: pointer;
                      // background-color: #f9faff;
                    }
                  }
                }
              }

              .references-list {
                display: flex;
                flex-wrap: wrap;

                .references-item {
                  display: flex;
                  align-items: center;
                  border-radius: 4px;
                  padding: 3px 8px;
                  background-color: #e5f0ff;
                  margin-right: 8px;
                  margin-bottom: 8px;
                  color: #3455dd;

                  &:hover {
                    background-color: darken(#e5f0ff, 3%);
                    cursor: pointer;
                  }
                }
              }

              .add-tags-action {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .plus-wrapper {
                  margin-left: 12px;
                  .plus-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 22px;
                    width: 22px;
                    box-shadow: 0 0 3px #eee;
                    border: 1px solid #dadde0;
                    border-radius: 4px;

                    &:hover {
                      cursor: pointer;
                      background-color: #f9faff;
                    }
                  }

                  .add-tags.top-position {
                    position: relative;
                    .AddRemoveTags {
                      position: absolute;
                      left: 30px;
                      top: -235px;
                    }
                  }
                  .add-tags.bottom-position {
                    position: relative;
                    .AddRemoveTags {
                      position: absolute;
                      left: 30px;
                      top: -25px;
                    }
                  }
                }
                .btn-add-tag {
                  margin-left: 10px;
                  padding: 4px 6px;
                  width: fit-content;
                  box-shadow: 0 0 3px #eee;
                  border: 1px solid #dadde0;
                  border-radius: 4px;

                  &:hover {
                    cursor: pointer;
                    background-color: #f9faff;
                  }

                  .add-tags.top-position {
                    position: relative;
                    .AddRemoveTags {
                      position: absolute;
                      left: 60px;
                      top: -231px;
                    }
                  }
                  .add-tags.bottom-position {
                    position: relative;
                    .AddRemoveTags {
                      position: absolute;
                      left: 60px;
                      top: -24px;
                    }
                  }
                }
              }
            }
            .technical-details-container {
              padding: 20px;
              overflow-wrap: anywhere;

              .technical-details-header-scope {
                margin: 0px !important;
                margin-top: 4px !important;
              }
              .technical-details {
                font-size: 11px;
                .react-json-view {
                  background-color: #f9faff !important;
                  border: 1px solid #ececec;
                  padding: 8px;
                  border-radius: 4px;
                }
              }
            }
            .technical-container {
              padding: 5px;
              overflow-wrap: anywhere;
              background-color: #f9faff;
              border: 1px solid #ececec;
              // padding: 12px;
              border-radius: 4px;

              textarea {
                font-size: 11px;
                background-color: #f9faff;
                min-height: 130px;
                overflow-y: scroll;
                border: 0px solid #ececec !important;
                font-family: monospace;
                line-height: 1.35;
                scrollbar-color: #fff lighten(#d1d2d3, 5%) !important;
              }
            }
            .screenshot {
              .screenshot-icon {
                svg {
                  transform: scale(1.3);
                }
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .PopSideCvesTab {
            display: flex;
            flex-direction: column;
            background-color: #eaeaea;

            .cve-item {
              display: flex;
              flex-direction: column;
              // margin: 6px 0;
              background-color: #fff;

              .cve-item-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid #e8e8e8;

                .color-line {
                  width: 5px;
                  height: 50px;
                  background-color: #3455dd;
                }
                .cve-item-name-wrapper {
                  padding: 6px 10px;
                  // background-color: #f9faff;
                  background-color: #e5f0ff;
                  border-radius: 4px;
                  margin-left: 20px;
                  display: flex;
                  align-items: center;

                  .cve-item-name {
                    font-size: 13px;
                    color: #4a79e7;
                    &:active {
                      color: #4a79e7;
                    }
                  }
                  &:hover {
                    background-color: darken(#e5f0ff, 3%);
                    cursor: pointer;
                  }
                }
                .cve-item-details {
                  display: flex;
                  justify-content: flex-end;
                  flex-grow: 1;
                  padding-right: 24px;
                  font-size: 13px;
                  color: #5d6476;
                }
                .item-exploited {
                  padding: 6px 10px;
                  background-color: #ffeeee;
                  margin-right: 24px;
                  border-radius: 4px;
                  font-size: 13px;
                  a {
                    color: #fb5858 !important;
                  }
                  &:hover {
                    background-color: darken(#ffeeee, 3%);
                    cursor: pointer;
                  }
                }
              }
              .cve-item-body {
                display: flex;
                align-items: center;
                font-size: 13px;
                padding: 12px 10px 12px 26px;
                //height: 40px;
                // border-bottom: 1px solid #e8e8e8;
              }
              .cwe-item {
                // border-left: 1px solid #e8e8e8;
                padding-left: 0px;
                margin-left: 12px;
                display: flex;
              }
              .cve-item-description {
                padding: 16px 26px;
                // font-size: 13px;
                // color: #5d6476;
                .cyberpion-ui-text {
                  line-height: 1.4;
                }
              }
            }
            &:first-child {
              .cve-item {
                margin-top: 12px;
              }
            }
            .AI-Cwe-Tooltip {
              display: flex;
              flex-direction: column;
              padding: 6px 8px;
              background-color: #fff;
              max-width: 400px;
              border-radius: 4px;
              font-size: 13px;

              .cwe-main-title {
                font-size: 15px;
                font-weight: 600;
                color: #000;
                font-family: Rubik;
                margin-bottom: 10px;
              }
              .cwe-link {
                margin-bottom: 10px;
                padding: 4px 6px;
                border-radius: 4px;
                background-color: #e5f0ff;
                width: fit-content;
                a {
                  color: #3455dd;
                  &:active {
                    color: #3455dd;
                  }
                }
                &:hover {
                  background-color: darken(#e5f0ff, 3%);
                  cursor: pointer;
                }
              }
              .cwe-description {
                color: #5d6576;
              }
            }
            .AI-Cvss-Tooltip {
              display: flex;
              flex-direction: column;
              font-size: 13px;
              padding: 12px;
              background-color: #fff;
              border-radius: 4px;

              .main-title {
                font-size: 15px;
                font-weight: 600;
                color: #000;
                font-family: Rubik;
              }
              .row-item {
                display: flex;
                flex-direction: column;
                .title {
                  color: #8c909a;
                }
                .value {
                  color: #000;
                }
                .vector-string {
                  display: flex;
                }
              }
              .score-row {
                display: flex;
                align-items: center;
                background-color: #f9faff;
                padding: 10px 22px;
                border-radius: 4px;
                margin: 12px 0;

                .score-row-item {
                  display: flex;
                  flex-direction: column;
                  padding: 0 20px;
                  .title {
                    color: #8c909a;
                  }
                  .value {
                    color: #000;
                    text-transform: capitalize;
                  }
                  &:first-child {
                    padding-left: 0;
                    padding-right: 30px;
                  }
                  &:last-child {
                    padding-right: 0;
                    padding-left: 30px;
                  }
                  &:not(:last-child) {
                    border-right: 1px solid #eaeaeb;
                  }
                }
              }
              .tt-main {
                display: flex;
                // align-items: center;
                margin-top: 16px;

                .col-item-wrapper {
                  display: flex;
                  flex-direction: column;
                  margin: 0 20px;

                  // margin: 12px 0;

                  .col-item {
                    display: flex;
                    flex-direction: column;
                    // padding: 0 22px;
                    margin: 8px 0;
                    .title {
                      color: #8c909a;
                    }
                    .value {
                      color: #000;
                      text-transform: capitalize;
                      font-weight: 400;

                      &.selected {
                        color: #4a79e7;
                        font-weight: 600;
                      }
                    }
                    &:first-child {
                      margin-top: 0;
                      margin-bottom: 8px;
                    }
                    &:last-child {
                      margin-bottom: 0;
                      margin-top: 8px;
                    }
                  }
                  &:first-child {
                    margin-left: 0;
                    margin-right: 20px;
                  }
                  &:last-child {
                    margin-right: 0;
                    margin-left: 20px;
                  }
                }
              }
            }
            div:has(> .AI-Cvss-Tooltip),
            div:has(> .AI-Cwe-Tooltip) {
              background-color: #fff;
              box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07),
                0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05), 0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04),
                0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04), 0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03),
                0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
            }
          }
          .PopSideCommentsTab {
            .comment-row {
              display: flex;
              align-items: center;
              margin: 24px 8px 18px 24px;

              .comment-input {
                width: 100%;
                margin-right: 16px;

                .Mui-focused {
                  background-color: #fff !important;
                  border: 0px solid #3355dd;
                }

                fieldset {
                  &:hover {
                    border: 0px solid #3355dd !important;
                    border-color: #bfbfc0 !important;
                  }
                }
              }

              .comment-row-icon {
                svg {
                  transform: scale(1.4);
                }
                &:hover {
                  cursor: pointer;
                }
                &.disabled {
                  opacity: 0.5;
                  pointer-events: none;
                }
              }
            }
            .comment-rows-wrapper {
              overflow-y: scroll;

              .comment-row-item {
                display: flex;
                align-items: center;
                padding: 15px 24px;

                .comment-row-item-data {
                  display: flex;
                  flex-direction: column;
                  margin: 0 28px;
                }
                .comment-menu {
                  flex-grow: 1;
                  display: flex;
                  justify-content: flex-end;
                  .icon-menu {
                    svg {
                      transform: scale(1.6);
                    }
                    &:hover {
                      cursor: pointer;
                    }
                  }
                }

                &:hover {
                  background-color: #f9faff;
                }
              }
            }
          }
          .PopSideGraphTab {
            .asset-graph-button {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 230px;
              height: 24px;
              width: 150px;
              border: 1px solid #e8e8e8;
              background-color: #fff;
              border-radius: 4px;
              left: 22px;
              box-shadow: 0 1px 2px #cccccc;

              &:hover {
                cursor: pointer;
                background-color: #f9faff;
                border-color: draken(#e8e8e8, 5%);
              }
            }
          }
        }
        .ActionItemsPopSideFooter {
          display: flex;
          flex-direction: column;
          position: fixed;
          bottom: 0;
          right: 0;
          width: 100%;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);

          .required-action {
            padding: 20px 20px 0 20px;
          }
          .actions-buttons {
            display: flex;
            flex-direction: row;
            justify-content: right;
            padding: 16px;
            align-items: center;
            button {
              font-size: 13px !important;
            }
            .ScanStatus {
              button {
                height: 28px;
                text-transform: none;
                padding: 0 12px;
                // width: 100px;
              }
            }

            .btn-next {
              width: 120px;
              height: 28px;
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}
