@import 'styles/library.scss';

.Login {
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

// .wrapper {
//     width: 100%;
// }

// .login-wrapper {
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     // height: calc(100% - 10rem);
//     position: relative;
//     top: 7%;
// }
// .Login_form-wrapper {
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     position: relative;
//     top: 7%;
// }
// .Login_form {
//     text-align: center;
//     flex-direction: column;
//     display: flex;
//     align-items: center;
// }

// .input-text {
//     padding: 0.8rem 1.2rem;
//     position: static;
//     width: 30.3rem;
//     height: 3.68rem;
//     left: 0px;
//     color: #ffff;
//     top: 2rem;
//     background: #2c2c30;
//     border: 1px solid #b7bbc5;
//     box-sizing: border-box;
//     box-shadow: 0 0.44rem 0.44rem rgba(24, 29, 78, 0.1);
//     border-radius: $borderRadiusSmall;
// }

// .Login_button {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 30.3rem;
//     font-weight: bold;
//     font-size: 1.2rem;
//     color: aliceblue;
//     height: 3.68rem;
//     left: 0;
//     background: #00a3e1;
//     box-shadow: 0 0.44rem 0.44rem rgba(24, 29, 78, 0.1);
//     border-radius: $borderRadiusSmall
//     ;
// }

// .Login_lost-password {
//     color: #00a3e1;
//     font-size: 1.44rem;
//     border-bottom: $borderRadiusSmall solid #4d4d51;
//     padding: 1rem 0;
//     font-weight: 400;
//     width: 25.6rem;
//     text-align: center;
// }

// .Login_lost-password-no-border {
//     color: #00a3e1;
//     font-size: 1.44rem;
//     padding: 1rem 0;
//     font-weight: 400;
//     width: 25.6rem;
//     text-align: center;
//     cursor: pointer;
// }

// .Login_general-assistance {
//     font-size: 1rem;
//     color: #b7bbc5;
//     width: 18.72rem;
//     margin: 0.48rem 0;
//     text-align: center;
//     padding-top: 12px;
// }

// .Login_input-password {
//     background: #454549;
//     border: 1px solid #00a3e1;
//     box-shadow: 0 0.44rem 0.44rem rgba(24, 29, 78, 0.1);
//     border-radius: $borderRadiusSmall;
//     color: #b7bbc5;
// }
// .login_error-text {
//     font-size: 1rem;
//     color: #f46666;
//     padding: 0.3rem 0 0 0;
// }

// .login_input-scope {
//     // height: 5.5rem;
//     margin: 8px 0;
//     text-align: left;
// }

// .login_support-team {
//     color: #b7bbc5;
//     text-decoration: underline;
//     margin: 0 0.3rem;
// }

// .return-to-login {
//     width: 25.6rem;
//     font-size: 1rem;
//     cursor: pointer;
//     color: #00a3e1;
//     text-align: left;
//     margin: 0 0 0.75rem 0;
// }

// .Login_foot-form {
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     margin: 1.2rem 0 0 0;
// }

// .login_disabled {
//     background: #5d6576;
// }

// .expiration-text {
//     color: $white;
//     padding-top: 48px;
// }
// .spinner {
//     padding-top: 50px;
//     svg { transform: scale(2); }
// }
// .form-title {
//     color: $white;
//     padding-bottom: 10px;
// }
// .input-validation {
//     color: red;
//     padding-inline-start: 10px;
// }
// .cyberpion-logo {
//     padding-top: 30px;
//     padding-bottom: 25px;
//     svg { transform: scale(0.6); }
// }
// .cyberpion-logo-handle {
//     padding-top: 27px;
//     padding-bottom: 25px;
//     svg { transform: scale(0.6); }
// }
// .cyberpion-logo-forgot {
//     padding-top: 34px;
//     padding-bottom: 25px;
//     svg { transform: scale(0.6); }
// }
// .line {
//     width: 260px;;
//     height: 3px;
//     border-bottom: $borderRadiusSmall solid #4d4d51;
// }
// .email-sent {
//     color: $white
// }
