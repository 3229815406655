@import 'styles/library.scss';

.Popup-root {
  & .MuiPaper-root {
    width: 530px !important;

    & .meta-group-name {
      box-sizing: border-box;
      border-radius: 4px;
      overflow: hidden;
      background-color: $lighterBlue;
      height: 34px;
      font-family: Rubik;
      margin-top: 20px;

      font-size: 13px;

      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid #ecedff;
      }
      &.Mui-error {
        & .MuiOutlinedInput-notchedOutline {
          border: 1px solid #d32f2f;
        }
      }
      & .MuiInputBase-input {
        color: $gray24;
      }
    }

    & .MuiSvgIcon-root {
      width: 16px !important;
      height: 16px !important;
    }
    & .MuiTypography-root {
      font-size: 12px;
    }
  }
}
