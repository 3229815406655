@import 'styles/library.scss';

.TickerPanelContainer {
  color: $gray5;
  border-radius: $defaultBorderRadius;
  margin-bottom: 4px;
  width: 100%;

  .slick-slider {
    display: grid;
  }
}
