.RadioButton_scope {
  width: 0.8vw;
  height: 0.8vw;
  box-sizing: border-box;
  border-radius: 6.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-toggle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.RadioButton_disabled {
  background: #8c909a;
}

.RadioButton_dark-mode {
  background: #2c2c30;
  border: 1px solid #8c909a;
}

.RadioButton_normal-mode {
  background: #ffff;
  border: 1px solid #b8c3ef;
}

.RadioButton_radio-toggle-dark-mode {
  background: #8796f0;
}

.RadioButton_radio-toggle-normal-mode {
  background: #3455dd;
}
