@import 'styles/library.scss';

.TechnicalDetails {
  > div:last-child {
    margin-bottom: 70px;
  }
  .raw-data-box {
    textarea {
      height: 150px;
      background: black;
      color: rgb(153, 153, 153);
      border: 0;
    }
    .div-box {
      background: black;
      color: rgb(153, 153, 153);
      border: 0;
      padding: 10px 16px;
      font-size: 12px;
    }
  }
  .variable-row {
    overflow: hidden;
  }
}
