@import 'styles/library.scss';

li.Card {
  background: $gray10;
  border: 1px solid $gray3;
  border-radius: $defaultBorderRadius;
  margin-bottom: $defaultMargin;
  position: relative;
  box-sizing: border-box;
  .card-upper-part {
    display: flex;
    padding: $paddingHalf;
    padding-right: 0;
    border-bottom: 1px solid transparent;
    .collapse-button {
      max-width: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid $gray11;
      cursor: pointer;
      margin-left: $defaultMargin;
      padding-left: 0;
      svg {
        transform: scale(1.5) rotate(-90deg);
        transition: all 0.4s;
      }
    }
  }
  .tab-filter-wrapper {
    position: relative;
    top: 0;
    z-index: 0;
    flex: 1 0;
    .tab-filter-scope {
      padding: 0;
      background: transparent;
      transition: all 0.4s;
      min-width: 0 !important;
      .description {
        background: transparent;
        width: 14px;
        height: 14px;
        color: $gray7 !important;
        font-size: 0.8rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: -1px 0px 1px $gray3;
        margin-left: 1rem;
        cursor: default;
      }
      .test-ChartLine {
        > div {
          border-color: $gray11;
        }
        > div:nth-child(1) {
          flex: 0 0 !important;
          width: 210px;
          min-width: 210px;
          padding-right: 0;
          margin-right: 0;
          display: flex;
          .formHeader_header-scope-border {
            align-items: center;
            display: flex;
            .formHeader_header-scope {
              width: 80px;
              margin-right: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              .formHeader_header-text {
                flex-direction: column;
                .formHeader_header-label {
                  text-align: center;
                }
              }
            }
          }
        }
        > div:nth-child(2) {
          flex: 4 0 !important;
          padding-right: 0;
          margin-right: $defaultMargin;
        }
        > div:nth-child(3) {
          flex: 5 0 !important;
        }
        .summary-from-body {
          justify-content: flex-start;
          margin-top: 0;
          .ChartLineLegend-label-scope {
            align-items: center;
          }
        }
        .areaChart-scope {
          height: 80px;
        }
        .score-render {
          transform: scale(1.2);
          // margin-top: $defaultMargin;
          .score-value {
            top: auto;
            left: 30px;
          }
        }
      }
      .formHeader_count {
        height: 22px;
        font-size: 2rem;
      }
      .formHeader_header-scope {
        font-weight: 500 !important;
        border-color: $gray3;
        border-bottom: 0;
        padding-bottom: 0;
        .formHeader_header-label {
          font-size: 1.1rem !important;
        }
        .formHeader-icon {
          display: none;
        }
      }
      *:not(.__react_component_tooltip) {
        color: $gray7 !important;
      }
      .chartLine_wrapper {
        border: 2px solid $gray1;
        margin-top: 10px;
        .chartLine_line {
          border-radius: 0;
          border: 0.01rem solid $gray1;
        }
      }
      .ChartLineLegend-scope {
        > div {
          display: flex;
          .ChartLineLegend-count {
            margin-left: $marginSmall;
            font-family: $fontFamilyMain;
            font-size: 1rem;
          }
        }
        .ChartLineLegend-count {
          margin-top: 2px;
        }
      }
    }
  }
  .table {
    background: $white;
    height: 0;
    transition: height 0.4s;
    overflow: hidden;
    position: relative;
    padding-left: 1rem;
  }
  &.collapsed {
    .card-upper-part {
      border-bottom: 1px solid $gray3;
    }
    .table {
      height: 317px;
    }
    .collapse-button svg {
      transform: rotate(0) scale(1.5);
    }
  }
}
