html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: Rubik;
  background-color: #f0f2f6;
  font-weight: 400;
}

a {
  text-decoration: none;
}

code {
  font-family: Rubik;
}

#root {
  display: flex;
}

.scrollbar-common ::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.scrollbar-common {
  /*
 style for firefox
*/
  scrollbar-color: #fff #d1d2d3 !important;
}

.scrollbar-common ::-webkit-scrollbar-thumb {
  background-color: #d1d2d3 !important;
  cursor: pointer;
  border-radius: 2px !important;
}

.scrollbar-common ::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 2px !important;
}

.MuiButton-text {
  padding: 0 !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Global props for the tooltip */
.__react_component_tooltip {
  padding: 8px !important;
  font-size: 10px !important;
  line-height: 100% !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

/* .__react_component_tooltip:after {
  border-left-color: #f0f2f6 !important;
} */

.flex-wrapper {
  display: flex;
}

.align-center {
  align-items: center;
}

.uppercase {
  text-transform: uppercase !important;
}
