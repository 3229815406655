@import 'styles/library.scss';

.UserManagementActions {
  display: flex;
  align-items: center;

  .btn-add-user {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: Rubik;
    border-radius: 4px 0 0 4px;
    // padding: 2px 8px !important;
    &.disabled {
      pointer-events: none;
      background-color: #8c9099;
      border-color: #8c9099;
      // opacity: 0.5;
      color: #fff;
    }
    &.regular-border-radius {
      border-radius: 4px;
    }
  }
  .icon-info-add-user {
    position: relative;
    top: 0px;
    padding-left: 2px;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #3355dd;
    padding: 4px 5px 1px 5px;
    background-color: #fff;
    &.disabled {
      border-color: #8c9099;
      &:hover {
        background-color: #f4666610;
      }
    }
  }
  .actions-trigger {
    margin-left: $marginHalf;
    font-size: 1.1rem;
    position: relative;
    .trigger-wrapper {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }
    .trigger-wrapper svg {
      transition: all 0.3s;
    }
    &.open {
      ul {
        display: block;
      }
      .trigger-wrapper svg {
        transform: rotate(180deg);
      }
    }
    ul {
      position: absolute;
      background: $white;
      padding: $paddingHalf;
      list-style-type: none;
      top: 10px;
      right: 0;
      border: 1px solid $gray1;
      display: none;
      li {
        cursor: pointer;
        &.disabled {
          pointer-events: none;
          // color: $gray4;
          opacity: 0.2;
        }
      }
      &.open {
        display: block;
      }
    }
  }
}
