@import '../../../styles/library.scss';

.select-wrapper {
  position: relative;
  font-size: 1.2rem;
  color: $gray5;
}

.select-header-wrapper {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.select-body-wrapper {
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  position: absolute;
  background: #ffffff;
  z-index: 1;
  right: -15px;
  top: 25px;
  padding: $defaultPadding;

  .sub-menu-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    .submenu-icon {
      position: relative;
      top: -2px;
      transform: scale(1.1) rotate(180deg);
      margin-right: 6px;
    }
  }
}
.sub-menu-disabled-item {
  display: flex;
  align-items: center;
  margin-left: 6px;
}
.select-item-scope {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
  &.disabled,
  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  .submenu-icon {
    position: relative;
    top: 1px;
    transform: scale(1.1);
    margin-left: 6px;
  }
  .tooltip-icon {
    margin-left: 6px;
  }
}

.select-label {
  margin: 0 0 0 5px;
}
