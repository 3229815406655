@import 'styles/library.scss';

.EvidenceData {
  display: flex;
  overflow: hidden;
  flex: 1;
  position: relative;
  z-index: 1;
  flex-direction: column;
  .scrollbar-common {
    display: flex;
    overflow: hidden;
    flex: 1;
    position: relative;
    z-index: 1;
  }
  // > .EvidenceCategoryLabel {
  //   pointer-events: none;
  //   position: absolute !important;
  //   top: 40px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   z-index: 3;
  //   .label {
  //     transform: scale(1);
  //     opacity: 1;
  //     transition: none;
  //   }
  // }
}
.content-header {
  list-style-type: none;
  display: flex;
  padding: 0;
  position: relative;
  z-index: 2;
  border-top: 1px solid #dfe1e4;
  // border-bottom: 1px solid #dfe1e4;
  width: 100%;
  > span {
    flex: 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 17px;
    font-size: 12px;
    font-weight: 500;
    color: $gray8;
    > img {
      margin-right: 10px;
    }
  }

  > .line {
    position: absolute;
    left: calc(50% - 1px);
    height: 60%;
    top: 20%;
    width: 1px;
    background: #dfe1e4;
  }
}
.content-area {
  overflow-y: auto;
  flex: 1;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 30px;
    background-image: linear-gradient(rgba(#5886fb, 0.05), transparent);
  }

  .DiscoveryEvidenceDataFlow {
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 3;
    position: relative;
    margin-top: 30px;

    &:first-child {
      margin-top: 10px;
    }
  }
}
