[class*='common-button'] {
  height: 3.3rem;
  padding: 0 0.94rem;
  background-color: var(--button-color-default);
  text-align: center;
  color: #ffffff;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-family: Rubik;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
