@import 'styles/library.scss';

.grid-link-field {
  color: $gray5;
  line-height: 100%;
  display: flex;
  cursor: pointer;
  &:hover {
    color: $primaryBlue;
  }
}

div.grid-link-field {
  &:hover {
    color: #3455dd !important;
  }
}
