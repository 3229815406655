@import 'styles/library.scss';

.ThreatCenterTopBar {
  display: flex;
  min-height: 50px;
  border-bottom: 1px solid #eaeaea;
  padding: 3px 28px;
  align-items: center;
  justify-content: space-between;

  .Search {
    border-bottom: 0px;
    width: 250px;
    flex-grow: 1;
  }

  .threat-center-subscribe {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    margin-right: 4px;
    border-radius: 4px;
    border: 1px solid #f0f2f6;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    padding: 4px 8px 3px 8px;

    &:hover {
      cursor: pointer;
      border-color: #bec4c9;
      background-color: #f7f7f7;
    }
    &.active {
      background-color: darken(#f9faff, 2%);
    }

    &.loading {
      position: relative;
      min-height: 16px;
      margin-left: -$defaultMargin !important;
      svg {
        margin: 0 !important;
      }
    }

    .subscribe-icon {
      margin-right: 5px;
    }
  }
  .subscribe-dropdown {
    margin-right: 12px;

    &.loading {
      position: relative;
      min-height: 16px;
      margin-left: -$defaultMargin !important;
      svg {
        margin: 0 !important;
      }
    }
  }
  .top-bar-switch {
    display: flex;
    align-items: center;
    margin-left: 12px;

    .show-only-findings {
      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
}
