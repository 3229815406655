.CNAPPAlertGeneralTab {
  padding: 20px;
  .validation {
    .validation-status {
      display: flex;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      align-items: flex-start;
      padding: 12px 16px;
      //   border-radius: 6px;

      &.na {
        .validation-status-wrapper {
          > span {
            color: #5d6576;
          }
        }
      }
      &.partially {
        border-radius: 6px 6px 0 0;
        border-bottom: 0;

        .validation-status-wrapper {
          > span {
            color: #dfb940;
          }
        }
      }
      &.available {
        border-bottom: 0;
        border-radius: 6px 6px 0 0;
        .validation-status-wrapper {
          > span {
            color: #4fa686;
          }
        }
      }
    }

    .validation-status-wrapper {
      display: flex;
      align-items: center;
      > span {
        font-size: 1.3rem;
        display: inline-block;
        margin-left: 6px;
        font-weight: 500;
      }
    }

    .validation-info {
      border: 1px solid #e8e8e8;
      border-radius: 0 0 6px 6px;
      min-height: 50px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &.remotely-exploitable,
      &.exploitable,
      &.remotely-exploitable-in-another-way {
        background: #fef7f7;
        .color {
          color: #f46666;
        }
      }

      &.not-remotely-exploitable,
      &.not-exploitable,
      &.practically-not-exploitable {
        background: #f6fbf9;
        .color {
          color: #4fa686;
        }
      }

      &.unknown {
        background: #fefbf3;
        .color {
          color: #e6b20e;
        }
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        li {
          margin-right: 50px;
          min-width: 150px;
          &:last-child {
            margin-right: 0;
          }
          h6 {
            margin: 0 0 4px 0;
            padding: 0;
            font-size: 1.3rem;
            font-weight: 400;
            color: #5d6576;
          }
          p {
            margin: 0;
            padding: 0;
            font-weight: 500;
            font-size: 1.3rem;
            color: #454549;
          }
        }
      }
    }

    .reasons-wrapper {
      font-weight: 400;
      margin-left: 32px;
      font-size: 1.3rem;
      color: #5d6576;
      ul {
        list-style-type: none;
        display: inline-flex;
        margin: 0 0 0 6px;
        padding: 0;
        flex-wrap: wrap;
        max-width: 575px;
        li {
          font-weight: 500;
          display: flex;
          align-items: center;
          color: #60646a;
          &:not(:first-child):before {
            content: '';
            width: 5px;
            height: 5px;
            background: #60646a;
            border-radius: 100%;
            display: inline-flex;
            margin: 0 6px;
          }
        }
      }
    }
  }
  .line {
    position: relative;
    width: 1px;
    height: 30px;
    background: #eaeaea;
    margin-left: 30px;
  }
  .alert-info-header {
    display: flex;
    align-items: flex-start;
    > div {
      margin-left: 16px;
      h5 {
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
        font-weight: 500;
        color: #454549;
      }
      p {
        color: #5d6576;
        font-size: 1.4rem;
        margin: 4px 0 0 0;
        padding: 0 100px 0 0;
        font-weight: 400;
        line-height: 140%;
        white-space: pre-wrap;
      }
      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        li {
          border: 1px solid #e8e8e8;
          border-radius: 3px;
          padding: 3px 6px;
          color: #5d6576;
          margin-right: 12px;
          font-size: 1.3rem;
          font-weight: 400;
          margin-top: 12px;
        }
      }
      h6 {
        font-size: 1.3rem;
        font-weight: 500;
        margin: 16px 0 0 0;
        padding: 0;
        color: #454549;
      }
    }
  }
}
