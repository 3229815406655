@import 'styles/library.scss';

.Comments {
  // box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05),
  //   0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04),
  //   0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
  .comments-cell {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    .icon {
      position: relative;
      top: 2px;
    }
    .count {
      margin-left: 6px;
    }
  }
}
