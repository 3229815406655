.integration-link {
  display: flex;
  flex-direction: column;
  // align-items: center;
  position: relative;

  .link-field {
    display: flex;
    // align-items: center;
    // height: 40px;
    padding: 10px;
    // width: 100%;
    background-color: #f9faff;
    border: 1px solid #ececec;
    border-radius: 4px;

    &.error {
      border: 1px solid #ff0000;
    }
    .link {
      margin-left: 8px;
      margin-right: 25px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #3659de;
      }
    }
    .clipboard {
      position: absolute;
      top: 38px;
      right: 6px;
      width: 24px;
      height: 24px;
      // background-color: #fff;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 4px;
      svg {
        transform: scale(1.2);
      }
      &:hover {
        cursor: pointer;
        background-color: #ecedff;
      }
    }
  }

  .error-text {
    margin-top: 12px;
  }
}
