@import 'styles/library.scss';

.APITokensActions {
  display: flex;
  align-items: center;
  position: relative;
  input[type='text'] {
    margin-right: $marginHalf;
    padding: 6px;
    font-size: 1.1rem;
    border: 1px solid $gray9;
    border-radius: $borderRadiusSmall;
    width: 220px;
    outline: 0;
    &.error {
      border-color: $red;
    }
  }
  .link-to-api {
    position: absolute;
    top: -40px;
    right: 0;
    font-size: 1.1rem;
    a {
      color: $primaryBlue;
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: underline;
      }
      img {
        margin-right: $marginSmall;
      }
    }
  }
  .date-picker-wrapper {
    margin: 0 $marginHalf;
    &.error {
      border: 0 !important;
      .MuiFormControl-root {
        border-color: $red !important;
      }
    }

    .MuiFormControl-root {
      background: $white;
      border-radius: $borderRadiusSmall;
      border: 1px solid $gray9;
    }
    input[type='tel'] {
      padding: 6px;
      font-size: 1.1rem;
      border: 0;
      // background: $white;
      width: 72px;
      outline: 0;
      height: auto;
      text-transform: uppercase;
    }
    fieldset {
      outline: 0;
      border-color: $gray9 !important;
      border-width: 1px !important;
      visibility: hidden;
    }
  }

  .actions-trigger {
    margin-left: $marginHalf;
    font-size: 1.1rem;
    position: relative;
    .trigger-wrapper {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }
    .trigger-wrapper svg {
      transition: all 0.3s;
    }
    &.open {
      ul {
        display: block;
      }
      .trigger-wrapper svg {
        transform: rotate(180deg);
      }
    }
    ul {
      position: absolute;
      background: $white;
      padding: $paddingHalf;
      list-style-type: none;
      top: 10px;
      right: 0;
      border: 1px solid $gray1;
      display: none;
      li {
        cursor: pointer;
        &.disabled {
          pointer-events: none;
          // color: $gray4;
          opacity: 0.2;
        }
      }
      &.open {
        display: block;
      }
    }
  }
}
