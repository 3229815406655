.DiscoveryScanStatus {
  display: flex;
  align-items: center;
  .icon {
    position: relative;
    top: 1px;
    svg {
      transform: scale(1.2);
    }
  }
}
