@import 'styles/library.scss';

.Evidence {
  margin-bottom: $marginHalf;
  > header {
    display: flex;
    cursor: pointer;
    .rotate {
      transform: rotate(0);
    }
    .urotate {
      transform: rotate(-90deg);
    }
  }
  .evidence-list {
    max-height: 0;
    margin-left: $defaultMargin * 1.5;
    transition: max-height 0.5s;
    overflow: hidden;
    > li {
      margin-top: $defaultMargin;
      textarea {
        width: 80%;
        height: 140px;
      }
    }
    &.visible {
      max-height: 2000px;
    }
    header {
      font-size: 1.3rem;
      color: $gray4;
      margin-bottom: $marginHalf;
    }
    img {
      width: 80%;
      border: 1px solid $gray9;
      border-radius: 4px;
    }
    .TechnicalDetails {
      width: 80%;
      > div:last-child {
        margin-bottom: $defaultMargin;
      }
      .technical-details-body-header {
        display: none;
      }
    }
  }
}
