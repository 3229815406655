@import '../../../../../../styles/library.scss';

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filtersWrapper-wrapper {
  display: flex;
  flex-flow: wrap;
  margin-left: $marginSmall;
  .flex-wrapper {
    position: relative;
    margin-right: 2px;
  }
}

.filtersWrapper-clear-filters {
  color: $gray5;
  min-height: 25px;
  cursor: pointer;
  white-space: pre;
  display: flex;
  align-items: center;
  margin-left: $marginSmall;
  font-weight: 500;
  font-size: 1rem;
  line-height: 100%;
}

.filtersWrapper-wrapper-moreOptions {
  display: flex;
  .active-filters-wrapper {
    margin-right: 100px;
    display: flex;
    flex-flow: wrap;
  }
}

.filtersWrapper-clear-filters-text {
  margin-right: $marginSmall;
}

.filtersWrapper-clear-filters-event {
  font-size: 1.2rem;
  cursor: pointer;
  transform: rotate(45deg);
}
