@import '../../styles/library.scss';

.ttG-d3-scope {
  position: absolute;
  color: #5d6576;
  z-index: 10000;
  padding: $paddingHalf;
  font-weight: bold;
  opacity: 1;
  background: #f0f2f6;
  border-radius: $defaultBorderRadius;
  display: none;
  font-size: 1rem;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
}

.ttG-header-scope {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ttG-d3-header {
  font-size: 1rem;
}

.ttG-d3-date {
  font-size: 1rem;
  margin: $marginHalf 0 0 0;
  color: #8c909a;
}

.last-scan-add {
  color: #24d9ad;
}

.last-scan-remove {
  color: #f46666;
}

.last-scan-scope {
  margin: $marginHalf 0 0 0;
  padding: 0;
  font-size: 1rem;
  color: #8c909a;
  list-style-position: inside;
}

.ttG-d3-pointer-left {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  border-width: 1px;
  border-style: solid;
  border-color: #f0f2f6 transparent transparent transparent;
}

.ttG-d3-pointer-right {
  content: '';
  position: absolute;
  top: 40%;
  left: 104%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #847f94;
}

.ttG-d3-border {
  position: relative;
  bottom: 4px;
}

.ttG-d3-text {
  font-size: 1.1rem;
  white-space: nowrap;
}

.ttG-d3-value {
  padding: 0 $paddingHalf;
}

.ttG-d3-num {
  padding: 0 $paddingHalf 0 0;
}
