@import 'styles/library.scss';

.AddCommentPopup {
  position: fixed;
  background: $white;
  width: 450px;
  border-radius: 8px;
  box-shadow: 0px 0px 50px 0px rgba(44, 44, 48, 0.5);
  padding: 20px;

  .text-area {
    border-radius: 6px;
    border: 1px solid #ebecff;
    background-color: #f7f8ff;
    padding: 8px;
    margin-bottom: 20px;

    textarea {
      border: none;
      background-color: #f7f8ff;
      height: 100px;
      color: #8c909a;
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 8px;
    }
  }
}
