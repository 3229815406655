@import 'styles/library.scss';

.NoFilterOptions {
  display: flex;
  align-items: center;
  // padding: $paddingSmall 0;
  img {
    margin-right: $marginSmall;
  }
}
