@import 'styles/library.scss';

.SelectAll {
  display: flex;
  align-items: center;
  margin-top: $marginHalf;
  cursor: pointer;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
