@import 'styles/library.scss';

.spinnerRenderer-scope {
  display: flex;
  align-items: center;
}

.spinnerRenderer-text-scope {
  display: flex;
  align-items: center;
  border: solid 1px #c5c6d6;
  height: 14px;
  font-size: 10px;
  border-radius: $defaultBorderRadius;
  width: auto;
  padding: 1px 4px;
  justify-content: center;
  margin-right: 4px;
}
