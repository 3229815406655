@import 'styles/library.scss';

.ServiceNow {
  .MuiOutlinedInput-root {
    border: 1px solid $paleBlue !important;
    &.Mui-error {
      border: 1px solid $red !important;
    }
  }
  .service-now-button {
    border: 1px solid #dadde0;
    color: #5d6576;
    background-color: #ffffff;

    &.active {
      color: #3455dd;
      border: 1px solid #3455dd;
      background-color: #ffffff;
    }

    &.marginLeft {
      margin-left: 10px;
    }

    &.marginRight {
      margin-right: 10px;
    }
  }

  .yes-button-popup {
    background-color: #f46666;
    opacity: 1;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .text-field-wrapper {
    .MuiOutlinedInput-root.Mui-error {
      border: 1px solid $red !important;
    }
  }
}
