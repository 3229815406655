@import 'styles/library.scss';

.DynamicMultiChoiceDropDown {
  position: relative;
  min-width: 179px;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  header {
    border: 1px solid $gray1;
    border-radius: $borderRadiusSmall;
    color: $gray3;
    padding: $paddingSmall $paddingHalf;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    background: $white;
    .AddUserPopUp & {
      padding: $paddingHalf;
      border: 1px solid $gray3;
    }
    cursor: pointer;
    &.empty {
      opacity: 0.7;
      cursor: no-drop;
    }
    svg {
      transition: all 0.3s;
    }
    &.selected {
      color: $gray5;
    }
  }
  > div {
    position: fixed;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    // width: 100%;
    max-height: 0;
    opacity: 0;
    z-index: 1;
    overflow: hidden;
    // top: 100%;
    border: 1px solid $gray1;
    background: $white;
    border-radius: $defaultBorderRadius * 0.5;
    transition: all 0.3s;
    box-sizing: border-box;
    .AddUserPopUp & {
      position: absolute;
      width: 100%;
    }
    &.visible {
      max-height: 160px;
      opacity: 1;
      background-color: $white;
    }
    .search {
      border: 1px solid $gray1;
      border-radius: $borderRadiusSmall;
      display: flex;
      align-items: center;
      padding: 0 $paddingSmall;
      margin: $marginSmall;
      input {
        padding: $paddingSmall 0 !important;
        margin-right: 0;
        border: 0 !important;
        box-sizing: border-box;
        margin-left: $marginSmall;
        outline: 0;
      }
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 174px;
    overflow: scroll;
    margin-right: $marginSmall;
    li {
      padding: $paddingSmall;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:last-child {
        margin-bottom: 80px;
      }
      svg {
        margin-right: $marginSmall;
      }
    }
  }
  .clear {
    position: absolute;
    bottom: 0;
    background: $gray1;
    padding: $paddingHalf;
    z-index: 1;
    width: 100%;
    display: flex;
    color: $gray3;
    box-sizing: border-box;
    cursor: pointer;
    &.active {
      color: $gray5;
    }
    span {
      font-size: 1.1rem;
      margin-left: $marginSmall;
    }
  }
}
