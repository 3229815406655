@import 'styles/library.scss';

.CollaspsedDiagram {
  &.expand {
    .collapsable-area {
      max-height: 3000px;
      &:after {
        display: none;
      }
    }
  }
  .collapsable-area {
    max-height: 60px;
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 8px;
      background-image: linear-gradient(transparent, #ffffff);
    }
  }
  > span {
    display: inline-block;
    background: $white;
    padding: 5px 0;
    font-weight: 400;
    color: $primaryBlue;
    cursor: pointer;
  }
}
