@import 'styles/library.scss';

.RadarLegendPDF {
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 15px;
    &.empty {
      background: #fff;
      border: 2px solid #eee;
      width: 20px;
      height: 20px;
    }
    &.number-of-findings {
      background: #fff;
      border: 2px solid #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      &:after {
        content: '';
        font-size: 8px;
        color: #666;
      }
    }
    .label {
      text-transform: capitalize;
    }
  }
}
