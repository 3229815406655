.CNAPPAlertRawTab {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  textarea {
    height: 100%;
    background: #f9faff;
    border: 1px solid #ececec;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 20px;
  }
}
