@import 'styles/library.scss';

.ConfirmedMessage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    margin-bottom: $defaultMargin;
  }
  p {
    text-align: center;
    width: 55%;
    margin: 0 0 $defaultMargin 0;
  }
  .Button {
    width: auto;
    padding: 0 $defaultPadding * 2;
  }
}
