@import 'styles/library.scss';

.AdditionalSeedAssets,
.CIDRWrapper {
  position: absolute;
  width: 320px;
  height: 0;
  background: $white;
  box-shadow: 0 0 6px #eee;
  overflow: hidden;
  top: 20px;
  border-radius: 4px;
  &.visible {
    height: auto;
    z-index: 2;
    border: 1px solid $paleBlue;
  }

  ul.cidr-list {
    list-style-type: none;
    overflow: auto;
    padding: $defaultPadding;
    margin: 0;
    max-height: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $paleBlue;
      border: 1px solid $cpBlue;
      // margin-right: $defaultMargin;
      margin-bottom: $defaultMargin;
      padding: 2px 8px;
      font-size: 1.1rem;
      border-radius: 4px;
    }
  }
  ul.main-list {
    list-style-type: none;
    overflow: auto;
    height: 200px;
    padding: 20px;
    margin: 0;
    > li {
      margin-top: $defaultMargin;
      border: 1px solid $paleBlue;
      border-radius: 4px;
      min-height: 30px;
      padding: $paddingHalf * 1.5;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
