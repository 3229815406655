@import 'styles/library.scss';

.page-not-fount{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100vh;

    img {
        height: 100px;
        margin-bottom: $defaultMargin;
    }

    h1 {
        font-size: $page404FontSize;
        font-weight: 600;
        color: $gray2;
        margin: 0 0 $defaultMargin 0;
        line-height: $page404FontSize;
    }

    h2 {
        color: $gray6;
        font-size: 1.6rem;
        margin: 0 0 $marginHalf 0;
        font-weight: $fontBold;
    }

    div {
        font-size: 1.2rem;
        color: $gray4;
    }

    button {
        width: 270px;
        margin-top: $defaultMargin;
        background: $primaryBlue;
        color: $white;
        padding: $paddingHalf 0;
        border: 1px solid $primaryBlue;
        outline: 0;
        border-radius: $borderRadiusSmall;
        cursor: pointer;
        font-weight: $fontBold;
        font-size: 1.2rem;
    }
}