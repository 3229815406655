@import 'styles/library.scss';

.info-panel-container {
  .InfoPanel {
    height: 100%;
    max-width: 0;
    background: $white;
    position: relative;
    transition: flex-basis 0.3s;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    transition: max-width 0.3s;
    overflow: hidden;

    &.open {
      max-width: 330px;
      border-left: 1px solid rgba(224, 224, 224, 1);
    }

    & .close-trigger {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 2;
      cursor: pointer;
      transition: transform 0.3s;
      &:hover {
        transform: rotate(90deg);
      }
    }
    .scrollbar-common {
      scrollbar-color: white #ccc !important;
      flex: 1 0;
    }

    .info-panel-content {
      height: 100%;
      position: relative;
    }
  }
  .left-shadow {
    height: 100%;
    width: 15px;
    background-image: linear-gradient(to right, $white, $gray5);
    opacity: 0.05;
  }
}
