@import 'styles/library.scss';
.Flag {
  img {
    border-radius: 2px;
  }
  .value {
    display: inline-block;
    font-size: 1rem;
    margin-left: $marginSmall;
  }
}
