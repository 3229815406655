@import '../../../../../../../styles/library.scss';

.dropdownHeader_scope {
  display: flex;
  justify-content: space-between;

  align-items: center;
  font-size: 1.2rem;
  border-radius: $defaultBorderRadius;
  margin-left: 1px;
}

.dropdownHeader_toggle-dropdown {
  background: #5d6576;
  color: $gray2 !important;
  * {
    color: $gray2 !important;
  }
}

.dropdownHeader_dropdown-label {
  display: flex;
  align-items: center;
  padding: 0 $paddingHalf;
  font-weight: 400;
  .flex-wrapper {
    color: $gray5;
  }
}

.dropdownHeader_active-filter {
  border: solid 1px #5d6576;
  color: #5d6576;
}

.dropdownHeader_label {
  // padding-right: 4px;
  color: $gray5;
}

.dropdownHeader_filter-clean {
  transform: rotate(45deg);
  color: #3455dd;
  margin-right: $marginSmall;
  font-size: 1.4rem;
  cursor: pointer;
}

.dropdownHeader_toggle-dropdown-and-filter-clean {
  color: $gray6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownHeader_sub-lable {
  display: inline-block;
  min-width: 1px;
  padding: 2px 0.38rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  background-color: #97a0af;
  color: #172b4d;
  margin: 0 0.2rem;
}
.dropdownHeader_one-value {
  margin: 0 2px;
  // color: $gray2;
}
