@import '../../../../../../styles/library.scss';

.formHostingProvier-scope {
  display: flex;
  color: $white;
  width: 100%;

  > div:not(.__react_component_tooltip) {
    display: flex;
    flex-direction: column;
    max-width: 100px;
    // margin-right: $marginHalf;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;

    > svg {
      margin-bottom: $marginHalf;
      transform: translateX(-2px);
    }
  }
}

.formHostingProvier_label {
  font-size: 1rem;
  opacity: 0.8;
  margin-right: $marginHalf;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.formHostingProvier_count {
  font-weight: $fontBold;
  font-size: 1.4rem;
}

.formHostingProvier-from-scope {
  width: 50%;
  position: relative;
}
