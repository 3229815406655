@import '../../../../styles/library.scss';

.headerForm-dashboard-header-scope {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $gray4;
  border-bottom: 1px solid $gray1;
  margin-bottom: $defaultMargin;
  padding-bottom: $paddingHalf;
  font-size: 1.2rem;
}

.headerForm-dashboard-label-scope {
  display: flex;
  align-items: center;
  color: $gray6;
  font-weight: 400;
  font-size: 1.2rem;
}

.headerForm-dashboard-label {
  padding-left: $paddingHalf;
}
