@import 'styles/library.scss';

.relationship-item-box {
  width: 222px !important;
  height: 70px;
  border-radius: 6px;
  border: 1px solid #dadde0;
  background: #fff;
  padding: 10px 15px;
  margin: 0 2px;

  &:hover {
    cursor: pointer;
    background-color: $lighterBlue;
  }
  &.selected {
    background-color: $lighterBlue;
    border: 1px solid #3455dd;
  }
}
