@import 'styles/library.scss';

.AddAssetsPopUp {
  position: fixed;
  background: $white;
  width: $popUpWidth;

  .popup-inner {
    padding: $paddingHalf;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .message {
      font-size: 1.2rem;
      line-height: 150%;
      color: $gray5;
      div {
        &:first-child {
          margin-top: $marginHalf;
        }
        &:last-child {
          margin: $marginSmall 0 $defaultMargin 0;
        }
      }
    }

    textarea {
      height: 200px;
      & + div {
        margin-top: $marginSmall;
      }
    }

    .consent {
      margin: $defaultMargin 0;
      color: $gray5;
      display: flex;
      align-items: center;
      font-size: 1.1rem;
      cursor: pointer;
      &.error {
        border: 0 !important;
        color: $red;
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      margin: $marginHalf 0;
      button {
        margin-left: $marginHalf;
      }
    }
  }
}
