@import '../../../../../../../styles/library.scss';

.filterGrid_scope {
  position: absolute;
  color: #6d6d7a;
  background: $white;
  box-shadow: 0 0 0.6em rgba(24, 29, 78, 0.1);
  border-radius: $defaultBorderRadius;
  z-index: 1;
  top: 35px;
  padding: $defaultPadding $paddingHalf $paddingHalf $paddingHalf;
  width: 200px;
  font-size: 1.2rem;

  .actions {
    display: flex;
    align-items: stretch;
    height: 24px;
    margin-bottom: 16px;
    .ToggleButtons {
      margin-right: 0px !important;
    }
    .ToggleButtons > span {
      display: flex;
      justify-content: center;
      width: 50% !important;
    }
    .Button {
      margin-right: 10px;
      width: auto;
      box-shadow: none;
      height: 24px;
      box-sizing: border-box;
      padding-right: 5px;
      padding-left: 10px;
    }
    .size-change {
      display: flex;
      align-self: center;
      cursor: pointer;
    }
  }
}

.filterGrid-header-scope {
  display: flex;
  justify-content: space-between;
  color: #3455dd;
  border-bottom: solid 2px #3455dd;
  cursor: pointer;
}

.filterGrid-item {
  margin: 0.8em 0;
  cursor: pointer;
}

.input-type {
  border-bottom: solid 1px #ececec;
  margin: 0.75rem 0 0 0;
}

.filterGrid-button-scope {
  display: flex;
  justify-content: center;
  padding-top: $paddingHalf;
  > button {
    flex: 1 0;
    font-weight: 400;
  }
}

.filterGrid-button {
  // padding: 0 $defaultPadding;
  width: 100%;
  border-radius: $defaultBorderRadius;
  font-size: 1.2rem;
}

.filterGrid-button-apply {
  background: #3455dd;
  color: #ffffff;
  font-size: 1.2rem;
}

.filterFreeText-input {
  width: 100% !important;
  margin: $marginHalf 0;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    text-decoration: line-through;
    &::placeholder {
      color: $white;
    }
  }
  &.error {
    &::placeholder {
      color: $red;
    }
  }
}
