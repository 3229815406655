@import 'styles/library.scss';

.Header {
  > div {
    display: flex;
    &:first-child {
      margin-bottom: $marginHalf;
      display: flex;
      align-items: flex-end;
      h2 {
        margin: 0;
        margin-left: $marginHalf;
        font-weight: 500;
        color: $gray7;
        font-size: 1.8rem;
      }
    }
    &:last-child {
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 1.4rem;
        margin: 0;
        font-weight: 400;
      }
      .Button {
        flex-basis: 200px;
        //   width: 100px;
      }
    }
  }
}
