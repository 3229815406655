@import '../../../styles/library.scss';

.logo-container {
  width: $logoSize;
  height: $logoSize;
  border-radius: 12px;
  background: $paleBlue;
  text-align: center;
  position: relative;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: $logoInnerWidth;
  height: $logoInnerHeight;
}
