.FileUpload {
  margin-bottom: 20px;

  .file-browse {
    &:hover {
      cursor: pointer;
    }
  }
  .trash-icon {
    position: absolute;
    right: 12px;
    top: 19px;
    padding: 4px;
    border-radius: 4px;

    svg {
      transform: scale(1.1);
    }
    &:hover {
      cursor: pointer;
      background-color: #e8e8e8;
    }
  }
}
