@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&family=Rubik:wght@300;400;500;600&display=swap');

/* Colors */

/* Grays */
$gray1: #e8e8e8;
$gray2: #d1d2d3;
$gray3: #b7bbc5;
$gray4: #8c909a;
$gray5: #6d6d7a;
$gray6: #5d6576;
$gray7: #454549;
$gray8: #2c2c30;
$gray9: #eaeaea;
$gray10: #f9f6f6;
$gray11: #e1e3e9;
$gray12: #fcfcfd;
$gray13: #f5f6f8;
$PDFTableRowGray: #f8f8f8;

/* Black & Whites */
$black: #000000;
$white: #ffffff;

/* Blues */
$primaryBlue: #3455dd;
$paleBlue: #ecedff;
$backgroundBlue: #f0f2f6;
$cpBlue: #8796f0;
$darkBlue: #aeb8f5;
$lightBlue: #c0cdf6;
$lighterBlue: #f9faff;
$PDFHeaderBlue: #4b7ae7;
$darkerBlue: #2c2c30;

/* REBRANDING COLORS */
$lightBlue2: #5886fb;
$gray14: #dadde0;
$gray15: #828282;
$gray16: #f3f3f3;
$gray17: #666666;
$gray18: #b7bbc5;
$gray19: #5d6576;
$gray20: #edeff2;
$gray22: #fcfcfc;
$gray23: #f6f8ff;
$gray24: #5d6576;

$brandBlue: $lightBlue2;
$brandPink: #feaa90;
$brandYellow: #ffe1ac;

/* REBRANDING ANIMATIONS */
$loginAnimation: moveShadow 40s infinite linear;

$red: #f46666;
$green: #00c00e;
$orange: #f3a909;
$darkGreen: #6fe1c1;
$darkGreen2: #1aa884;
$lightGreen: #ebfbf7;
$lightGreen2: #d3f7f0;

$backgroundColor: #e5e5e5;

/* Fonts */
$fontSizeSmall: 10px;
$fontSizeMedium: 12px;
$fontSizeBig: 14px;

$fontFamilyMain: Rubik;
$fontFamilySecondary: Rajdhani;

$fontColorMain: $gray5;
$fontThin: 300;
$fontNormal: 400;
$fontBold: 500;
$fontExtraBold: 600;

/* Sizes */
$siteWidth: 1346px;
$logoSize: 48px;
$logoInnerWidth: 32px;
$logoInnerHeight: 25px;
$sideBarWidth: 72px;
$defaultMargin: 16px;
$defaultPadding: 16px;
$paddingHalf: $defaultPadding * 0.5;
$marginHalf: $defaultMargin * 0.5;
$paddingSmall: $paddingHalf * 0.5;
$paddingBig: 24px;
$marginSmall: $marginHalf * 0.5;
$borderRadiusSmall: 2px;
$defaultBorderRadius: 4px;
$borderRadiusBig: $defaultBorderRadius * 3;
$filterBoxOpenSize: 200px;
$filterBoxCloseSize: 100px;
$popUpWidth: 712px;
$headerMarginTop: 60px;
$selectItemsWidth: 120px;
$appIconSize: 60px;
$page404FontSize: 160px;
$quickViewPanelSize: 550px;
$addintionalBottomSpace: 5px;
