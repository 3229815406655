@import 'styles/library.scss';

.Help {
  border-radius: 50%;
  border: solid 1px;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  position: relative;
  margin-bottom: $marginHalf;
  background: $primaryBlue;
  color: $white;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    background-color: $white;
    color: $primaryBlue;
  }
}

#ZD_HELP {
  display: none;
  &.visible {
    display: flex;
  }
}
