@import 'styles/library.scss';

.SSO-button-wrapper {
  width: 100%;
  margin-bottom: $defaultMargin;
  box-shadow: 0 1px 2px lighten($black, 80%);
  &:last-child {
    margin-bottom: 0;
  }
}

.SSO_button-scope {
  width: 100%;
  font-size: 1.5rem;
  height: 36px;
  border: 0;
  outline: 0;
  border-radius: 2px;
  font-family: $fontFamilyMain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: $gray16;
  color: $gray17;
  text-decoration: none !important;
  transition: background 0.3s;
  &:hover {
    background: darken($gray16, 3%);
  }
  img {
    margin-right: $marginHalf;
  }
}
