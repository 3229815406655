@import 'styles/library.scss';

.AlertSingle {
  position: relative;
  min-height: 100px;
  .notice {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 140%;
    color: $gray6;
    margin-bottom: $defaultMargin * 1.5;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      border-bottom: 1px solid $gray13;
      padding-bottom: $defaultPadding;
      margin-bottom: $defaultMargin;
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
      }
      h6 {
        color: $gray4;
        margin: 0 0 $marginSmall 0;
        font-size: 1.1rem;
        font-weight: 300;
      }
      p {
        color: $gray7;
        margin: 0;
        font-size: 1.2rem;
        line-height: 140%;
      }
    }
  }
  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: $paddingHalf $defaultPadding;
    box-sizing: border-box;
    background: $white;
    border-top: 1px solid $gray9;
    span {
      margin-right: $defaultMargin;
      font-size: 1.1rem;
      text-decoration: underline;
      color: $gray4;
      cursor: pointer;
      font-weight: 500;
    }
    .btn {
      font-weight: 700;
    }
  }
}
