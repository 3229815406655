@import '../../../../../styles/library.scss';

.formHeader_header-scope {
  display: flex;
  color: $white;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: solid 1px #3f4045;
  padding-bottom: $paddingHalf;
}

.formHeader_header-text {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.link-to-more {
  color: $gray2;
  &:hover {
    font-weight: 700;
    text-decoration: underline;
  }
}

.formHeader_count-scope {
  display: flex;
}

.formHeader_count {
  font-weight: $fontBold;
  font-size: 3.6rem;
}

.fsormHeader_header-scope-border {
  border-right: solid 1px #3f4045;
}

.formHeader-icon {
  margin-right: $marginHalf;
}
