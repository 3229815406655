@import 'styles/library.scss';

.InnerRule {
  > div {
    display: flex;
    > div {
      background: $lighterBlue;
      padding: $paddingSmall;
      border: 1px solid $paleBlue;
      border-radius: 4px;
      flex: 1;
      margin-right: $defaultMargin;
      position: relative;
    }
    img {
      cursor: pointer;
    }
  }

  .DropdownList {
    margin-left: $marginHalf;
    .dropdown {
      height: 24px;
    }
  }

  .add-value {
    // width: 60px;
    // flex-basis: 60px;
    flex-basis: auto !important;
    width: auto !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .seperator {
    display: inline-flex;
    margin: 0 10px;
    align-items: center;
  }

  .rule-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    > * {
      margin: 6px;
    }
  }

  .value {
    border: 1px solid #dfe1e4;
    padding: 2px 5px;
    color: #454549;
    background: #fff;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    margin-right: 10px;
    height: 22px;
    font-size: 1.2rem;
    span:first-child {
      display: block;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    span:last-child {
      background: #f0f2f6;
      color: #5d6576;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 3px 3px;
      border-radius: 4px;
      font-size: 10px;
      margin-left: 6px;
      cursor: pointer;
      img {
        width: 8px;
        height: 8px;
      }
    }
  }

  .and,
  .add-rule {
    display: none;
  }

  .add-rule {
    width: 20px;
    height: 20px;
    display: none;
    align-items: center;
    justify-content: center;
    border: 1px solid $paleBlue;
    border-radius: 3px;
    background: $white;
    color: $primaryBlue;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .and {
    width: 30px;
    height: 20px;
    font-size: 1.2rem;
    background: $white;
    border: 1px solid $paleBlue;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin: 10px 0;
  }

  &:not(:last-child) {
    .and {
      display: flex;
    }
  }
  &:last-child {
    .add-rule {
      margin-top: 10px;
      position: static !important;
      display: flex;
    }
  }
}
