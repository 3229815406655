@import 'styles/library.scss';

.Download {
  display: flex;
  width: 55px;
  justify-content: center;
  img {
    cursor: pointer;
  }
}
