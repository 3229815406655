@import 'styles/library.scss';

.DragDrop {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  &.hasSelected {
    .DRAGGABLE:not(.itemSelected) {
      pointer-events: none;
    }
  }
  .right,
  .left {
    flex-direction: column;
    display: flex;
    flex: 1;
    box-sizing: border-box;
    position: relative;
    .approve {
      flex: 6;
      border-bottom: 1px solid $paleBlue;
      .LabelWithConfidence,
      .LabelWithIcons {
        background: #f0fcf9;
        border-color: #1aa884;
      }
    }
    .proposed {
      .LabelWithIcons {
        border: 1px solid #dfe1e4;
        background: $white;
      }
    }
    .reject {
      flex: 4;
      .LabelWithConfidence,
      .LabelWithIcons {
        background: #fff5f9;
        border-color: #c32424;
      }
    }
    .proposed {
      flex: 1;
      .LabelWithConfidence {
        &.itemSelected {
          border-color: $primaryBlue;
          box-shadow: 0 0 0 2px #98aaee;
          background: #f5f6fd;
        }
      }
      .main-scrollable-area {
        &.hide-determined {
          .moved {
            color: #fff;
            * {
              opacity: 0 !important;
            }
          }
        }
      }
      .moved {
        pointer-events: none;
        color: #ddd;
        * {
          opacity: 0.7 !important;
        }
      }
      .hidden {
        color: #fff;
        * {
          opacity: 0 !important;
        }
      }
    }
    header {
      width: 100%;
      flex: 0;
      box-sizing: border-box;
      left: 0;
      padding: $defaultPadding;
      font-size: 1.3rem;
      display: flex;
      align-self: center;
      color: #60646a;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      > div {
        display: flex;
        align-items: center;
      }
      .switch-wrapper {
        .label {
          margin-right: $marginHalf;
        }
      }
      .add-entity {
        color: $primaryBlue;
        cursor: pointer;
      }
      img {
        margin-right: $marginSmall;
      }
      .select-all {
        margin-right: $marginHalf;
        cursor: pointer;
      }
      .unselect-all {
        cursor: pointer;
      }
    }
    &.left {
      border-right: 1px solid $paleBlue;
    }
    .top,
    .bottom {
      > div.DRAGGABLE {
        display: inline-flex;
      }
    }
    > div.DRAGGABLE {
      border: 1px solid $paleBlue;
      width: 100%;
      margin-bottom: $defaultMargin;
      &:last-child {
        margin-bottom: 0;
      }
      > div {
        width: 100%;
      }
      .HierarchyItems {
        margin: 10px;
      }
    }
    .approve,
    .reject,
    .proposed {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: relative;
      box-sizing: border-box;
      .DROPPABLE {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: hidden;
        .scrollbar-common {
          flex: 1;
          overflow-y: auto;
          .empty-state {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img {
              width: 40px;
              height: 40px;
              margin-top: 10px;
              //   margin-bottom: $marginSmall;
            }
          }
          .main-scrollable-area {
            overflow-y: auto;
            padding: $defaultPadding;
            padding-top: 0;
            > div.DRAGGABLE {
              border: 1px solid $paleBlue;
              width: 100%;
              margin-bottom: $defaultMargin;
              &:last-child {
                margin-bottom: 0;
              }
              > div {
                width: 100%;
              }
              .HierarchyItems {
                margin: 10px;
              }
            }
          }
        }
      }
    }
  }
}
