@import 'styles/library.scss';

.SubHeader {
  border: 1px solid $paleBlue;
  border-radius: 4px;
  margin-top: $defaultMargin * 1.5;
  color: $gray7;
  background: #f9faff;
  > div {
    padding: $defaultPadding;
    &:first-child {
      // border-bottom: 1px solid $paleBlue;
    }
    // &:last-child {
    //   display: flex;
    //   justify-self: flex-start;
    //   .addional-seed-assets-wrapper,
    //   .cidr-wrapper {
    //     position: relative;
    //   }
    // }
    h3 {
      font-size: 1.6rem;
      margin: 0 0 $marginHalf 0;
      padding: 0;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
    }
    a {
      font-size: 1.3rem;
      margin-right: $defaultMargin;
      cursor: pointer;
      color: $primaryBlue;
    }
  }
}
