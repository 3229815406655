@import 'styles/library.scss';

.InvestigationTabs {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eaeaea;
  height: 100%;
  .actions {
    display: flex;
    align-items: center;
    min-height: 24px;
    padding: 6px 18px;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    .selected-rules {
      display: flex;
      justify-content: center;
      padding: 3px 6px;
      background-color: #eaeefc;
      color: #3355dd;
      border-radius: 4px;
      font-size: 13px;
      min-width: 12px;
    }
    // margin-bottom: 16px;
    .ToggleButtons {
      margin-right: 0px !important;
      height: 22px;
    }
    .ToggleButtons > span {
      display: flex;
      justify-content: center;
      width: 50% !important;
    }
    .Button {
      margin-right: 10px;
      width: auto;
      box-shadow: none;
      height: 24px;
      box-sizing: border-box;
      padding-right: 5px;
      padding-left: 10px;
    }
    .size-change {
      display: flex;
      align-self: center;
      cursor: pointer;
    }
  }
  .search-wrapper {
    display: flex;
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
    .search {
      display: flex;
      padding: 16px 0px 16px 16px;
      width: 100%;

      .input-scope {
        width: 100%;
        .input-common {
          width: 100%;
        }
      }
    }

    &:after {
      content: '';
      height: 100%;
      width: 15px;
      background-image: linear-gradient(to right, #ffffff, #6d6d7a);
      opacity: 0.05;
      display: flex;
    }
  }
  .rules-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    .rule-item-wrapper {
      display: flex;
      border-bottom: 1px solid #eaeaea;
      background-color: #fff;
      margin-top: 8px;

      .rule-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 0px 12px 16px;

        .MuiCheckbox-root {
          color: #1976d2 !important;
          min-width: 20px !important;
        }
      }
      &:after {
        content: '';
        height: 100%;
        width: 15px;
        background-image: linear-gradient(to right, #ffffff, #6d6d7a);
        opacity: 0.05;
        display: flex;
      }

      .status-count {
        display: grid;

        .count {
          padding: 3px 6px;
          border-radius: 4px;
        }
      }
    }
  }
  .save-views-footer {
    display: flex;
    height: 40px;
    align-items: center;
    background-color: #fcfcfc;
    justify-content: flex-end;
    padding: 0 16px;

    .save-as,
    .restore {
      margin-left: 16px;
      &:hover {
        cursor: pointer;
      }
      &:active {
        color: #3556dd;
      }
    }
  }

  .views-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .view-item-wrapper {
      display: flex;
      border-bottom: 1px solid #eaeaea;
      background-color: #fff;
      margin-top: 8px;
      position: relative;

      .view-content-wrapper {
        display: flex;
        align-items: center;
        padding: 16px 0 16px 16px;
        width: 100%;

        .icon {
          display: flex;
          img.default-icon {
            transform: scale(1.3);
            position: absolute;
            top: 2px;
            left: 4px;
          }
          img.generic-icon {
            transform: scale(0.5);
          }
          img.user-icon {
            transform: scale(1.4);
            margin-right: 6px;
            padding-left: 4px;
          }
        }
      }
      .view-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 0px 12px 16px;

        .MuiCheckbox-root {
          color: #1976d2 !important;
        }
      }
      &:after {
        content: '';
        width: 15px;
        background-image: linear-gradient(to right, #ffffff, #6d6d7a);
        opacity: 0.05;
        display: flex;
      }

      .status-count {
        display: grid;

        .count {
          padding: 3px 6px;
          border-radius: 4px;
        }
      }
    }
  }
  .save-views-footer {
    display: flex;
    height: 40px;
    align-items: center;
    background-color: #fcfcfc;
    justify-content: flex-end;
    padding: 0 16px;

    .save-as,
    .restore {
      margin-left: 16px;
      &:hover {
        cursor: pointer;
      }
      &:active {
        color: #3556dd;
      }
    }
  }
}
