@import 'styles/library.scss';

.integration-input {
  margin-bottom: 10px;

  .input-container {
    display: flex;
    align-items: center;
    position: relative;

    // .has-value {
    //   background-color: #fff !important;
    // }
    // &:not(.has-value) {
    //   background-color: #f9faff !important;
    // }
    .input {
      margin-top: $defaultMargin;
      font-size: 12px !important;

      &.has-value {
        .MuiInputBase-root {
          background-color: #fff !important;
        }
      }
      &:not(.has-value) {
        .MuiInputBase-root {
          background-color: #f9faff !important;
        }
      }
      .MuiInputBase-root.Mui-focused {
        background-color: #fff !important;
      }

      label {
        padding: 0 5px;
        color: #5d6576 !important;
        margin-left: -1px;
      }
      .MuiInputLabel-root {
        padding-left: 0;
      }
      label:not(.Mui-focused):not(.MuiFormLabel-filled) {
        transform: translate(14px, 13px) scale(1);
        // color: #5d6576 !important;
        font-size: 12px;
      }
      label.MuiFormLabel-filled:not(.Mui-focused) {
        // color: #5d6576;
        background: $white;
      }
      label.Mui-focused {
        // color: #5d6576 !important;
        background: $white;
      }
      label.MuiInputLabel-animated {
        transform: translate(14px, -7px) scale(1);
        font-size: 10px;
      }
      input {
        padding: 12px;
        outline: 0;
        // color: $gray19;
        font-size: 13px;
      }
      fieldset {
        border-color: $paleBlue;
        border-width: 1px;
      }
    }
    .icon-clipboard,
    .icon-help-only {
      position: absolute;
      top: 27px;
      right: 6px;
      width: 20px;
      height: 20px;
      // background-color: #fff;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 4px;
      svg {
        transform: scale(1.2);
      }
      &:hover {
        cursor: pointer;
        background-color: #ecedff;
      }
    }
    .icon-help-both {
      position: absolute;
      top: 10px;
      right: 30px;
      width: 20px;
      height: 20px;
      // background-color: #fff;
      align-items: center;
      display: flex;
      justify-content: center;
      svg {
        transform: scale(1.2);
      }
      &:hover {
        cursor: pointer;
        background-color: #ecedff;
      }
    }
  }
}

.input-readonly {
  display: flex;
  align-items: center;

  .clipboard {
    width: 16px;
    height: 16px;
    border: 1px solid #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-left: 4px;

    &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
      border-color: #f7f7f7;
    }
  }
}
