@import '../../../../../styles/library.scss';

.riskScoreByCategory {
  display: flex;

  > div {
    h6 {
      font-size: 1rem;
      margin: 0 0 $marginHalf 0;
    }
    &:first-child {
      margin-right: $defaultMargin * 2;
      .riskScoreByCategory-total {
        color: $white;
        font-size: 3.2rem;
        line-height: 3.2rem;
      }
    }
    &:last-child {
      .riskScoreByCategory-categories {
        display: flex;
        justify-content: flex-start;
        margin-top: $marginHalf;
        flex-wrap: wrap;
        .riskScoreByCategory-label-scope {
          display: flex;
          font-size: 1rem;
          margin: 0 $marginHalf $marginHalf 0;
          color: #ffff;
          align-items: center;
          .riskScoreByCategory-label {
            margin: 0 0 0 2px;
          }
        }
      }
    }
  }
}

// .riskScoreByCategory-header {
//   font-size: 1rem;
// }

// .riskScoreByCategory-label {
//   margin: 0 0 0 2px;
// }
