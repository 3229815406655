@import 'styles/library.scss';

.mapCard-scope {
  position: absolute;
  width: 300px;
  right: 0;
  top: 45px;
  background: #2c2c30;
  color: #b7bbc5;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  border-radius: 8px 0 0 8px;
  transition: all 0.2s linear;
  height: calc(100% - 70px);
  z-index: 2;
}

.mapCard-row-scope {
  display: flex;
  justify-content: space-between;
  margin: $defaultMargin $marginHalf;
  font-size: 1.4rem;
}

.mapCard-body {
  margin: $defaultMargin;
  border: solid 1px #454549;
  border-radius: $defaultBorderRadius;
  height: 75%;
}

.mapCard-header-label-scope {
  display: flex;
  align-items: center;
}

.mapCard-header-scope {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 1.4rem;
  margin: $marginHalf $defaultMargin;
  border-bottom: solid 1px #666669;
}

.mapCard-iconArrow {
  transform: rotate(270deg);
}

.mapCard-header-count {
  font-size: 1.2rem;
  font-family: Rajdhani;
}

.mapCard-input-search-scope {
  margin: $defaultMargin;
}

.mapCard-input-search {
  color: #fff;
}
.scrollbar-mapCard ::-webkit-scrollbar-track {
  background: #000000;
  border-radius: 6.3rem;
}
