.BulletStatus {
  display: flex;
  align-items: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
}
