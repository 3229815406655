@import 'styles/library.scss';

.AddAccountConfirmation {
  transform: translateX(150%);
  transition: transform 0.2s ease-out;
  background-color: $white;
  position: absolute;
  z-index: 2;
  h3 {
    width: 505px;
  }
  &.visible {
    transform: translateX(0);
  }
  .not-found {
    width: 505px;
    font-size: 1.3rem;
  }
  .buttons-container {
    bottom: -50px;
    .Button {
      width: auto !important;
      flex-basis: auto !important;
      padding: 0 $defaultPadding;
    }
  }
  p {
    color: $gray4;
    font-size: 1.2rem;
    line-height: 130%;
  }
  h5 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: $defaultMargin * 2 0 0 0;
  }
  .details-box {
    background-color: $lighterBlue;
    border-radius: 6px;
    padding: 20px;
    margin-top: $defaultMargin;
    border: 1px solid $gray13;
    h6 {
      font-size: 1.2rem;
      font-weight: 500;
      margin: 0;
    }
    ul {
      list-style: none;
      margin: $marginHalf 0 0 0;
      padding: 0;
      li {
        color: $gray6;
        font-size: 1.2rem;
        margin-bottom: $marginSmall;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
