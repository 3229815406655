@import 'styles/library.scss';

.threats-pdf-header {
  display: flex;
  flex-direction: columns;
  background-color: $darkerBlue;
  // height: 80px;
  padding: 20px 30px;
  // flex-grow: 1;

  .header-data-row {
    // padding: 0 40px 0 30px;
    height: 60px;
    justify-content: center;
  }
  .right-border {
    height: 60px;
    border-right: 1px solid #3d3d3d;
  }

  & .header-logo {
    svg {
      width: 100px;
      height: 35px;
    }
  }
}
