@import '../../../../styles/library.scss';

.SidePanel-wrapper {
  width: 0;
  transition: width 0.3s;
  position: relative;
  &.open {
    width: 500px;
    padding-left: $paddingHalf;
  }
  .scrollbar-common {
    flex: 1 0;
    overflow: hidden;
  }
}

.notifications-drawer {
  position: relative;
  height: 100%;
  .MuiDrawer-paper {
    left: 100%;
    position: absolute;
    .open & {
      left: 0;
    }
  }

  & .add-notification-item-box {
    padding: 14px 8px 14px 16px;
    border: 1px solid #dadde0;
    border-radius: 6px;
    margin-bottom: 16px;

    &:hover {
      background-color: $lighterBlue;
      cursor: pointer;
    }
    & .add-notification-item-desc {
      padding-top: 8px;
      padding-left: 26px;
    }

    & .add-notification-item-img {
      svg {
        transform: rotate(-90deg) scale(1.5);
      }
    }
  }

  & .close-trigger {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}
