@import '../../../styles/library.scss';

.menu-sidebar {
  position: relative;
  top: -25px;
}
.item-sidebar {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  a {
    display: flex;
    flex-direction: column;
    height: 60px;
    width: 72px;
    cursor: pointer;
    svg {
      margin-bottom: $marginSmall;
    }
    &:hover {
      background-color: rgba(25, 118, 210, 0.14);
    }
  }
}

.link-page-title {
  font-size: 0.9rem;
  text-align: center;
  font-weight: 400;
}

.uselected {
  color: $gray8;
}

.selected {
  color: $primaryBlue;
}

@media only screen and (max-height: 550px) {
  .item-sidebar {
    margin-bottom: 1.5rem;
  }
}
