@import '../../../../styles/library.scss';

.criticalActionsOverTime-scope {
  background: #ffffff;
  padding: $defaultPadding;
  border-radius: $defaultBorderRadius;
  margin-bottom: $defaultMargin;
  box-shadow: 0 0.4vw 1vw rgba(24, 29, 78, 0.1);
  display: flex;
  flex-direction: column;
  flex: 1 0;
}

.criticalActionsOverTime-header-scope {
  display: flex;
  justify-content: space-between;
  color: #8c909a;
  font-size: 1vw;
}
