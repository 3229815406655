@import 'styles/library.scss';

.PopSide {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  &.visible {
    z-index: 999999;
    opacity: 1;
    width: 100%;
    .bg-layer {
      opacity: 0.4;
    }
    .PopSide-inner {
      opacity: 1;
      transform: translateX(0);
      right: 10px;
    }
  }
  &.secondLevel {
    z-index: 9999999;
    opacity: 1;
  }
  &.hidden {
    z-index: 99999999;
    // opacity: 0;
    .bg-layer {
      opacity: 0;
    }
    .PopSide-inner {
      // opacity: 0;
      transform: translateX(100%);
      right: 0;
    }
  }
  &.wide {
    .PopSide-inner {
      width: 850px;
    }
  }
  .close-trigger {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
  .bg-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #29292c95;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .bg-layer-ontop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .PopSide-inner {
    width: 550px;
    height: calc(100% - 20px);
    position: absolute;
    right: 0;
    top: 10px;
    background: $white;
    // opacity: 0;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transform-origin: 100%;
    border-radius: 6px;
    padding: $defaultPadding * 1.5;
    padding-bottom: $defaultPadding;
    padding-right: $defaultPadding;
    box-sizing: border-box;
    border: 1px solid $gray20;
    transition: all 0.5s;

    &.parent-popside {
      right: 25px !important;
      top: 30px !important;
      height: calc(100% - 60px);
      transition: all 0.5s;
      z-index: 9999999;

      // ~ .PopSide.hidden {
      //   color: red;
      // }
    }
  }
  .ai-width {
    width: 905px;
    &.edit-account {
      .PopSide-inner {
        padding: 0;
        padding-top: $defaultPadding * 1.5;
      }
    }
  }
}
