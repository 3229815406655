@import '../../../../styles/library.scss';

.collapse-panel-actionItems-open,
.collapse-panel-actionItems-closed {
  & .top-panel-action-items {
    display: flex;
    align-items: center;

    & .action-items-wrapper {
      padding-top: 30px;
      flex-wrap: nowrap;

      & .data-panel-item:not(:last-child) {
        margin-right: 15px;
      }

      & .data-panel-title {
        // padding-bottom: 8px;
      }
      & .content-class-name {
        margin-top: 18px;
      }
      & .small-padding {
        margin-top: 4px;
      }

      & #risky_connection,
      #web_application,
      #vulnerable_component {
        width: 68px;
      }
    }
  }

  & .top-panel-collapse-wrapper {
    align-items: center;
  }
  & .distribution-by-urgency {
    & .urgency-wrapper {
      flex-wrap: nowrap;
      padding-top: 12px;
      & .small-padding {
        margin-top: 4px;
      }
    }
    & .urgency-icon {
      padding-bottom: 5px;
    }

    & .data-panel-container {
      min-width: 38px;
    }
  }

  & .critical-data-panel {
    padding-top: 28px;
    & .small-padding {
      margin-top: 4px;
    }
    & .data-panel-content {
      flex-wrap: nowrap;
    }
    & .data-panel-title {
      padding-bottom: 10px;
    }
    & .last-30 {
      flex-wrap: nowrap;
      padding-top: 15px;
    }
    & .current-average-right-text {
      flex-wrap: nowrap;
      padding-top: 15px;
      padding-left: 3px;
    }
    & .data-panel-container {
      width: 105px;
    }
  }

  & .last-30-days {
    padding-top: 6px;
  }
  & .top-panel-collapse-item {
    padding-right: 16px;
  }
  & .urgency-line {
    width: 600px;
  }
}
