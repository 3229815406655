@import '../../../../../styles/library.scss';

.dropdownbox {
  // margin-top: 20px;
  border: 1px solid $paleBlue;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  background-color: $lighterBlue;

  &.header-selected {
    background-color: #ffffff;
  }
  &.is-required {
    border: 1px solid $red;
  }

  & .label-wrapper {
    border-bottom: 1px solid #ecedff;
    position: relative;
  }
  & .collapse-button {
    padding: 0;
    background-color: $white;
    border-color: $paleBlue;

    &.expand {
      svg {
        transform: rotate(180deg);
      }
    }
    &.collapse {
      svg {
        transform: rotate(0deg);
      }
    }
    & .MuiButton-startIcon {
      margin: 0;
    }
  }
  & .dropdownbox-label {
    flex: 1 0;
    padding: 5px 8px 5px 16px;
    cursor: pointer;
    flex-wrap: nowrap;

    & .dropdownbox-label-row {
      flex-wrap: nowrap;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  & .dropdownbox-item {
    padding: 10px $paddingHalf 10px $defaultPadding;
    &:hover {
      background-color: $lighterBlue !important;
      cursor: pointer;
      // border: 1px solid $paleBlue;
    }
  }

  & .dropdownbox-content {
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    background-color: $white;
    & .dropdown-box-checkbox {
      display: flex;
      flex-direction: column;
      padding: 0;

      & .checkbox {
        display: flex;
        padding: 10px 8px 10px 16px;
        align-items: center;
        &:hover {
          background-color: $lighterBlue !important;
          // padding: 2px;
          // border-radius: 4px;
        }
      }

      & .checkbox-importance {
        &:hover {
          background-color: $lighterBlue !important;
          // padding: 2px;
          // border-radius: 4px;
        }
        display: flex;
        padding: 4px 8px 4px 16px;
        align-items: center;
        font-size: 12px;

        & .importance-container {
          padding-left: 6px;
          svg {
            transform: scale(0.8);
          }
        }
      }
    }

    & .dropdown-box-slider {
      display: flex;
      align-items: center;
      flex-direction: column;
      // padding: 16px 12px;
      // margin: 0 10px;

      & .dropdown-box-slider-legend {
        width: 100%;
      }
      & .slider-text {
        border-radius: 4px;
        background-color: $gray23;
        // padding: 4px;
        font-size: 12px;
        width: 30px;
        height: 16px;
        text-align: center;
        &.left {
          margin-right: 10px;
        }
        &.right {
          margin-left: 10px;
        }
      }
    }

    & .MuiOutlinedInput-input {
      padding: 10px !important;
      border-color: #ecedff !important;
    }

    & .notifications-autocomplete-input {
      & .MuiAutocomplete-input {
        font-size: 13px !important;
      }
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid #ffffff;
      }
      & .MuiChip-label {
        font-size: 13px !important;
        padding: 2px 12px !important;
        font-family: Rubik;
      }
      &.invalid {
        & .MuiAutocomplete-input {
          color: $red;
        }
      }
      & .MuiAutocomplete-input {
        border-bottom: 1px solid #ecedff !important;
        padding: 14px !important;
        font-size: 12px;

        &.invalid {
          color: $red;
        }
      }
      & .MuiChip-filled {
        border-radius: 4px;
        height: auto;
        padding: 3px 0;

        & .MuiChip-label {
          font-size: 12px;
          font-family: Rubik;
        }
      }
    }
    & .MuiAutocomplete-root {
      & .MuiTextField-root {
        border-color: #ecedff !important;
        &:hover {
          border-color: #ecedff !important;
        }
      }
    }
  }
}
