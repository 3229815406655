@import 'styles/library.scss';

.ButtonsWrapper {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);

  &.smallHeight {
    height: 50px;
    transition: height 0.15s ease-out;
  }
  &.bigHeight {
    height: 170px;
    transition: height 0.15s ease-out;
  }
  .popside-delete {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .Button {
    //:not(:last-child) {
    margin-right: $defaultMargin * 0.9;
  }
  .error {
    border: 0 !important;
    flex: 1 0;
    margin-right: $defaultMargin;
    color: $red;
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.1rem;
  }
  .delete-button {
    display: flex;
    align-items: center;
    color: $red;
    margin-left: 24px;
    &:hover {
      cursor: pointer;
    }
    &.inactive {
      pointer-events: none;
    }
  }
}
