@import '../../../../styles/library.scss';

.notifications-header {
  background-color: #f5f6ff;
  // width: min-content;

  & .notifications-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
    border-bottom: 1px solid $gray1;

    > button {
      flex-basis: 120px;
      width: 120px;
      background-color: #3455dd;
      height: 24px;
      font-family: Arial;
      color: #ffffff;

      &.panel-open {
        width: auto;
        white-space: nowrap;
      }
    }
  }

  .group-action {
    padding: 13px 20px 13px 40px;
    display: flex;
    align-items: center;

    &.panel-open {
      padding: 13px 5px 13px 10px;
    }

    & .MuiToggleButtonGroup-root {
      position: sticky;
      right: 0;
      left: 0;
      margin-left: auto;
    }
    & .Search {
      border: 0px solid white;
      padding: 0;
      margin-right: 24px;
      min-width: 160px;

      & #input-search {
        background-color: #f5f6ff;
      }
    }

    & .order-sort-icon {
      padding-top: 2px;
      visibility: hidden;

      &.active {
        visibility: visible;
      }
      &.desc {
        svg {
          transform: rotate(180deg);
        }
      }
      &.asc {
        svg {
          transform: rotate(0deg);
        }
      }
    }
    // & .order-sort-icon:not(.active):hover {
    //   visibility: visible;
    // }
    & .MuiToggleButtonGroup-root {
      height: 28px;
      & .MuiToggleButton-root {
        padding: 4px 10px;
        &:hover {
          & .order-sort-icon:not(.active) {
            visibility: visible;
            opacity: 0.4;
            & .order-sort-icon {
              &.desc {
                svg {
                  transform: rotate(180deg);
                }
              }
              &.asc {
                svg {
                  transform: rotate(0deg);
                }
              }
            }
          }
        }
      }
    }
  }
}
