@import 'styles/library.scss';

.Alert {
  border: 1px solid $gray14;
  width: 384px;
  padding: $paddingHalf * 1.5;
  box-sizing: border-box;
  background: $white;
  border-radius: 6px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  div:first-child {
    margin-right: $marginHalf;
  }
  .icon {
    width: 22px;
    height: 22px;
  }
  h6 {
    text-transform: capitalize;
    margin: 3px 0 $marginSmall 0;
    font-size: 1.3rem;
    font-weight: 500;
    color: $gray8;
  }
  p {
    margin: 0;
    color: $gray17;
    font-size: 1.2rem;
    padding-right: $paddingHalf;
  }
  .close {
    position: absolute;
    right: $paddingHalf * 1.5;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
