@import 'styles/library.scss';

.SubsidiaryRow {
  margin: 20px;
  border: 1px solid $gray1;
  min-height: 20px;
  border-radius: 4px;
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $black;
  font-size: 1.3rem;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  &.with-cursor {
    cursor: pointer;
  }

  .row-data {
    display: flex;
    align-items: center;
  }
  > div {
    margin-left: 15px;
    &:last-child {
      color: $gray5;
      font-weight: 400;
    }
  }
  .score-render {
    .score-background {
      height: 14px;
      width: 14px;
    }
    .score-border {
      height: 14px;
      width: 14px;
    }
    .score-text {
      color: $black;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
  .no-events {
    pointer-events: none;
    opacity: 0.3;
    cursor: none;
  }
  .no-data {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
}
