@import 'styles/library.scss';

.OrgScorePopUp {
  width: $popUpWidth * 1.5;
  height: 450px;
  background: $white;
  position: fixed;
  z-index: 999999;
  border-radius: $defaultBorderRadius;

  header.OrgScorePopUp-header {
    background: #2c2c30;
    margin: 8px 5px;
    border-radius: 6px;
    position: relative;
    color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: auto;
    .close {
      transform: rotate(45deg);
      font-size: 3rem;
      font-weight: 100;
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
    .text {
      padding-left: $defaultPadding;
      font-size: 2rem;
    }
  }
  .impacts {
    font-size: 1.2rem;
    margin: $defaultMargin $marginHalf 0 $marginHalf;
    > div {
      display: flex;
      margin-bottom: $marginSmall;
      .key {
        font-weight: $fontExtraBold;
        width: 160px;
      }
    }
  }
  .table {
    position: relative;
    height: 100% !important;
    padding-left: $paddingHalf;
    .importance-icon {
      max-width: 25px;
    }
    [data-field='description'],
    [data-field='host'] {
      white-space: normal;
    }
    .download-csv-link {
      cursor: pointer;
    }
  }
}
