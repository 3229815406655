.ExposureRadar {
  display: flex;
  flex-direction: column;

  .asm-wrapper-pdf {
    position: relative;
    height: 640px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
}

.ASMWidget.is_pdf {
  overflow: visible;
}

@media print {
  .ASMWidget.is_pdf {
    .small-circle,
    .middle-circle,
    .icon,
    .icon-shadow,
    .tt > div,
    .icon,
    .icon-shadow,
    .count {
      -webkit-print-color-adjust: exact;
      -webkit-filter: opacity(1);
    }
  }
}
