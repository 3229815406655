@keyframes loadingState {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.top-panel-loading-container {
  display: flex;
  align-items: flex-end;

  .top-panel-loading-state-progress {
    border-radius: 0px;
    height: 8px;
    position: relative;
    bottom: 0;
    background: repeating-linear-gradient(to right, #0078d7 0%, #2c2c3000 50%, #0078d7 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: loadingState 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
}
