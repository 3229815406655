@import 'styles/library.scss';

.AddEntityWidget {
  position: absolute;
  width: 400px;
  height: 0;
  background: $white;
  box-shadow: 0 0 6px #eee;
  overflow: hidden;
  top: 40px;
  right: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  &.visible {
    height: 230px;
    z-index: 2;
    border: 1px solid $paleBlue;
    padding: $defaultPadding;
  }
  h6 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: $defaultMargin;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 $defaultMargin 0;
    li {
      display: flex;
      margin-bottom: $marginHalf * 1.5;
      font-size: 1.2rem;
      cursor: pointer;
      > div {
        margin-right: $marginHalf;
      }
    }
  }
  .input-wrapper {
    &.error {
      border-radius: 4px;
      box-shadow: 0 0 0 1px $red;
      border: 0 !important;
    }
    // input {
    //   background: $paleBlue;
    //   width: 100%;
    //   border-radius: 4px;
    //   padding: $paddingHalf;
    //   font-size: 1.2rem;
    // }
    textarea {
      // border: none;
      // background-color: #f7f8ff;
      // height: 100px;
      // color: #8c909a;
      background: $paleBlue;
      width: 100%;
      border-radius: 4px;
      padding: $paddingHalf;
      font-size: 1.2rem;
      height: 80px;
    }
  }

  .actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: $defaultMargin;
    span {
      margin-left: $defaultMargin;
      font-size: 1.3rem;
      cursor: pointer;
      &:first-child {
        color: $gray7;
      }
      &:last-child {
        color: $primaryBlue;
      }
    }
  }
}
