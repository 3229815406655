@import 'styles/library.scss';

.Downloads {
  position: fixed;
  bottom: 24px;
  right: 24px;
  left: auto;
  z-index: 9999991;
  border: 1px solid $gray14;
  width: 450px;
  max-height: 340px;
  overflow-y: hidden;
  padding: $paddingHalf * 1.5;
  box-sizing: border-box;
  background: $white;
  border-radius: 6px;
  box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05),
    0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04),
    0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f9faff;
  // div:first-child {
  //   margin-right: $marginHalf;
  // }

  .downloads-header {
    display: flex;
    align-items: center;
    width: 100%;

    .downloads-header-actions {
      display: flex;
      align-items: center;
      position: absolute;
      top: 12px;
      right: 16px;

      .devider {
        height: 20px;
        width: 1px;
        background-color: #eaeaea;
        margin: 0 18px;
      }
      .chevron-icon {
        position: relative;
        top: 2px;
        svg {
          &.rotate {
            transform: scale(2) rotate(180deg);
          }

          &.urotate {
            transform: scale(2) rotate(0deg);
          }
        }
      }
      .close-trigger {
        // z-index: 2;
        cursor: pointer;
        transition: transform 0.3s;
        &:hover {
          transform: rotate(90deg);
        }
      }
    }
  }
  .scrollbar-common {
    scrollbar-color: lighten(#ccc, 5%) transparent !important;
  }
  .downloads-list-wrapper {
    overflow: hidden;
    border: 1px solid #ebecff;
    border-radius: 8px;

    .downloads-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 435px;
      max-height: 255px;
      overflow-y: auto;
      background-color: #fff;
      // border-radius: 8px;
      // border: 1px solid #ebecff;

      .download-item {
        display: flex;
        align-items: center;
        padding: 10px 0px;
        width: 100%;

        &:hover {
          background-color: #f7f7f750;
        }
        .icon {
          width: 20px;
          height: 20px;
          margin-left: 8px;
          position: relative;
          top: -2px;
        }
        .loading-item {
          width: 24px;
          height: 24px;
          position: relative;
          right: 4px;
          margin-left: 25px;
          margin-right: 10px;

          .download-progress-wrapper {
            position: relative;
            display: inline-flex;

            .download-progress-text {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .cancel-download-item {
          width: 24px;
          height: 24px;
          position: relative;
          right: 4px;
          margin-left: 25px;
          margin-right: 10px;
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
          }
        }
        .success-download-item {
          width: 24px;
          height: 24px;
          position: relative;
          right: 4px;
          margin-left: 25px;
          margin-right: 10px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

.popUp-scope {
  z-index: 15;

  .download-cancel-all {
    position: fixed;
    background-color: #fff;
    width: 400px;
    height: 140px;
    padding: 16px;
    z-index: 16;

    .cancel-header {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      .close-trigger {
        position: absolute;
        top: 16px;
        right: 16px;
        // z-index: 2;
        cursor: pointer;
        transition: transform 0.3s;
        &:hover {
          transform: rotate(90deg);
        }
      }
    }
    .cancel-content {
      padding: 2px 12px 12px 0;
    }
    .cancel-footer {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 20px;

      .cancel-footer-button {
        width: 150px;
        height: 30px;
        margin-left: 12px;
      }
    }
  }
}
