@import 'styles/library.scss';

.AlertPopup {
  position: fixed;
  background: $white;
  width: $popUpWidth * 0.8;
  height: 530px;

  &.multi {
    width: $popUpWidth * 1.4;
    height: 70vh;
    margin-left: $sideBarWidth;
    .popup-inner {
      height: calc(70vh - 90px);
      padding-bottom: 0;
      padding-top: 0;
      .grid {
        height: 100%;
      }
    }
  }

  .popup-inner {
    padding: $defaultPadding;
    padding-bottom: $defaultPadding * 4;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow-x: hidden;
    overflow-y: auto;
    height: 456px;
    width: 100%;
  }
}
