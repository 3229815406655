.dropdownbox-date-picker {
  padding: 20px 14px;
  align-items: center;
  flex-wrap: nowrap !important;

  & .from {
    padding-right: 5px;
  }
  & .from-date {
    & .MuiFormControl-root {
      width: 100%;

      input {
        font-size: 12px;
        // & .MuiSvgIcon-root {
        //   transform: scale(1.4);
        // }
      }
    }
  }
  & .to {
    padding-right: 5px;
    padding-left: 10px;
  }
  & .to-date {
    & .MuiFormControl-root {
      width: 100%;

      input {
        font-size: 12px;
        // font-family: Rubik;
      }
    }
  }
}
