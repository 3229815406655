@import 'styles/library.scss';
.SaveCustomView {
  width: 350px;
  background: $white;
  padding: $defaultPadding;
  position: absolute;
  z-index: 9;
  top: -10px;
  left: 100%;
  box-shadow: 0 0 3px 1px $paleBlue;
  border-radius: 4px;
  overflow: hidden;
  #AppLoading {
    margin-top: 0 !important;
    > div:first-child {
      height: 50px !important;
    }
    > div:last-child {
      margin-top: 10px;
      font-size: 1.1rem;
    }
  }
  input {
    background: $lighterBlue;
    font-size: 1.2rem;
    border: 1px solid $backgroundBlue;
    width: 100%;
    box-sizing: border-box;
    padding: $paddingHalf * 1.5;
    outline: 0;
    border-radius: 3px;
  }

  > div {
    display: flex;
    justify-content: flex-end;
    margin-top: $defaultMargin;
    .Button {
      margin-left: $marginHalf;
    }
  }
}
