@import '../../../../styles/library.scss';

.pieChartForm-scope {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  border-radius: $defaultBorderRadius;
  margin-right: $defaultMargin;
  padding: $defaultPadding;
  display: flex;
  align-items: center;
  color: #7c8390;
  flex: 1 0;
  &:last-child {
    margin-right: 0;
  }
}
