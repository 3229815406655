@import 'styles/library.scss';

.CustomSettingsPages {
  padding: 30px;
  background: $white;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  &.integrations {
    background-color: $white;
    padding: 0;
    margin: 30px 0 30px 30px;
    overflow-y: scroll;
    height: 100%;
    // scrollbar-color: #fff #fff;
  }
  &.notifications {
    padding: 0;
    background-color: $backgroundBlue;
  }
  &.seed-data {
    height: 100%;
    padding-bottom: 0;
  }
}

.notifications-drawer {
  // transition: transform 1s;
  width: 500px;

  & .MuiDrawer-paper {
    position: relative;
    width: 500px;
    box-sizing: border-box;
    // padding: 24px;
    overflow: hidden;
    border-left: 0;
  }
}
