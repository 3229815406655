@import './styles/library.scss';

#webWidget {
  display: none;
  &.show {
    display: block;
  }
}

#launcher {
  display: none !important;
}

.MuiButtonBase-root {
  font-family: $fontFamilyMain !important;
}

.flex-container {
  display: flex;
  flex: 1 0;
}

.layout-container {
  display: flex;
  min-width: $siteWidth;
  width: 100%;
}

.layout-content {
  width: 100%;
  padding: 0 $paddingHalf;
  box-sizing: border-box;
  margin-left: $sideBarWidth;
}

.layout-pages {
  width: 100%;
  height: calc(100vh - 7.64rem);
}

.layout-pages1 {
  width: 100%;
  height: calc(100vh - 9.75rem);
}

.layout-sidebar {
  position: fixed;
  height: 100%;
  width: $sideBarWidth;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $backgroundBlue;
  box-shadow: 4px 0px 28px 6px rgba(45, 60, 123, 0.12);
  z-index: 4;
  padding: $defaultPadding 0;
  box-sizing: border-box;
  &.move-to-bottom {
    z-index: -1;
  }
  &.flex-start {
    justify-content: flex-start;
    .logo-container {
      margin-bottom: $defaultMargin;
    }
    .menu-sidebar {
      top: 0;
      flex: 1 0;
    }
  }
}

.sub-layout-pages {
  height: calc(100vh - #{$headerMarginTop} - #{$addintionalBottomSpace});
  display: flex;
  flex-direction: column;
  margin-top: $headerMarginTop;
  position: relative;
  &.orgScore {
    height: calc(100vh - 95px);
  }
  .group-filter-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: $defaultPadding;
    padding-bottom: 2px;
  }
}

.main-content-area.ontop {
  z-index: 4;
  position: relative;
}

.font-number-common {
  font-family: Rajdhani;
}

.pointer {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.btn {
  font-size: 1.2rem;
  padding: $paddingSmall $paddingHalf;
  outline: 0;
  border-radius: $defaultBorderRadius * 0.5;
  cursor: pointer;

  &.btn-main {
    background: $primaryBlue;
    color: $white;
    border: 1px solid $primaryBlue;
  }

  &.btn-secondary {
    background: $white;
    color: $primaryBlue;
    border: 1px solid $primaryBlue;
  }

  &.btn-big {
    min-width: 100px;
    padding: $paddingHalf;
  }

  &.btn-small {
    padding: 1px 6px;
    font-size: 0.9rem;
  }

  &.btn-medium {
    padding: 3px 6px;
    font-size: 1.2rem;
  }

  &.disabled {
    pointer-events: none;
    background: $gray2;
    border: 1px solid $gray2;
    color: $gray3;
  }

  &.submitting {
    pointer-events: none;
  }
}
textarea {
  width: 100%;
  resize: none;
  box-sizing: border-box;
  border: 1px solid $gray9;
  font-family: $fontFamilyMain;
  padding: $paddingHalf;
  font-size: 1.2rem;
  outline: 0;
  color: $gray5;
  border-radius: $defaultBorderRadius;
  &.error {
    font-size: 1.2rem !important;
    border: 1px solid $red !important;
    text-align: left !important;
    color: $gray5;
  }
}

// input {
//   color: $gray5;
// }

.red {
  color: $red;
}

.highlight {
  background: yellow;
  font-weight: 700;
}

.link {
  color: $primaryBlue;
  cursor: pointer;
}

.pre {
  span:not(.highlight) {
    white-space: pre;
  }
}

.MuiCalendarPicker-root {
  * {
    font-size: 1.1rem !important;
  }
}

.MuiSnackbar-root {
  z-index: 1111111 !important;
}
