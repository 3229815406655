@import 'styles/library.scss';

.action-items-details {
  margin: 32px 24px;
  .risk-icon {
    svg {
      transform: scale(1.4);
    }
  }

  .technical-details-container {
    border: 1px solid $backgroundBlue;
    overflow-wrap: anywhere;
    // width: 680px;

    & .technical-details-header-scope {
      margin: 8px !important;
    }
    & .technical-details {
      font-size: 11px;
    }
    .TechnicalDetails {
      .technical-details-body-header {
        color: #454549;
        font-weight: 600;
        font-size: 11px;
        font-family: Rubik;
      }
      .raw-data-box {
        textarea {
          height: max-content;
        }
      }
    }
  }
}
