@import '../../../styles/library.scss';

.tab-filter-wrapper {
  position: sticky;
  top: $headerMarginTop;
  z-index: 3;
}

.tab-filter-scope {
  $takenWidth: $sideBarWidth + $defaultPadding;
  background: #2c2c30;
  display: flex;
  justify-content: center;
  border-radius: $borderRadiusBig $borderRadiusBig 0 0;
  padding: $defaultPadding * 2;
  width: 100%;
  min-width: $siteWidth - $takenWidth;
  box-sizing: border-box;
  transition: max-height 0.5s;
  max-height: $filterBoxOpenSize;
  overflow: hidden;
  .formHeader_count {
    display: none;
  }
  &.org-score,
  &.org-score-agg {
    .link-to-more {
      display: none;
    }
    .test-ChartLine {
      > div:nth-child(1) {
        flex: 4 0 !important;
      }
      > div:nth-child(2) {
        flex: 5 0 !important;
      }
      > div:nth-child(3) {
        flex: 4 0 !important;
      }
    }
    .summary-from-scope:first-child {
      .formHeader_header-scope {
        border-bottom: 0;
        .formHeader_header-label {
          font-size: 1.8rem;
        }
      }
      .summary-from-body {
        margin-top: 0;
      }
    }
    .summary-from-scope1:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0;
      .score-render {
        transform: scale(1.5);
      }
    }
  }
  &.loginassets {
    .summary-from-scope {
      .formHeader_count {
        min-height: 0 !important;
      }
    }
  }
  &.action-items,
  &.discovery {
    .summary-from-scope {
      .formHeader_count {
        min-height: 45px;
      }
    }
  }
  &.action-items {
    .summary-from-scope {
      flex: 3 0;
      &:nth-child(2) {
        flex: 4 0;
      }
    }
  }
  &.discovery {
    .areaChart-scope {
      flex: 1 0;
    }
    .summary-from-scope {
      flex: 4 0;
      &:first-child {
        flex: 2 0;
      }
    }
    .summary-from-body {
      > div {
        justify-content: space-between;
      }
    }
  }
  &.active-protection {
    .test-ChartLine {
      > div:nth-child(1) {
        flex: 10 0 !important;
      }
      > div:nth-child(2) {
        flex: 4 0 !important;
      }
      > div:nth-child(3) {
        flex: 6 0 !important;
      }
    }
  }
}

.filter-box-close {
  max-height: $filterBoxCloseSize;
  // overflow: hidden;
  .summary-from-scope {
    border-width: 0;
  }
  .summary-from-body {
    opacity: 0;
  }
}

.rounded-tabs {
  list-style-type: none;
  position: absolute;
  bottom: -10px !important;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.rounded-tabs li {
  display: inline-block;
  background: #2c2c30;
  padding: 0 1.5rem;
  position: relative;
  border-bottom-left-radius: 1.1rem;
  border-bottom-right-radius: 1.1rem;
  cursor: pointer;
}

.rounded-tabs li:after,
.rounded-tabs li:before {
  content: '';
  height: 0.75rem;
  width: 0.75rem;
  background: #2c2c30;
  position: absolute;
  top: 0;
  right: -0.63rem;
  z-index: 1;
}

.rounded-tabs li:before {
  right: auto;
  left: -0.63rem;
}

.rounded-tabs li span:after,
.rounded-tabs li span:before {
  content: '';
  height: 2rem;
  width: 2rem;
  background: #f5f6ff;
  position: absolute;
  top: 0;
  right: -1.75rem;
  z-index: 2;
  border-radius: 50%;
}

.rounded-tabs li span:before {
  right: auto;
  left: -1.75rem;
}

.test-ChartLine {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.tabSummary_sub-from-body {
  display: flex;
  justify-content: center;
}

.formGraph-header-scope {
  white-space: pre;
  margin-right: $marginHalf;
}

.formGraph-header {
  font-size: 1rem;
  color: $gray1;
  font-weight: $fontNormal;
}

.formGraph-number {
  font-size: 3.6rem;
  font-family: Rajdhani;
  svg {
    margin-left: 5px;
  }
}

.formGraph-label-number {
  font-size: 1rem;
}
