@import 'styles/library.scss';

.MuiPopover-root {
  z-index: 99999999999999999 !important;
}
.MuiSelect-select {
  display: flex !important;
  align-items: center !important;
}
.EditScanFrequency {
  margin-top: 20px;
  h5 {
    font-size: 1.2rem;
    white-space: pre;
    margin: 0 20px 10px 0;
    font-weight: 500;
    color: $black;
  }
  .drop-down-label {
    display: none;
  }
}
