@import '../../../styles/library.scss';

.map-scope {
  margin-right: 16px;
  background: $white;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  color: $gray5;
  border-radius: $defaultBorderRadius;
}

.map-reduce {
  position: fixed;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  z-index: 5;
  padding: $defaultPadding;
  padding-top: 0;
  .mapLegend-scope {
    margin-top: -20px;
  }
}

.map-expand {
  position: relative;
  z-index: 1;
  padding: $defaultPadding;
  padding-top: 0;
}

.map-zoom {
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: $defaultMargin;
  font-size: 1rem;
}

.map-dashboard {
  background: $white;
  margin-top: -25px;
}

.map-country:hover {
  cursor: pointer;
  fill: rgba(52, 85, 221, 0.3);
  stroke: #3455dd;
}

.map-country {
  strokewidth: 0.5px;
  stroke: #cad1f4;
  fill: $backgroundBlue;
  transition: all 0.25s ease-in-out;
}

.map-header-scope {
  background: $white;
  top: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding-top: $defaultPadding;
  padding-bottom: $paddingHalf;
}

.map-lable-scope {
  display: flex;
  align-items: center;
  color: #5d6576;
  font-weight: 400;
  font-size: 1.2rem;
}

.map-event-scope {
  display: flex;
}

.map-lable {
  padding-left: $paddingHalf;
}

.map-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000009c;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s;
  &.visible {
    z-index: 4;
    opacity: 1;
  }
}
