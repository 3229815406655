@import 'styles/library.scss';

.Splunk {
  .MuiOutlinedInput-root {
    border: 1px solid $paleBlue !important;
    &.Mui-error {
      border: 1px solid $red !important;
    }
  }
  .info {
    position: relative;
    top: 10px;
    margin-left: 10px;
  }
}
