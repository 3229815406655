@import 'styles/library.scss';

.Timeline {
  display: flex;
  height: calc(100vh - 205px);
  overflow-x: hidden;
  width: 70px;
  // padding-right: 20px;
  border-right: 1px solid #eaeaea;
  z-index: 1;

  .timeline-wrapper {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .timeline-wrapper::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .timeline-wrapper {
    overflow-x: hidden;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-heading {
      // flex: 0 0 33%;
      padding: 15px 19px 10px 15px;
      position: sticky;
      text-align: center;
      top: 0px;
      background-color: #fffffe;
      border-bottom: 1px solid #e8e8e8;
      z-index: 1;
    }
    .timeline-month-wrapper {
      .section-heading-month {
        flex: 0 0 33%;
        padding: 15px 0px 10px 0px;
        position: sticky;
        text-align: center;
        top: 42px;
        background-color: #fffffe;
        z-index: 0;
      }
      .section-days {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .day {
      &:first-child {
        margin-top: 6px;
      }
      &:last-child {
        margin-bottom: 2px;
      }
    }
    .a-day {
      padding: 6px 0;
      margin: 8px 0;
      font-size: 11px;
      width: 22px !important;
      display: block;
      background-color: #f9faff;
      border-radius: 4px;
      text-align: center;
      &:hover {
        &.low {
          background-color: #00bdd725;
        }
        &.medium {
          background-color: #e7b70e25;
        }
        &.high {
          background-color: #e0691525;
        }
        &.critical {
          background-color: #fb585825;
        }
      }
    }
  }

  .section-heading {
    background-color: #fffffe;
  }
}
