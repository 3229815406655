@import 'styles/library.scss';

.GroupButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  button {
    width: 100px;
    margin: $defaultMargin;
    margin-left: 0;
  }
}
