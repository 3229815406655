@import 'styles/library.scss';

.SubTabsLinks {
  display: flex;
  margin-top: $defaultMargin;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    li {
      margin-left: $defaultMargin;
      padding: $paddingHalf $defaultPadding;
      font-size: 1.2rem;
      position: relative;
      span {
        display: none;
      }
      &.active {
        background: $white;
        border-radius: 10px 10px 0 0;
        a {
          color: $primaryBlue;
        }
        span {
          display: block;
          position: absolute;
          bottom: 0;
          &.bottom-left {
            right: -8px;
          }
          &.bottom-right {
            left: -8px;
          }
          &.bottom-left,
          &.bottom-right {
            width: 8px;
            height: 8px;
            overflow: hidden;
          }
          &.bottom-left:before,
          &.bottom-right:before {
            content: '';
            display: block;
            width: 200%;
            height: 200%;
            position: absolute;
            border-radius: 50%;
          }
          &.bottom-left:before {
            bottom: 0;
            left: 0;
            box-shadow: -5px 5px 0 0 $white;
          }
          &.bottom-right:before {
            bottom: 0;
            right: 0;
            box-shadow: 5px 5px 0 0 $white;
          }
        }
      }
      a {
        color: $gray5;
      }
    }
  }
}
