@import '../../../../../styles/library.scss';

.action-items-pdf-tooltip,
.download-csv-tooltip {
  font-size: 11px !important;
}
.gridUtilities {
  display: flex;
  justify-content: space-between;
  background: #f5f6ff;
  align-items: flex-start;
  transition: height 0.2s linear;
  position: relative;
  z-index: 3;
}
.gridUtilities_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: $defaultPadding;
  .saved-views-trigger {
    height: 24px !important;
    padding-right: $paddingHalf !important;
    padding-left: $paddingHalf !important;
    margin-right: 10px;
    width: auto !important;
    display: flex;
    align-items: center;
    &.edited {
      display: flex;
      align-items: center;
      padding-right: 0 !important;
      padding-left: 10px !important;
      span {
        white-space: pre;
        background: $primaryBlue;
        padding: 2px 4px;
        color: $white;
        border-radius: 2px;
        margin: 0 2px 0 4px;
      }
    }
    &.main {
      // background: $gray8;
      box-shadow: 0 0 1px 1px $primaryBlue;
      &:hover {
        background: $black;
      }
    }
  }
  .input-common {
    width: 94px;
    &::placeholder {
      color: $gray5;
      opacity: 1;
    }
  }
  .download-csv-link {
    margin-left: $marginHalf;
    display: block;
    cursor: pointer;
  }
  .pdf-loading,
  .csv-loading {
    & .MuiCircularProgress-root {
      position: relative;
      width: 35px !important;
      height: 35px !important;
      top: -10px;
      right: 10px;
    }
    position: relative;
    right: 0;
    width: 15px;
    height: 17px;
    padding-left: 10px;

    svg {
      transform: scale(0.35);
    }

    #AppLoading {
      background-color: transparent;
    }
  }

  .diabled-icon-button {
    pointer-events: none;
    opacity: 0.4;
  }
}

.gridUtilities_input-scope {
  border: solid 1px #b7bbc5;
  background: $white;
  // border-left: solid 1px #b7bbc5;
  padding: 2px $paddingHalf;
  border-radius: 4px;
  .input-scope {
    position: relative;
    .input-cleanText {
      position: absolute;
      right: 0;
    }
  }
  .input-icon {
    width: 13px;
  }
  // padding-left: $paddingHalf;
}

.gridUtilities_right-side {
  display: flex;
  align-items: center;
  white-space: pre;
  margin-left: $marginSmall;
  border-left: 1px solid $gray3;
  padding-left: $paddingHalf;
  .select-wrapper,
  .multi-select-wrapper {
    border-right: 1px solid $gray3;
    margin-right: $marginHalf;
    padding-right: $paddingSmall;
  }
}

.gridUtilities_left-side {
  display: flex;
  font-size: 1rem;
  width: 100%;
  align-items: center;
  position: relative;
  .settings-wrapper & {
    width: auto;
  }
}

.search-options-wrapper {
  display: flex;
}

.gridUtilities-Expand-or-reduce {
  position: absolute;
  // width: 0.8vw;
  // height: 0.8vw;
  left: 1.2rem;
  bottom: -0.5rem;
  background: #ffffff;
  z-index: 1;
  box-shadow: 0 0.1rem 0.2rem rgba(24, 29, 78, 0.1);
  border-radius: 0.4rem;
}

.gridUtilities-open {
  // height: 5rem;
  border-bottom: solid 1px #eaeaea;
}

.gridUtilities-close {
  // height: 2.3vw;
}

.gridUtilities-button-selection {
  background: #3455dd;
  color: #ffffff;
  margin-right: $marginHalf;
  font-size: 1.2rem;
  padding: 2px $defaultPadding;
  border-radius: $defaultBorderRadius;
  font-weight: $fontNormal;
}

.gridUtilities-button-selection-disabled {
  background: gray;
}
