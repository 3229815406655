@import 'styles/library.scss';

.CollapsedList {
  &.environment_variables {
    ul {
      border-left: 1px solid $gray2;
      padding-left: 10px;

      li {
        border: 0;
        padding: 0;
        margin-bottom: 10px;
        word-break: break-word;
      }
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    &.expand {
      li {
        display: inline-block;
      }
    }
    li {
      margin-bottom: 4px;
      border: 1px solid $gray2;
      border-radius: 4px;
      word-break: break-all;
      padding: 3px;
      display: none;
      &:nth-child(1),
      &:nth-child(2) {
        display: inline-block;
      }
      &:first-child {
        margin-top: 5px;
      }
      span {
        font-weight: 400;
      }
    }
  }
  > span {
    display: inline-block;
    background: $white;
    padding: 5px 0;
    font-weight: 400;
    color: $primaryBlue;
    cursor: pointer;
  }
}
