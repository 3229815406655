@import 'styles/library.scss';

.TabDiscoveryEvidence {
  width: 100%;
  height: 100%;
  .TabDiscoveryEvidenceInner {
    padding: 0;
    background-color: #ffffff;
    height: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow: hidden;

    .diagram-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0;
      &.loading {
        #AppLoading {
          position: relative;
          height: 280px;
          background: transparent;
        }
        .diagram-background {
          background-size: 0px;
          opacity: 0;
        }
        .diagram-gradient {
          opacity: 0;
        }
      }
      .DiscoveryEvidenceDiagram {
        z-index: 2;
      }
      .content-switch {
        width: 100%;
        .expand {
          position: absolute;
          width: 37px;
          height: 12px;
          bottom: -12px;
          background: $white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          cursor: pointer;
          svg {
            transform: scale(0.8);
          }
        }
        .scrollbar-common {
          display: flex;
          overflow: hidden;
          flex: 1;
        }
        > .collapsed {
          max-width: 100%;
          overflow: hidden;
          .DiscoveryEvidenceDiagram {
            position: absolute;
            left: $defaultPadding;
            width: calc(100% - 30px);
            overflow-x: auto;
            padding-bottom: $paddingHalf;
          }
        }
        > .expanded,
        > .collapsed {
          opacity: 0;
          height: 0;
          transition: all 0.2s;
          pointer-events: none;
        }
        > .expanded {
          &.show {
            opacity: 1;
            height: 270px;
            pointer-events: all;
          }
        }
        > .collapsed {
          padding: 0 $defaultPadding;
          &.show {
            opacity: 1;
            height: 50px;
            pointer-events: all;
          }
        }
      }
      header {
        padding: 20px 20px 0 20px;
        font-size: 1.2rem;
        z-index: 2;
        position: relative;
        display: flex;
        align-self: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: baseline;
        width: 100%;
        box-sizing: border-box;
        .confidence-level {
          .ConfidenceLevel {
            align-items: baseline;
            display: inline-flex;
            margin-left: 2px;
            .confidence-label {
              color: $gray8;
              font-weight: 400;
              margin-left: 5px;
              font-size: 1.2rem;
              text-transform: capitalize;
            }
          }
        }
        > div {
          display: flex;
          // flex: 1 0;
          > div {
            margin-right: $defaultMargin;
            display: flex;
            align-items: flex-end;
            > span {
              font-weight: 500;
              color: $gray8;
              margin-right: 4px;
            }
          }
        }
        .download-csv-link {
          cursor: pointer;
        }
        // > a {
        // }
      }
      .diagram-gradient {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: radial-gradient($white 10%, $white 70%, rgba(#5886fb, 0.1) 100%);
        z-index: 0;
        opacity: 1;
        transition: all 0.5s;
        &.hide {
          opacity: 0;
        }
      }
      .diagram-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 1;
        background-size: 15px;
        transition: all 0.5s;
        &.hide {
          opacity: 0;
        }
      }
    }
  }
}
