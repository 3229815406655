@import 'styles/library.scss';

.NotAvailable {
  .not-available-wrapper {
    flex: 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      font-size: 1.2rem;
      color: $gray4;
      max-width: 250px;
      text-align: center;
      line-height: 150%;
    }
    .button-wrapper {
      button {
        width: 150px;
        flex-basis: 150px;
      }
    }
  }
}
