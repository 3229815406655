@import '../../../styles/library.scss';

.singleAsset {
  display: flex;
  width: 100%;
  margin-top: $headerMarginTop;
  height: calc(100vh - #{$headerMarginTop} - #{$addintionalBottomSpace});
  position: relative;
  z-index: 1;
  .singleAsset-tab-inner {
    flex: 1 0;
    display: flex;
    overflow: hidden;
    position: relative;
  }
  &.is_virtual {
    .panel-header-container {
      padding-bottom: 16px !important;
    }
    .NoIssuesIcon {
      .cyberpion-ui-text {
        display: none;
      }
    }
  }
}

.singleAsset-scope {
  flex: 1 0;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.defaultIcon-notData {
  padding: 0.2rem 0 0 0;
  background-color: #ffff;
  height: 108%;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
