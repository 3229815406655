@import '../../../../styles/library.scss';

.ChartLineLegend-scope {
  display: flex;
  justify-content: space-between;
  color: $white;
  margin-top: $defaultMargin;
}

.ChartLineLegend-label-scope {
  display: flex;
  font-size: 1rem;
  margin-bottom: $marginHalf * 0.5;
  align-items: flex-start;
}

.ChartLineLegend-label {
  margin-left: $marginHalf * 0.5;
}

.ChartLineLegend-count {
  font-weight: $fontBold;
  font-size: 1.2rem;
  margin-left: $defaultMargin;
}
