@import 'styles/library.scss';

.DiscoveryInvestigationTool {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - #{$headerMarginTop} - #{$addintionalBottomSpace});
  position: relative;
  z-index: 1;
  .gridUtilities_input-scope {
    display: none;
  }

  .Discovery-Results-Header {
    margin-bottom: 16px;
  }
  .discovery-wrapper {
    margin-top: 16px;
    display: contents;

    .discovery-grid {
      height: calc(100vh - 160px);
      z-index: 1;
    }
    .Button.size-small {
      width: 55px;
    }
    & .MuiDataGrid-columnHeaderCheckbox {
      display: none !important;
    }
    & .MuiDataGrid-cellCheckbox {
      display: none !important;
    }
  }
}
