@import 'styles/library.scss';

.WhiteListEdit {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 2;
  display: flex;
  flex-direction: column;

  .popup-inner {
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    flex: 1 0;
    > div {
      > div {
        margin-bottom: $defaultMargin;
        input {
          width: 250px;
          margin-right: $marginSmall;
          padding: 8px;
          border-radius: $borderRadiusSmall;
        }
        button {
          margin-right: $marginSmall;
        }
      }
    }
    .buttons-wrapper {
      display: flex;
      justify-content: flex-end !important;
    }
  }
}
