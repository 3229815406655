@import 'styles/library.scss';

.SavedViewOptionsMenu {
  background: #f9f9f9;
  position: absolute;
  width: 200px;
  // position: fixed;
  margin-left: 20px;
  transform: translate(-195px, 30px);
  z-index: 3;
  box-shadow: 0 0px 5px #aaa;
  border-radius: 4px;
  #AppLoading {
    > div:first-child {
      height: 50px !important;
    }
    > div:last-child {
      margin-top: 10px;
      font-size: 1.1rem;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding: $defaultPadding * 0.75;
      display: flex;
      align-items: center;
      &.grayed-out {
        opacity: 0.3;
        pointer-events: none;
        cursor: default;
      }
      img {
        margin-right: $marginHalf;
        height: 12px;
        width: 12px;
      }
      &:hover {
        font-weight: 500;
      }
      &:last-child {
        color: $red;
        padding-bottom: $defaultPadding * 0.75;
        padding-top: $defaultPadding * 0.75;
        border-top: 1px solid $paleBlue;
      }
      &:first-child {
        padding-top: $defaultPadding * 0.75;
        padding-bottom: 0;
      }
    }
  }
}
