@import '../../../../../styles/library.scss';

.option-wrapper {
  display: flex;
  font-size: 1.2rem;
  color: #6d6d7a;
  align-items: center;
  padding-top: $paddingSmall;
  justify-content: space-between;
}

.option {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.MGS-option-text {
  padding: 0 0 0 $paddingSmall;
}
