@import 'styles/library.scss';

@keyframes low_border {
  0% {
    border-color: #00bdd7;
  }
  100% {
    border-color: #e8e8e8;
  }
}

@keyframes medium_border {
  0% {
    border-color: #e7b70e;
  }
  100% {
    border-color: #e8e8e8;
  }
}

@keyframes high_border {
  0% {
    border-color: #e06915;
  }
  100% {
    border-color: #e8e8e8;
  }
}

@keyframes critical_border {
  0% {
    border-color: #fb5858;
  }
  100% {
    border-color: #e8e8e8;
  }
}

@keyframes low_background {
  0% {
    background-color: $lighterBlue;
  }
  50% {
    background-color: #00bdd715;
  }
  100% {
    background-color: $lighterBlue;
  }
}

@keyframes medium_background {
  0% {
    background-color: $lighterBlue;
  }
  50% {
    background-color: #e7b70e15;
  }
  100% {
    background-color: $lighterBlue;
  }
}

@keyframes high_background {
  0% {
    background-color: $lighterBlue;
  }
  50% {
    background-color: #e0691515;
  }
  100% {
    background-color: $lighterBlue;
  }
}

@keyframes critical_background {
  0% {
    background-color: $lighterBlue;
  }
  50% {
    background-color: #fb585815;
  }
  100% {
    background-color: $lighterBlue;
  }
}

@keyframes low_round {
  0% {
    box-shadow: 0 0 4px -3px #00bdd7;
  }
  50% {
    box-shadow: 0 0 4px 3px #00bdd7;
  }
  100% {
    box-shadow: 0 0 4px -3px #00bdd7;
  }
}

@keyframes medium_round {
  0% {
    box-shadow: 0 0 4px -3px #e7b70e;
  }
  50% {
    box-shadow: 0 0 4px 3px #e7b70e;
  }
  100% {
    box-shadow: 0 0 4px -3px #e7b70e;
  }
}

@keyframes high_round {
  0% {
    box-shadow: 0 0 4px -3px #e06915;
  }
  50% {
    box-shadow: 0 0 4px 3px #e06915;
  }
  100% {
    box-shadow: 0 0 4px -3px #e06915;
  }
}

@keyframes critical_round {
  0% {
    box-shadow: 0 0 4px -3px #fb5858;
  }
  50% {
    box-shadow: 0 0 4px 3px #fb5858;
  }
  100% {
    box-shadow: 0 0 4px -3px #fb5858;
  }
}

.threat-center-item {
  display: flex;
  margin: 20px 0;
  border: 1px solid #e8e8e8;

  &.low {
    -webkit-animation: low_border 4s;
    animation: low_border 4s;
  }
  &.medium {
    -webkit-animation: medium_border 4s;
    animation: medium_border 4s;
  }
  &.high {
    -webkit-animation: high_border 4s;
    animation: high_border 4s;
  }
  &.critical {
    -webkit-animation: critical_border 4s;
    animation: critical_border 4s;
  }

  .left-indicator-line {
    width: 5px;
    z-index: 1;
  }
  .round-indicator-outer {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 6px solid #f9faff;
    width: 11px;
    height: 11px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -25px;
      transform: translate(-50%, -50%);
      background-color: #eaeaea;
      width: 40px;
      height: 1px;
    }

    .round-indicator-inner {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: #fff;
      width: 6px;
      height: 6px;
      &.low {
        -webkit-animation: low_round 1s ease-in 3 forwards;
        animation: low_round 1s ease-in 3 forwards;
      }
      &.medium {
        -webkit-animation: medium_round 1s ease-in 3 forwards;
        animation: medium_round 1s ease-in 3 forwards;
      }
      &.high {
        -webkit-animation: high_round 1s ease-in 3 forwards;
        animation: high_round 1s ease-in 3 forwards;
      }
      &.critical {
        -webkit-animation: critical_round 1s ease-in 3 forwards;
        animation: critical_round 1s ease-in 3 forwards;
      }
    }
  }
  // }
  .threat-center-item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 0;
    background-color: $lighterBlue;
    transition: background-color 0.5s;
    &.low {
      -webkit-animation: low_background 4s;
      animation: low_background 4s;
    }
    &.medium {
      -webkit-animation: medium_background 4s;
      animation: medium_background 4s;
    }
    &.high {
      -webkit-animation: high_background 4s;
      animation: high_background 4s;
    }
    &.critical {
      -webkit-animation: critical_background 4s;
      animation: critical_background 4s;
    }

    .content-top-info {
      display: flex;
      // align-items: center;
      // padding: 0 20px;
      // width: 100%;
      height: 40px;
      border-bottom: 1px solid #e8e8e8;

      .with-bullets {
        display: flex;
        .cyberpion-ui-text {
          padding-right: 3px;
        }
        .header-bullet {
          &:not(:last-child):after {
            content: '';
            display: flex;
            border-radius: 50%;
            width: 5px;
            height: 5px;
            background-color: #8c909a;
            margin-left: 8px;
            align-self: center;
          }
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }
      .action-items-chip {
        margin-left: 8px;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: #8c909a;
        align-self: center;

        .cyberpion-ui-text {
          padding-right: 0;
        }
        &.hover {
          &:hover {
            cursor: pointer;
            background-color: #60646a;
          }
        }
      }
      .tags-chip-wrapper {
        display: flex;
        align-items: center;

        .item-tags-chip {
          margin-right: 8px;
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid #dfe1e4;
          background-color: #fff;
          .cyberpion-ui-text {
            padding-right: 0;
          }
        }
      }
    }
    .content-main {
      display: flex;
    }
    .content-main-left {
      display: flex;
      flex-direction: column;
      margin-left: 26px;
      margin-top: 20px;
      flex-grow: 1;

      .threat-center-item-description {
        line-height: 1.35;
        font-family: Rubik;
        font-size: 13px;
        color: #60646a;

        a {
          color: #3455dd;
        }
        .references {
          margin-top: 4px;
        }
        .list {
          padding-left: 20px;
          margin: 4px 0 4px 0;
        }
      }
    }
    .threat-center-item-collapse {
      margin-top: 6px;
      margin-bottom: 10px;
      display: flex;

      &:hover {
        cursor: pointer;
      }
    }
    .threat-center-item-artifacts-wrapper {
      display: flex;
      margin: 10px 20px;

      .threat-center-item-artifacts {
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 4px 8px;
        background-color: #e5f0ff;
        margin-right: 8px;
        color: #3455dd;

        &:hover {
          background-color: darken(#e5f0ff, 3%);
        }
      }
    }
    .threat-item-findings {
      .link {
        padding: 2px 0;
        border-radius: 4px;
        width: fit-content;
        min-width: 28px;
        // color: #3455dd;
        text-align: center;
        &:hover {
          cursor: pointer;
          background-color: #ecedff;
        }
      }
    }
  }
}
