@import 'styles/library.scss';

.integration {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  .scrollbar-common {
    scrollbar-color: #fff #fff !important;
  }
  > div {
    flex: 1 0;
  }
  > .IntegrationHeader,
  > .ButtonsWrapper {
    flex: 0;
  }
  .main-integration-wrapper {
    overflow-y: auto;
    height: calc(100vh - 290px);
    position: relative;
    &.showDeletePopup {
      opacity: 0.4;
      height: calc(100vh - 405px);
    }
    #AppLoading {
      z-index: 2;
    }
    .group {
      margin-top: $defaultMargin * 1.5;
      &:first-child {
        margin-top: 0;
      }
      .group-headline {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        h6 {
          margin: 0;
          font-size: 1.2rem;
          font-weight: 400;
          flex: 0;
          white-space: pre;
        }
        &:after {
          content: '';
          flex: 2 0;
          height: 1px;
          background: $paleBlue;
          margin-left: $defaultMargin;
        }
      }
      .jira-group {
        border: 1px solid $paleBlue;
        border-radius: 4px;
        padding: $defaultPadding;

        .jira-group-headline {
          display: flex;
          align-items: center;
          // margin-bottom: 4px;
          justify-content: space-between;
          svg {
            &.rotate {
              transform: rotate(180deg);
            }

            &.urotate {
              transform: rotate(0deg);
            }
          }
          .chevron-icon {
            svg {
              width: 24px;
              height: 24px;
            }
          }
          h6 {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 400;
            // flex: 0;
          }
          .devider {
            background: $paleBlue;
            height: 1px;
            flex: 1;
            margin: 0 10px;
          }
        }
      }

      .text-field-wrapper {
        position: relative;
        margin-top: 4px;
        .info {
          position: absolute;
          bottom: 12px;
          right: 8px;
        }
        .icon-visibility {
          position: absolute;
          top: 26px;
          right: 10px;
          width: 20px;
          height: 20px;
          background-color: #fff;
          align-items: center;
          display: flex;
          justify-content: center;
          svg {
            transform: scale(1.2);
          }
          &:hover {
            cursor: pointer;
            background-color: $paleBlue;
          }
        }
        .MuiInputBase-root {
          height: 38px;
          input {
            font-size: 12px;
          }
        }
        .primary_key,
        .hec_token {
          .MuiOutlinedInput-input {
            width: 90%;
          }
        }
      }
      .input {
        margin-top: $defaultMargin;

        label {
          padding: 0 5px;
          color: $gray4 !important;
          margin-left: -1px;
        }
        .MuiInputLabel-root {
          padding-left: 0;
        }
        label:not(.Mui-focused):not(.MuiFormLabel-filled) {
          transform: translate(14px, 13px) scale(1);
          color: $gray19;
          font-size: 1.1rem;
        }
        label.MuiFormLabel-filled:not(.Mui-focused) {
          color: $gray19;
          background: $white;
        }
        label.Mui-focused {
          color: $gray19;
          background: $white;
        }
        label.MuiInputLabel-animated {
          transform: translate(14px, -7px) scale(1);
          font-size: 1rem;
        }
        input {
          padding: 12px;
          outline: 0;
          color: $gray19;
          font-size: 1.1rem;
        }
        fieldset {
          border-color: $paleBlue;
          border-width: 1px;
        }
      }
      ul {
        margin: 0;
        list-style-type: none;
        padding: 0;
      }
      .cyberpion-ui-dropdown {
        width: 100% !important;
      }
      .drop-down-freq {
        display: flex;
        margin-top: 20px;

        .DropDown {
          flex-grow: 1;
          & .MuiFormLabel-root {
            font-size: 11px;
            color: #5d6576 !important;
          }
          & .MuiFormLabel-root {
            padding: 0 !important;
          }
          & .MuiSelect-select {
            // display: flex !important;
            // align-items: center !important;
            min-height: 0;
            .cyberpion-ui-text {
              font-size: 12px;
            }
          }
          &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
