@import 'styles/library.scss';

.NoRules {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  > div {
    width: 400px;
    text-align: center;
    img {
      height: 100px;
    }
    p {
      color: $gray4;
      font-size: 1.2rem;
      width: 300px;
      margin: 20px auto;
      white-space: break-spaces;
      text-align: center;
      line-height: 140%;
    }
    button {
      margin: 0 auto;
    }
  }
}
