@import 'styles/library.scss';

.Integrations {
  // width: 700px;
  .page-not-fount {
    height: calc(100vh - 150px);
  }
  > h2 {
    color: $gray5;
    font-size: 1.4rem;
    &:first-child {
      margin-top: 0;
    }
  }
  .integrations-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    > li {
      margin-right: $defaultMargin;
      margin-bottom: $defaultMargin;
    }
    > li:last-child {
      margin-right: 0;
    }
  }
}
