@import 'styles/library.scss';

.RuleWrapper {
  border: 1px solid $paleBlue;
  position: relative;
  margin-bottom: 40px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  .line {
    position: absolute;
    top: 50%;
    left: -25px;
    width: 25px;
    height: 1px;
    background: $paleBlue;
  }
  .line-2 {
    position: absolute;
    height: calc(50% + 40px);
    top: 50%;
    width: 1px;
    background: $paleBlue;
    left: -25px;
  }
  &:not(:first-child) {
    .line-2 {
      top: -2px;
      height: calc(100% + 42px);
    }
  }
  &:not(:last-child) {
    .or {
      display: flex;
    }
  }

  &:last-child {
    > .add-group {
      display: flex;
    }
  }

  .add-group {
    position: absolute;
    width: 20px;
    height: 20px;
    display: none;
    align-items: center;
    justify-content: center;
    top: calc(100% + 30px);
    left: -35px;
    border: 1px solid $paleBlue;
    border-radius: 3px;
    background: $white;
    color: $primaryBlue;
    font-size: 1.2rem;
    cursor: pointer;
  }
  .or {
    position: absolute;
    width: 30px;
    height: 20px;
    left: -40px;
    font-size: 1.2rem;
    top: calc(100% + 10px);
    background: $white;
    display: none;
    border: 1px solid $paleBlue;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }
}
