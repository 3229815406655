@import 'styles/library.scss';

.SubsidiariesDropDown {
  position: relative;
  display: inline-block;
  height: 100%;

  width: 250px;
  height: 65px;
  border-radius: 4px;
  font-size: 13px;

  &.inactive {
    pointer-events: none;
  }
  &:hover {
    background-color: #1a1a1c;
    border: 2px solid #000000;
  }
  .arrow-wrapper {
    transition: transform 0.2s;
    width: 20px;
    height: 20px;
    transform-origin: 50% 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &.rotate {
      transform: rotate(180deg);
    }
    svg {
      position: relative;
      top: 3px;
      transform: scale(1.4);
    }
  }
  .SubsidiariesDropDown-inner {
    width: 400px;
    height: 0;
    background: $white;
    z-index: -1;
    overflow: hidden;
    position: fixed;
    transition: all 0.2s;
    border-radius: 6px;

    &.no-events {
      cursor: none;
      pointer-events: none;
    }
    &.open {
      z-index: 999;
      height: 300px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    }
    & .scrollbar-common.no-header {
      height: 100% !important;
    }
    h6 {
      color: $gray5;
      margin: 8px 20px;
    }
    .utility-row {
      margin: 8px 20px;
      display: flex;
      > img {
        margin-right: 5px;
      }
      input {
        border: 0;
        outline: 0;
        flex: 1 0;
      }
      .buttons-wrapper {
        height: 100%;
        display: flex;
        align-items: center;

        > .csv-link {
          margin-left: $marginHalf;
          display: inline-flex;
          img {
            width: 18px;
          }
        }
        .__react_component_tooltip {
          &:before {
            display: none;
          }
        }
        > .buttons {
          display: flex;
          border-radius: 3px;
          background: #f0f2f6;
          padding: 2px;
          button {
            height: 100%;
            border: 0;
            background: #f0f2f6;
            border-radius: 3px;
            font-size: 1.2rem;
            padding: 5px 10px;
            cursor: pointer;
            &.active {
              background: $white;
              color: $gray5;
            }
          }
        }
        .download-csv-link {
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }
    .empty {
      border-top: 1px solid $gray1;
      color: $gray5;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 140px;
      font-size: 1.3rem;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      border-top: 1px solid $gray1;
      height: 160px;
      li {
        &.no-events {
          cursor: none;
          pointer-events: none;
        }
        .SubsidiaryRow {
          border: 0;
          padding: 0;
          margin: 0;
          box-shadow: none;
        }
        padding: 10px 20px;
        border-bottom: 1px solid $gray1;
      }
    }
  }
}
