@import '../../../../../../../styles/library.scss';

.multipleChoice-scope {
  position: absolute;
  min-width: 14em;
  color: #6d6d7a;
  background: #ffffff;
  box-shadow: 0 0 0.63em rgba(24, 29, 78, 0.1);
  z-index: 1;
  top: 35px;
  padding: $paddingHalf;
  .Toggle {
    margin-bottom: 12px;
  }
}

.multipleChoice_body-scope {
  max-height: 250px;
  overflow: auto;
  font-size: 1rem;
  &.blank_selected {
    > div:not(:first-child) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.multipleChoice-option-scope {
  display: flex;
  margin: $marginHalf;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &.inactive {
    pointer-events: none;
  }
  > svg {
    margin-right: $marginSmall;
  }
  .Flag {
    margin-right: $marginSmall;
  }
  .value {
    width: $selectItemsWidth;
  }
  &.hidden {
    display: none;
  }
}

.IconRisk-scope {
  margin: 0 0.4em 0 0.5em;
}
