.PendingScan {
  display: flex;
  position: relative;
  padding-bottom: 8px;
  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      padding: 0 7px;
    }
  }
  .info {
    margin-left: 12;
    cursor: pointer;
    position: absolute;
    right: -22px;
    top: 9px;
  }
  .tooltip {
    position: absolute;
    top: -55px;
    right: 0;
    height: 135px;
    width: 350px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 4px #ccc;
    padding: 16px;
    box-sizing: border-box;
    pointer-events: none;
    transform: scale(0.8);
    opacity: 0;
    z-index: -1;
    transition: all 0.1s;
    &.visible {
      transform: scale(1);
      opacity: 1;
      z-index: 1;
      pointer-events: all;
    }
    h6 {
      margin: 0;
      padding: 0;
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.2rem;
      margin: 0;
      padding: 0;
      margin-bottom: 16px;
    }
    .Button {
      width: auto;
      height: 32px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
