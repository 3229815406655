@import '../../../../../../../styles/library.scss';

.filterRadioButton-scope {
  position: absolute;
  color: $gray5;
  background: $white;
  box-shadow: 0 0 1rem rgba(24, 29, 78, 0.1);
  z-index: 1;
  top: 30px;
  font-size: 1.2rem;
  padding: $defaultPadding;
  width: 200px;
  box-sizing: border-box;
}

.filterRadioButton-body-scope {
  max-height: 300px;
  overflow: auto;
}

.filterRadioButton-option-scope {
  display: flex;
  margin-bottom: $marginHalf;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
}

.filterRadioButton-RadioButton {
  position: relative;
  margin-right: 5px;
}

.filterRadioButton-label {
  // margin: 0 $marginHalf;
  cursor: pointer;
}
