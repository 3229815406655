@import 'styles/library.scss';

.InfoDiscoveryPopup {
  position: fixed;
  background: $white;
  width: 450px;
  border-radius: 8px;
  box-shadow: 0px 0px 50px 0px rgba(44, 44, 48, 0.5);
  padding: 20px;

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;

    button {
      &:not(:first-child) {
        margin-left: 8px;
        flex-basis: auto !important;
        width: 100px !important;
      }
    }
  }
}
