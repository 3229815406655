.RemoveRow {
  img {
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      // filter: grayscale(100);
      // opacity: 0;
      display: none;
    }
  }
}
