.OpenNewTabAction {
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }
  svg {
    transform: scale(1.2);
  }
}
