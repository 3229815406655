@import 'styles/library.scss';

.InputWithLabel {
  width: 100%;
  position: relative;
  label {
    position: absolute;
    font-size: 1.2rem;
    color: $gray3;
    transform: translate(13px, 28px);
    left: 0;
    bottom: 38px;
    pointer-events: none;
    transition: all 0.5s;
  }
  .delete {
    position: absolute;
    right: 10px;
    top: 9px;
    cursor: pointer;
  }
  input {
    width: 100%;
    background: $lighterBlue;
    border: 1px solid $paleBlue;
    font-size: 1.2rem;
    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
    outline: 0;
    &:focus,
    &.filled {
      + label {
        transform: translate(10px, 11px);
        padding: 1px 4px;
        border-radius: 4px;
        background: $white;
        font-size: 1rem;
      }
    }
  }
}
