.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    transform: scale(2);
    margin-bottom: 30px;
  }
}
