@import 'styles/library.scss';

.parent-company-dropdown {
  .MuiPaper-root {
    width: 220px;
  }
}
.GroupsPopsideHeader {
  display: flex;
  flex-direction: column;

  padding: 24px;

  input {
    padding: 8px 12px !important;
    border-radius: 4px !important;
    border: 1px solid #dadde0 !important;
    color: #5d6576;
    height: 16px;
    width: 220px !important;
  }

  &.disabled {
    .DropdownList,
    .FlagsDropdown {
      pointer-events: none;
      opacity: 0.4;
    }
    input {
      opacity: 0.4;
    }
  }

  .DropdownList {
    .dropdown {
      width: 220px;
    }
  }
  .dropdown {
    height: 30px !important;
    color: #5d6576 !important;
    box-shadow: none !important;
  }
}
