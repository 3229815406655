@import 'styles/library.scss';

.Settings {
  display: flex;
  width: 100%;
  margin-top: $headerMarginTop;
  height: calc(100vh - #{$headerMarginTop} - #{$addintionalBottomSpace});
  position: relative;
  z-index: 1;
  &.ontop {
    z-index: 4;
  }
  [data-field='actions'] {
    justify-content: center !important;
    .MuiDataGrid-columnHeaderDraggableContainer {
      width: auto;
    }
  }
  .MuiDataGrid-columnHeaders {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    position: absolute;
    top: -1px;
  }
  .gridUtilities_right-side {
    border-left: 0;
  }
  .MuiDataGrid-pinnedColumnHeaders {
    box-shadow: none;
  }
  .MuiDataGrid-pinnedColumns {
    box-shadow: none;
    .MuiDataGrid-row {
      position: relative;
      &:after {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        box-shadow: 0 0 5px #eee;
        left: 0px;
        z-index: 999;
      }
    }
  }
  .settings-wrapper {
    flex: 1 0;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: $borderRadiusBig;
    // height: calc(99vh - 140px);
    position: relative;
    &.ontop {
      z-index: 11;
    }
  }
}
