@import '../../../styles/library.scss';

.ms-options-wrapper {
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  position: absolute;
  background: $white;
  z-index: 1;
  right: -10px;
  top: 30px;
  width: 240px;
}

.select-items-scope {
  max-height: 250px;
  overflow: auto;
  padding: $paddingSmall $defaultPadding;
  .hide {
    display: none;
  }
}

.multi-select-wrapper {
  position: relative;
  user-select: none;
  .SelectAll {
    margin: $marginHalf 0 0 $marginHalf;
    color: $gray5;
    font-size: 1.2rem;
  }
}

.ms-input-scope {
  border-bottom: solid 1px #f5f5f5;
  padding: $defaultPadding;
}
