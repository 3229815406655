.input-scope {
  display: flex;
  align-items: center;
}

.input-common {
  background: #7fffd400;
  border: solid 1px #0000ff00;
  font-size: 1.2rem;
}

.input-common:focus-visible {
  outline: none;
}

::-webkit-input-placeholder {
  color: #b6b6bc;
}

::-moz-placeholder {
  color: #b6b6bc;
}
:-ms-input-placeholder {
  color: #b6b6bc;
}

::placeholder {
  color: #b6b6bc;
}

.Input_error-text {
  font-size: 1rem;
  color: #f46666;
  padding-top: 3px;
}

.error {
  border: solid 1px #f46666 !important;
}

.input-icon {
  width: 16px;
}

.input-cleanText {
  font-size: 1.6rem;
  transform: rotate(45deg);
  cursor: pointer;
  line-height: 0.6;
  font-weight: 100;
  color: #5d6576;
}
