@import '../../../../../styles/library.scss';

.action-items-pdf-table {
  & .table-row {
    padding: 0 10px;
    min-height: 30px;
    background-color: $PDFTableRowGray;
    margin-bottom: 5px;
    border-radius: 4px;
  }

  & .icon-risk {
    position: relative;
    top: 2px;
    svg {
      transform: scale(0.8);
    }
  }

  & .action-item-wrapper {
    position: relative;
    left: -10px;
    align-items: center;
  }
  & .action-item-importance {
    top: 2px;
    position: relative;

    svg {
      transform: scale(0.7);
    }
  }

  & .action-item-host {
    position: relative;
    // top: 9px;
    left: -35px;
    padding: 5px 0;
  }
}
