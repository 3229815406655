.score-render {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  &.small {
    margin-left: 10px;
    font-size: 1rem;
    font-weight: 400;
    .score-value {
      top: auto;
      left: 25px;
    }
    .score-background,
    .score-border {
      height: 16px;
      width: 16px;
    }
  }
}

.score-background {
  height: 24px;
  width: 24px;
  background: rebeccapurple;
  opacity: 0.2;
  position: absolute;
  transform: rotate(45deg);
}

.score-border {
  height: 24px;
  width: 24px;
  border: solid 1px rebeccapurple;
  position: absolute;
  transform: rotate(45deg);
}

.score-iconArrowTrend {
  position: absolute;
  top: 18px;
  left: 18px;
}

.score-value {
  position: absolute;
  top: 33px;
  font-size: 1rem;
}
