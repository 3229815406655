@import 'styles/library.scss';

.ReportsList {
  min-height: 100px;
  position: relative;
  border-left: 1px solid $gray9;
  .report-list-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray9;
    h6 {
      margin-bottom: 0 !important;
      border-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
    button {
      white-space: nowrap;
      width: fit-content;
      height: 24px;
      padding: 0 10px;
    }
    .scrollbar-common {
      scrollbar-color: white #ccc !important;
      flex: 1 0;
    }
  }
  .list-wrapper {
    overflow-y: auto;
    max-height: 310px;
  }
  .empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
    color: $gray5;
    height: 240px;
    img {
      width: 50px;
      margin-bottom: $defaultMargin;
      margin-top: -$defaultMargin;
    }
  }
}
