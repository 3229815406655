@import 'styles/library.scss';

.DeletePromptPopup {
  position: fixed;
  background: $white;
  width: $popUpWidth * 0.5;
  border-radius: $borderRadiusSmall;

  .popup-inner {
    padding: $defaultPadding * 1.5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 150%;

    h2 {
      font-size: 1.6rem;
      margin: 0 0 $defaultMargin 0;
    }

    h3 {
      font-size: 1.2rem;
      margin: 0;
      font-weight: 400;
    }

    ul {
      list-style-type: none;
      margin: $marginHalf 0;
      padding: 0;
      width: 100%;
      word-break: break-all;
      li {
        font-size: 1.2rem;
        color: $gray5;
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      margin: $defaultMargin 0 0 0;
      button {
        margin-left: $marginHalf;
      }
    }
  }
}
