@import '../../../../../styles/library.scss';

.DH-header-wrapper {
  display: flex;
  font-size: 1.2rem;
  color: #6d6d7a;
  cursor: pointer;
  align-items: center;
  margin-right: 2px;
}

.DH-header {
  padding: 0 2px 0 0;
}
