@import '../../../../styles/library.scss';

.collapse-panel-dashboard {
  & .cyberpion-ui-na {
    opacity: 0.25;
  }
  & .top-panel-dashboard {
    height: 100%;
    & .dashboard-action-items {
      // width: 350px;
    }
    & .dashboard-active-protection {
      // width: 400px;
      // position: relative;
      // right: 20px;
    }
    & .action-items-wrapper {
      flex-wrap: nowrap;
      padding-top: 30px;

      .data-panel-content {
        margin-top: 4px;
      }

      & .data-panel-item:not(:last-child) {
        margin-right: 25px;
      }

      & .data-panel-container:last-child {
        // width: 130px;
        // margin-right: 20px;
      }
    }

    & .panel-wrapper-last {
      //width: 375px;
    }
  }

  & .org-risk-score-wrapper {
    // flex-grow: 1;
    // width: auto;
    // margin: auto;

    & .data-panel-wrapper {
      padding-top: 12px;
    }
    & .org-risk-score {
      flex-wrap: nowrap;
      & .chart-size {
        flex: 1;
        // width: calc(100% - 120px);
      }
      & .letter-0,
      .letter-1 {
        color: $white;
      }
      & .org-risk-score-indicator {
        padding-top: 42px;
        padding-left: 30px;
        width: 100px;
        &.data-panel-na {
          & .cyberpion-ui-score-indicator {
            background-color: #555659;

            border-color: #ffffff;
          }
        }
      }
      & .org-risk-score-overall {
        padding-top: 25px;
        padding-left: 25px;
        width: 105px;
      }

      & .data-panel-trend {
        width: 20px;
        padding-top: 16px;
        padding-left: 5px;
        margin-left: 0;
      }
    }
  }
}
