@import '../../../../styles/library.scss';

.topAssetsAtRisk-scope {
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid $backgroundBlue;
  color: $gray5;
  border-radius: $defaultBorderRadius;
  margin-top: $defaultMargin;
  padding: $defaultPadding;
  flex: 1 0;
}

.topAssetsAtRisk-label-scope {
  display: flex;
  align-items: center;
}

.topAssetsAtRisk-header-scope {
  display: flex;
  justify-content: space-between;
}

.topAssetsAtRisk-items-scope {
  padding: $paddingHalf;
  background: $white;
  border: 1px solid $backgroundBlue;
  border-radius: $defaultBorderRadius;
}

.topAssetsAtRisk-item-scope {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $paddingHalf 0;
  border-bottom: solid 1px $gray9;
  font-size: 1rem;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}

.topAssetsAtRisk-item-scope:last-child {
  border-bottom: none;
}

.topAssetsAtRisk-icon {
  cursor: pointer;
}
