@import 'styles/library.scss';
.ToggleButtons {
  background: $backgroundBlue;
  width: auto;
  font-size: 12px;
  display: flex;
  flex: 1 0;
  margin-right: 10px;
  align-items: center;
  position: relative;
  border-radius: 4px;
  color: $gray4;
  z-index: 0;
  cursor: pointer;
  > span {
    display: flex;
    white-space: nowrap;
    margin: 0 12px;
    position: relative;
    z-index: 1;
    user-select: none;
    &:hover,
    &.chosen {
      color: #666666;
    }
  }
  .selector {
    position: absolute;
    background: $white;
    width: 50%;
    height: 100%;
    z-index: 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px lighten($black, 80%);
    border: 1px solid $backgroundBlue;
    box-sizing: border-box;
    left: 0;
    transition: left 0.15s;
    &.toggled {
      left: 50%;
    }
  }
}
