@import 'styles/library.scss';

.fqdns-info-panel {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  .header {
    display: flex;
    padding: 8px 10px 16px 20px;
    flex: 0;
    justify-content: space-between;
  }
  .info-panel-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $gray2;
    border-bottom: 1px solid $gray2;
    padding: 8px 18px;

    .Search {
      border: none;
      #input-search {
        width: 220px;
      }
    }
    .download-csv-link {
      cursor: pointer;
    }
  }

  .info-panel-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: absolute;
    height: 84%;
    width: 100%;
    overflow: scroll;
    margin-top: 0;

    .info-panel-fqdns-item {
      padding: 15px 20px;
      border-bottom: 1px solid $gray2;
      word-break: break-word;

      .fqdn-link {
        .cyberpion-ui-text {
          &:hover {
            color: #3455dd;
          }
        }
      }
    }
  }
}
