@import '../../../../styles/library.scss';

.actionItemsTrend-scope {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  position: relative;
  padding: $defaultPadding;
  border-radius: $defaultBorderRadius;
}
.actionItemsTrend-sections-scope {
  display: flex;
  align-items: center;
  .formGraph-header-scope {
    margin-right: 30px;
    &:nth-child(3) {
      margin-right: $defaultMargin;
    }
  }
  .formGraph-number {
    color: $gray6;
  }
  .areaChart-scope {
    width: 100%;
  }
  .formGraph-header {
    color: $gray6;
  }
}

.actionItemsTrend-header-scope {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.actionItemsTrend-label-scope {
  display: flex;
}
