.DiscoveryInvestigationDomain {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .icon-clipboard,
  .icon-open {
    margin-left: 8px;

    &:hover {
      cursor: pointer;
    }
  }
}
