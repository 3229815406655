@import 'styles/library.scss';

.EditableColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &.loading {
    width: 100%;
    height: 100%;
  }
  #AppLoading {
    background: transparent;
    height: 25px;
    top: 12px;
  }
  input {
    font-size: 1.1rem;
    padding: 3px;
    border: 1px solid $gray1;
    outline: 0;
    border-radius: 4px;
    margin-right: $marginSmall;
    background: transparent;
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    &.edit-mode {
      background: $white;
      pointer-events: all;
      text-overflow: unset;
    }
  }

  > img {
    cursor: pointer;
  }

  .buttons-wrapper {
    display: flex;
    button {
      padding: 5px;
      cursor: pointer;
      &:first-child {
        margin-right: 4px;
      }
    }
  }
}
