@import 'styles/library.scss';

.AffectedAssets {
  border: 1px solid $gray13;
  box-sizing: border-box;
  padding: $defaultPadding 0 0 0;

  h4 {
    padding: 0 $defaultPadding;
  }
  .AffectedAssets-types {
    display: flex;
    font-size: 1.3rem;
    color: $gray4;
    font-weight: 400;
    padding: 0 $defaultPadding;
    > span {
      display: flex;
      align-items: center;
      span {
        cursor: pointer;
      }
      a {
        display: inline-flex;
        align-items: center;
        img {
          margin-left: 4px;
        }
      }
    }
    > span:first-child {
      margin-right: $defaultMargin;
    }
    > span.active {
      color: $primaryBlue;
      font-weight: 500;
    }
  }

  .search {
    margin-top: $defaultMargin * 0.75;
    display: flex;
    border-top: 1px solid $gray13;
    border-bottom: 1px solid $gray13;
    padding: $defaultPadding * 0.75;
    .Search {
      flex: 1;
      border: 1px solid #dfe1e4;
      border-radius: 4px;
      padding-top: 6px;
      padding-bottom: 6px;
      > img {
        margin-left: 6px;
      }
    }
    > img {
      margin-left: $marginHalf;
      border: 1px solid #dfe1e4;
      padding: 0 5px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .empty {
    padding-bottom: 10px;
    .manageNotData-scope {
      position: relative;
      transform: none;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .icon-middle {
      position: relative;
      top: 0;
      margin-top: -50px;
    }
    > div {
      text-align: center;
      font-size: 1.2rem;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      padding: $defaultPadding;
      border-bottom: 1px solid $gray13;
      font-size: 1.2rem;
      word-break: break-all;
      &:last-child {
        border-bottom: 0;
      }
      a {
        color: $primaryBlue;
      }
    }
  }
}
