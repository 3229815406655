@import 'styles/library.scss';

.AddBulk {
  position: absolute;
  top: 0;
  left: calc(100% + 5px);
  width: 400px;
  background: #fbfbfc;
  border: 1px solid #ecedff;
  box-shadow: 0 0 2px #ecedff;
  border-radius: 4px;
  padding: $defaultPadding;
  z-index: 1;
  transform: scale(0.7);
  transition: all 0.2s;
  transform-origin: 0 0;
  opacity: 0;

  &.visible {
    transform: scale(1);
    opacity: 1;
  }

  h6 {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 500;
  }

  p {
    margin: 10px 0;
    font-size: 1.3rem;
    color: #8c909a;
  }

  textarea {
    height: 100px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  }
}
