@import '../../../styles/library.scss';

.popUp-scope {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .bg-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .popup-inner-wrapper {
    transform: translateY(50px) scale(0.8);
    opacity: 0;
    transition: transform 0.3s;
  }
  &.animate {
    .bg-layer {
      opacity: 0.7;
    }
    .popup-inner-wrapper {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }
}
