@import 'styles/library.scss';

.MuiTooltip-popper:has(> .MuiTooltip-tooltip) {
  z-index: 9999999 !important;
}

// .action-item-popside-row-selected {
//   .MuiDataGrid-cell {
//     background-color: darken(#f9faff, 2%) !important;
//   }
// }
.groups-popside + .popUp-scope {
  z-index: 9999999 !important;
}
.groups-popside {
  .PopSide {
    .PopSide-inner {
      width: 905px;
      padding: 0 !important;

      .close-trigger {
        top: 21px !important;
      }
      .GroupsdPopside {
        display: flex;
        flex-direction: column;
        // overflow-x: hidden;

        .scrollbar-common {
          scrollbar-color: white #fff !important;
        }

        .popside-content {
        }
      }
    }
  }
}
