@import '../../../../styles/library.scss';

.collapse-panel-assessments {
  & .top-panel-assessments-collapse-wrapper {
    // align-items: center;
    padding: 18px;
  }
  .top-panel-wrapper {
    padding-top: 10px;
  }
  & .risk-wrapper {
    padding-top: 40px;
    padding-left: 10px;
    min-width: 180px;
    & .data-panel-na {
      & .cyberpion-ui-score-indicator {
        background-color: #555659;

        border-color: #ffffff;
      }
    }
  }

  & .org-risk-score {
    padding-left: 35px;
  }

  & .org-risk-score-legend {
    padding-left: 95px;
    padding-right: 70px;
    svg {
      transform: scale(1.4);
    }
  }

  & .top-panel-login-title {
    padding-right: 25px;
  }
  & .risk-indicator-ranks > div {
    span {
      left: 0;
      top: 0;
    }
    & .letter-0,
    .letter-1 {
      color: $white;
    }
  }
  & .top-panel-indicator {
    padding-right: 10px;
  }
}
