@import '../../../styles/library.scss';

.links-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: $defaultPadding;
  min-height: 24px;
}

.tabs {
  display: flex;
  color: $gray2;
  margin: 0 0 1px $defaultMargin;
}

.tab-title {
  font-weight: $fontNormal;
  font-size: 1.2rem;
  margin-right: $marginHalf * 0.5;
  .label {
    position: absolute;
    top: -12px;
    margin-left: -20px;
    background: $primaryBlue;
    color: $white;
    font-size: 0.8rem;
    padding: 1px 4px;
    border-radius: 4px;
    // line-height: 8px;
  }
}

.tab-title-count {
  display: flex;
  align-items: center;
  img {
    margin-left: 6px;
    background: $green;
    padding: 2px;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
  }
}

.tab-count {
  font-weight: $fontExtraBold;
  font-size: 1.2rem;
}

.tab-scope {
  button.locked {
    pointer-events: none;
  }
  margin: 0 !important;
  &:not(.open_action_items):not(.closed_action_items):not(.cnapp_alerts_validation) {
    @media only screen and (max-width: 1375px) {
      & .tab-title-count {
        display: none;
      }
    }
  }
}

.tab {
  display: flex;
  align-items: center;
  padding: 0 $defaultPadding - 2;
  font-size: 1.2rem;
  &.locked {
    pointer-events: none;
    cursor: default;
  }
  svg {
    margin-right: 2px;
  }
}

.tab-color {
  color: #454549;
}

.uselected {
  color: #2c2c30;
  font-weight: 400;
}

.selected {
  color: #3455dd;
  font-weight: 400;
}

.border-selected {
  height: 0.2rem;
  border-radius: 0.63rem 0.63rem 0 0;
  background: #3659de;
  position: relative;
  top: 2px;
}

.border-uselected {
  height: 0.2vw;
}
