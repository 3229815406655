@import '../styles/library.scss';

.header-container {
  padding: ($defaultPadding * 1.5) 0 $defaultPadding 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  background: $backgroundBlue;
  z-index: 1;
}

.logo-header {
  display: flex;
  align-items: center;
}

.header-text {
  color: $gray6;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: capitalize;
}

.header-action {
  display: flex;
}
