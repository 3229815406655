@import 'styles/library.scss';

.AddTokenPopup {
  position: fixed;
  background: $white;
  width: $popUpWidth;
  border-radius: $borderRadiusSmall;

  .popup-inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 150%;

    h6 {
      text-align: left;
      width: 100%;
      padding-left: $defaultPadding;
      font-size: 1.4rem;
      margin: $marginHalf 0;
      box-sizing: border-box;
    }

    p {
      margin: 0;
      width: 100%;
      text-align: left;
      font-size: 1.2rem;
      padding-left: $defaultPadding;
      box-sizing: border-box;
    }

    .token-details {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0 $defaultPadding;
      box-sizing: border-box;
      flex-direction: column;
      margin: $defaultMargin * 2 0 $defaultMargin 0;
      input {
        margin-right: 8px;
        padding: 6px;
        font-size: 1.1rem;
        border: 1px solid #eaeaea;
        border-radius: 2px;
        box-sizing: border-box;
        width: 100%;
        outline: 0;
        letter-spacing: 3px;
        &[type='text'] {
          letter-spacing: 2px;
        }
      }
      > div {
        text-align: left;
        font-size: 1.2rem;
        .seperator {
          display: inline-block;
          margin: 0 $marginHalf;
        }
        &:first-child {
          margin-bottom: $marginHalf;
        }
        &:last-child {
          display: flex;
          img {
            margin-right: $marginHalf;
            cursor: pointer;
          }
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: $defaultPadding;
      width: 100%;
      box-sizing: border-box;
    }
  }
}
