@import 'styles/library.scss';

.SavedViews {
  height: 100%;
  max-width: 0;
  background: $white;
  position: relative;
  transition: flex-basis 0.5s;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: max-width 0.5s;
  overflow: hidden;
  // box-shadow: -10px 0px 10px $gray5 inset;
  // &:after {
  //   content: '';
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 15px;
  //   background-image: linear-gradient(to right, $white, $gray5);
  //   opacity: 0.05;
  // }

  // .scrollbar-common {
  //   scrollbar-color: white #ccc;
  // }

  &.open {
    max-width: 300px;
    border-right: 1px solid rgba(224, 224, 224, 1);
    .header {
      span {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  // .scrollbar-common {
  //   flex: 1 0;
  // }
  .layer {
    height: 100%;
    position: relative;
    width: 300px;
    .empty {
      font-size: 1.1rem;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .NoIssuesIcon {
        padding: 0 50px;
        text-align: center;
        .icon-middle-card {
          top: 0;
          svg {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
  ul.views-list {
    list-style-type: none;
    padding: 0;
    position: absolute;
    height: 100%;
    margin-left: 20px;
    margin-right: 0;
    flex: 1 0;
    // overflow: scroll;
    margin-top: 0;
    &.long-list {
      > li:last-child,
      > li:nth-last-child(2),
      > li:nth-last-child(3) {
        .SavedViewOptionsMenu {
          transform: translate(-195px, -130px);
        }
      }
    }
    > li {
      margin-top: 10px;
      border: 1px solid $gray1;
      padding: 10px;
      margin-right: 12px;
      border-radius: 3px;
      color: $gray6;
      font-size: 1.3rem;
      line-height: 140%;
      width: 238px;
      // display: flex;
      position: relative;
      // flex-direction: row;
      cursor: pointer;
      color: $gray5;
      overflow: visible;
      transition: border-color 0.3s;
      background: $white;
      .view-content-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;
        > div {
          display: flex;
          flex-direction: row;
          word-break: break-word;
          > div:first-child {
            display: flex;
            align-items: center;
          }
        }
        .menu {
          position: relative;
        }
      }
      &:hover {
        border-color: $primaryBlue;
      }
      &:first-child {
        margin-top: 0;
      }
      .reset {
        color: $primaryBlue;
        cursor: pointer;
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 5px;
        display: inline-block;
      }

      &.selected {
        background: $lighterBlue;
        color: $gray5;
        border-color: $lightBlue2;
        .icon {
          // background: $primaryBlue;
        }
      }

      .buttons {
        margin-left: 20px;
      }
      .generic-icon {
        width: 8px;
        height: 8px;
        padding: 4px;
        margin-right: 5px;
        border-radius: 2px;
        vertical-align: middle;
      }
      .user-icon {
        width: 16px;
        height: 16px;
        padding: 0;
        margin-right: 5px;
        vertical-align: middle;
      }
      .default-icon {
        width: 10px;
        height: 10px;
        padding: 0px;
        margin-right: 2px;
        vertical-align: middle;
      }

      .edited-badge {
        background: $paleBlue;
        color: $black;
        padding: 0px 7px;
        border-radius: 2px;
        font-size: 1.1rem;
        display: inline-block;
        line-height: 150%;
      }
    }
  }

  .header {
    display: flex;
    padding: 20px;
    flex: 0;
    justify-content: space-between;

    img {
      position: absolute;
      top: 20px;
      right: 12px;
      transition: transform 0.3s;
      cursor: pointer;
    }

    span {
      // letter-spacing: 1px;
      color: $gray5;
      font-size: 1.2rem;
      display: block;
      white-space: nowrap;
      opacity: 0;
      &:last-child {
        display: none;
        &.active {
          display: block;
          cursor: pointer;
        }
      }
    }
  }
}
