.chips-list {
  margin-bottom: 20px;

  .chips-list-container {
    display: flex;
    flex-direction: column;
    // align-items: center;

    .input-field {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;

      .MuiTextField-root {
        &.has-value {
          .MuiInputBase-root {
            background-color: #fff !important;
          }
        }
        &:not(.has-value) {
          .MuiInputBase-root {
            background-color: #f9faff !important;
          }
        }
        .MuiInputBase-root.Mui-focused {
          background-color: #fff !important;
        }
      }

      .icon-clipboard,
      .icon-help-only {
        position: absolute;
        top: 27px;
        right: 60px;
        width: 20px;
        height: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 4px;
        svg {
          transform: scale(1.2);
        }
        &:hover {
          cursor: pointer;
          background-color: #ecedff;
        }
      }
      .icon-help-both {
        position: absolute;
        top: 10px;
        right: 84px;
        width: 20px;
        height: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        svg {
          transform: scale(1.2);
        }
        &:hover {
          cursor: pointer;
          background-color: #ecedff;
        }
      }
      .add-item {
        position: absolute;
        top: 23px;
        right: 8px;

        .MuiButtonBase-root {
          padding: 4px !important;
          min-width: 45px !important;
        }
      }

      .input-list {
        display: flex;
        flex-direction: column;
        padding-top: 12px;
        flex-wrap: wrap;

        .chip-item {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          background-color: #ecedfe;
          border-radius: 4px;
          margin-right: 10px;
          margin-bottom: 10px;
          color: #6d6d7a;
          width: fit-content;

          .chip-item-close-trigger {
            margin-left: 4px;
            width: 18px;
            height: 18px;
            cursor: pointer;

            svg {
              transform: scale(0.5);
            }
            transition: transform 0.3s;
            &:hover {
              transform: rotate(90deg);
              transform-origin: center center;
            }
          }
        }
      }
    }
  }

  .chips-list-readonly {
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: fit-content;
    flex-wrap: wrap;

    .chip-item-row {
      display: flex;
      margin-bottom: 10px;

      .chip-item {
        display: flex;
        align-items: center;
        padding: 6px 8px;
        background-color: #f0f2f6;
        border-radius: 4px;
        margin-right: 8px;
        margin-bottom: 0px;
        color: #6d6d7a;

        &:hover {
          cursor: pointer;
        }
        // &:last-child {
        //   margin-bottom: 0;
        // }
      }

      .clipboard {
        position: relative;
        top: 2px;
        width: 16px;
        height: 16px;
        border: 1px solid #e8e8e8;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        transition: background-color 0.3s;
        margin-left: 4px;
        padding: 2px;

        &:hover {
          cursor: pointer;
          background-color: #ecedff;
          border-color: #f7f7f7;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
