@import 'styles/library.scss';

.AddAccount {
  padding: 10px 20px 0 0;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  .add-account-wrapper {
    position: absolute;
    width: 505px;
    transition: transform 0.2s ease-out;
    &:not(.visible) {
      transform: translate(-150%);
    }
  }
  .scrollbar-common {
    scrollbar-color: transparent transparent !important;
  }
  .scrollbar-common ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  .main-scrollable-area {
    overflow-y: auto;
    height: calc(100vh - 143px);
    position: relative;
  }
  h2 {
    margin: 0;
    padding: 0;
    color: $gray7;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    img {
      margin-right: $marginHalf;
    }
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 500;
    color: $gray7;
  }
  .inputs-group {
    &:first-child {
      .headline {
        margin-top: $defaultMargin;
      }
    }
    .headline {
      display: flex;
      align-items: center;
      margin: $defaultMargin * 1.5 0;
      h5 {
        font-size: 1.2rem;
        white-space: pre;
        margin: 0 20px 0 0;
        font-weight: 500;
        color: $black;
      }
      &:after {
        content: '';
        display: flex;
        width: 100%;
        height: 1px;
        background: #f5f6ff;
        flex-basis: 100%;
        flex: 1;
      }
    }
    .info {
      display: flex;
      margin-bottom: 20px;
    }
  }

  .buttons-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    background-color: $white;
    &.loading {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.97);
        z-index: 0;
      }
      .Button {
        box-shadow: 0 0 0 !important;
      }
    }
    .Button {
      margin-left: 10px;
      width: 100px;
      &:last-child {
        width: 200px;
        flex-basis: 200px;
      }
    }
  }
}
