@import '../../../../../../styles/library.scss';

.info-panel-count {
  color: #6f6f7d;
  border-radius: 4px;
  padding: 2px 5px;
  background-color: #ecedff;
  &:hover {
    color: #ffffff;
    background-color: #5886fb;
    cursor: pointer;
  }

  &.selected {
    background-color: #5d6576;
    color: #ffffff;
  }
}
