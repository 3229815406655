@import 'styles/library.scss';

.IntegrationHeader {
  margin-bottom: 14px;
  h6 {
    font-weight: 400;
    font-size: 1.2rem;
    margin: 0;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: 7px;
    }
  }
  > div {
    display: flex;
    align-items: center;
    margin-top: 16px;
    h5 {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 500;
    }
    img {
      margin-right: $marginHalf;
    }
  }
}
