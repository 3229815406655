@import '../../../../../../styles/library.scss';

.panel-body-container {
  margin: 0;
  font-size: 1.1rem;
  font-family: Rubik;
  // margin-left: 25px;

  & .panel-body-title {
    font-weight: 400;
    color: #454549;
    background-color: #f9faff;
    padding: 10px 25px;
  }
  & .panel-body-field {
    color: #8c909a;
    padding-left: 25px;
    padding-bottom: 3px;
  }
  & .panel-body-field-text {
    color: #454549;
    padding-top: 5px;
    font-weight: 200;
    padding: 0 25px;
    word-break: break-word;
    max-width: 260px;
  }
}
