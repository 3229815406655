@import 'styles/library.scss';

.colored-number-scope {
  &.pos {
    color: $green;
  }
  &.neg {
    color: $red;
  }
}
