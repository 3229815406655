@import 'styles/library.scss';

.OrgScore {
  height: 100%;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  .loading-wrapper {
    position: relative;
    flex: 1 0;
  }
  .manageNotData-scope {
    transform: none;
    margin: auto;
  }
  .org-score-popup {
    background: $white;
    height: 400px;
    width: $popUpWidth;
    position: relative;
  }
  header {
    background: red;
    min-height: 100px;
  }
  .scrollbar-common {
    overflow: hidden;
  }
  .org-score-wrapper {
    display: flex;
    // flex-direction: column;
    padding: 10px 20px 10px 0;
    background-color: #fff;
  }
  ul.cards {
    list-style-type: none;
    margin: 0;
    padding: 0 $defaultPadding $defaultPadding * 2 $defaultPadding;
    overflow: auto;
    background: $white;
    height: 100%;
    box-sizing: border-box;
  }
}
