@import 'styles/library.scss';

.UserRolesDropdown-wrapper {
  h6 {
    font-size: 1rem;
    margin: 0 0 $marginSmall 0;
    font-weight: 500;
  }
}
.UserRolesDropdown {
  position: relative;
  width: 80px;

  &.disabled {
    display: none;
    header {
      pointer-events: none;
      // border: 0;
      // background: transparent;
      svg {
        display: none;
      }
    }
  }
  header {
    display: flex;
    align-items: center;
    border: 1px solid $gray1;
    padding: 2px $paddingSmall;
    background: $white;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    svg {
      transition: transform 0.3s;
    }
    &.visible {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  ul {
    list-style-type: none;
    position: fixed;
    // padding: 0 $paddingSmall;
    margin: 0;
    padding: 0;
    width: 80px;
    background: $white;
    border-radius: 0 0 $defaultBorderRadius $defaultBorderRadius;
    box-shadow: 0 0 0;
    z-index: 1;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;
    &.visible {
      max-height: 60px;
      box-shadow: 0 0 1px 1px $gray9 inset;
    }
    li {
      // margin: $marginHalf 0;
      padding: $paddingHalf $paddingSmall;
      font-size: 1rem;
      cursor: pointer;
    }
  }
}
