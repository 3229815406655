@import 'styles/library.scss';

.Checkbox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  border: 1px solid $lightBlue;
  margin-right: $marginSmall;
  border-radius: 2px;
  color: $primaryBlue;
}
