@import 'styles/library.scss';

.EditSavedView {
  width: 180px;
  border: 0;
  border-radius: 3px;
  padding: 3px;
  box-shadow: 0 0 4px #aaa;
  outline: 0;
}
