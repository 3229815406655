@import '../../../../styles/library.scss';

.summary-from-scope {
  position: relative;
  flex: 1 0;
  margin-right: $defaultMargin * 2;
  padding-right: $defaultPadding * 2;
  border-right: 1px solid $gray7;
  transition: all 0.1s;
  &:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }
}

.summary-from-scope1 {
  width: 20%;
  position: relative;
  display: flex;
  align-items: center;
  border-right: 1px solid $gray7;
  margin-right: $defaultMargin * 2;
  padding-right: $defaultPadding * 2;
}

.summary-from-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-top: $defaultMargin * 1.25;
  opacity: 1;
  transition: opacity 0.5s;
  &.graph-container {
    justify-content: center;
  }
}

.summary-from-body1 {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  color: #fff;
}
