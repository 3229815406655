@import 'styles/library.scss';

.ViewMSSP {
  ul.chips-list {
    list-style-type: none;
    margin: 0 0 $defaultMargin 0;
    padding: 0;
    display: flex;

    > li {
      background: $gray13;
      padding: 4px 10px;
      border-radius: 3px;
      margin-right: $defaultMargin;
      font-size: 1.3rem;
      color: $gray5;
    }
  }
  ul.info-list {
    list-style-type: none;
    margin: 0;
    padding: $defaultPadding;
    padding-left: 0;
    display: flex;
    border-top: 1px solid $gray1;
    border-bottom: 1px solid $gray1;
    li {
      margin-right: $defaultMargin * 1.5;
      > h5 {
        font-size: 1.2rem;
        margin: 0;
        color: #60646a;
        font-weight: 400;
        flex: 0 1;
        margin-bottom: $marginHalf;
      }
      > div {
        display: flex;
        align-items: center;
        flex: 1 0;
        font-size: 2.8rem;
        font-weight: 300;
        .risk-indicator-ranks {
          margin: 0 $marginHalf 0 $marginSmall;
        }
      }
    }
  }
}
