.iCollapse_arrows {
  transform-origin: center center;
  transform-box: fill-box;
  cursor: pointer;
}
.iCollapse_rotate {
  transform: translate3d(-5px, -11px, 10px) rotate(180deg);
}

.iCollapse_urotate {
  transform: translate3d(-5px, -11px, 10px) rotate(0deg);
}
