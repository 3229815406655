@import 'styles/library.scss';

.misconfigured-login-details {
  margin: 32px 24px;
  .risk-icon {
    svg {
      transform: scale(1.4);
    }
  }
  .technical-details-container {
    border: 1px solid $backgroundBlue;
    overflow-wrap: anywhere;
    // width: 680px;

    & .technical-details-header-scope {
      margin: 8px !important;
    }
    & .technical-details {
      font-size: 11px;
    }
  }
}
