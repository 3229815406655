.RunScanButton {
  margin-left: 10px;
  display: flex;
  position: relative;
  .Button:first-child {
    width: 130px;
    border-radius: 4px 0 0 4px;
  }
  .Button:last-child {
    width: 30px;
    border-radius: 0px 4px 4px 0px;
    padding-right: 0;
  }
  .scan-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background: #ffffff;
    position: absolute;
    bottom: 50px;
    right: 0px;
    padding: 16px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 190px;
    transform: scale(0.5);
    transition: all 0.1s;
    opacity: 0;
    pointer-events: none;
    &.visible {
      transform: scale(1);
      opacity: 1;
      pointer-events: all;
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 1.2rem;

      opacity: 0.6;
      pointer-events: none;
      filter: grayscale(1);

      &:first-child {
        font-weight: 500;
        margin-bottom: 12px;
        font-size: 1.3rem;
      }
      cursor: pointer;
      span {
        margin-right: 8px;
        pointer-events: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
