.PromptPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  &.visible {
    z-index: 99999999;
    opacity: 1;
    .prompt-content {
      transform: scale(1);
    }
    .bg-layer {
      opacity: 1;
    }
  }
  .bg-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    opacity: 0;
    transition: opacity 0.3s;
  }
  .prompt-content {
    width: 380px;
    height: auto;
    transform: scale(0.5);
    transition: all 0.3s;
    background: #fff;
    padding: 25px;
    border-radius: 8px;
    min-height: 60px;
    h3 {
      margin: 0;
      padding: 0;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 1.8rem;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
    }
    .buttons-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 25px;
      .Button {
        margin-left: 10px;
        width: auto;
        padding-right: 35px;
        padding-left: 35px;
      }
      display: flex;
    }
  }
}
