@import 'styles/library.scss';

.ForgotPasswordFlow {
  position: relative;
  width: 100%;
  height: 100%;
  background: $white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    width: 100%;
  }
  .Button {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
