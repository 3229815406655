@import '../../../../styles/library.scss';

.mapLegend-circle {
  height: 0.3vw;
  width: 0.3vw;
  border-radius: 1vw;
  margin: 0 0.2vw;
  border: solid 2px;
  cursor: pointer;
}

.mapLegend-scope {
  display: flex;
  justify-content: space-evenly;
  margin-top: $marginHalf;
}

.mapLegend-label-scope {
  display: flex;
  align-items: center;
  color: #8c909a;
  font-size: 1rem;
}
