@import '../../../../../../styles/library.scss';

.formCategories-scope {
  display: flex;
  color: $white;
  justify-content: space-between;
}

.formCategories-label-scope {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: $defaultMargin * 0.75;
  flex: 1 0;
  &:last-child {
    margin-right: 0;
  }
}

.formCategories-header {
  font-size: 1rem;
  margin-bottom: $marginHalf;
}

.formCategories-value {
  font-family: Rajdhani;
  font-size: 1.8rem;
}
