@import 'styles/library.scss';

.UserSettings {
  position: fixed;
  background: $white;
  width: $popUpWidth;
  padding: $defaultPadding;
  border-radius: 10px;
  .user-scope {
    pointer-events: none;
  }

  .inputs-wrapper {
    label {
      display: block;
      color: $gray4;
      font-size: 1.2rem;
      margin-bottom: $marginSmall;
    }
  }
  .phone-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    .PhoneInputCountry {
      position: absolute;
      left: 9px;
      top: 11px;
    }
    input {
      text-indent: 30px;
      width: 350px;
    }
    &.error {
      border: 0 !important;
      input {
        border: 1px solid $red;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background: $gray1;
    margin-bottom: $defaultMargin;
  }
  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      button {
        margin-left: $marginHalf;
        width: 100px;
      }
      a {
        font-size: 1.2rem;
        color: $primaryBlue;
      }
      .bullet {
        font-size: 1.6rem;
        display: inline-block;
        margin: 0 $marginSmall;
        color: $gray4;
      }
    }
  }
  input {
    width: 350px;
    box-sizing: border-box;
    margin-bottom: $defaultMargin;
    padding: $paddingHalf;
    border: 1px solid $gray3;
    border-radius: $defaultBorderRadius;
    font-size: 1.2rem;
    outline: 0;
    &:read-only {
      background: $gray1;
    }
    &.error {
      border: 1px solid $red !important;
      text-align: left !important;
      font-size: 1.2rem !important;
      color: $gray5 !important;
    }
  }
}
.popup-header-actions-content {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  width: 100%;
  align-items: center;
}
