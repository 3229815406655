@import '../../../../../styles/library.scss';

.sub-option-wrapper {
  padding: 0 0 0 $paddingHalf;
  .hide {
    // override .hide and display the all the group items
    // in case the parent item has 'show-group' className
    .show-group & {
      display: block !important;
    }
  }
}
