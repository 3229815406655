@import 'styles/library.scss';

.LoginForm {
  position: relative;
  width: 100%;
  height: 100%;
  background: $white;
  // opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .Button {
    width: 100%;
    margin-bottom: $defaultMargin;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .two-buttons {
    display: flex;
    .Button {
      width: 120px;
    }
  }
  .small-padding-right {
    padding-right: 6px;
  }
  .small-padding-left {
    padding-left: 6px;
  }
  .small-padding-bottom {
    padding-bottom: 10px;
  }
  .general-error {
    color: $red;
    font-size: 1.3rem;
  }
  .animation & {
    transition: opacity 0.5s;
    &.visible {
      // opacity: 1;
    }
  }
}
