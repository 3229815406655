@import 'styles/library.scss';

.AddUserPopUp {
  position: fixed;
  background: $white;
  width: $popUpWidth;
  // min-height: 300px;

  .popup-inner {
    padding: $paddingHalf;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .input-wrapper {
      position: relative;
      margin-bottom: $defaultMargin;
      &.error {
        border: 0 !important;
        input {
          border: 1px solid $red;
        }
      }
      .server-error {
        position: absolute;
        height: 100%;
        width: 50%;
        right: 8px;
        top: 0px;
        color: $red;
        margin-bottom: $marginSmall;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        white-space: normal;
        text-align: right;
      }
    }
    .PhoneInputCountry {
      position: absolute;
      left: 9px;
      top: 11px;
    }
    .PhoneInputInput {
      text-indent: 30px;
    }
    input {
      width: 100%;
      box-sizing: border-box;
      padding: $paddingHalf;
      border: 1px solid $gray3;
      border-radius: $borderRadiusSmall;
      font-size: 1.2rem;
      outline: 0;
      &.error {
        border: 1px solid $red;
        text-align: left;
        font-size: 1.2rem !important;
        color: $gray5;
      }
    }
    // .error {
    //     color: $red;
    //     text-align: center;
    //     font-size: 1.4rem;
    //     border: 0 !important;
    // }
    .select-wrapper {
      border: 1px solid $gray3;
      padding: $paddingHalf;
      border-radius: 2px;
      margin-bottom: $defaultMargin;
      position: relative;
      z-index: 1;
      margin-right: 0;

      &.error {
        border: 1px solid $red;
        font-size: 1.2rem !important;
        text-align: left !important;
        color: $gray7 !important;
      }

      .header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;
        svg {
          float: right;
        }
      }
      ul {
        position: absolute;
        list-style-type: none;
        padding: $paddingHalf;
        background: $white;
        box-sizing: border-box;
        border: 1px solid $gray3;
        // border-radius: $defaultBorderRadius;
        width: 100%;
        margin: 0;
        left: 0;
        top: 30px;
        display: none;
        &.open {
          display: block;
        }
        li {
          margin: $marginHalf 0;
          padding-bottom: $paddingHalf;
          border-bottom: 1px solid $gray1;
          color: $gray6;
          user-select: none;
          cursor: pointer;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    .DynamicMultiChoiceDropDown {
      margin-bottom: $defaultMargin;
    }
    .buttons-wrapper {
      display: flex;
      justify-content: center;
      margin: 0 0 $marginHalf 0;
      button {
        margin-left: $marginHalf;
      }
    }
  }
}
