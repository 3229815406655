@import '../../../../styles/library.scss';

.multiLineChart-icon {
  height: 0.5vw;
  width: 0.5vw;
  border-radius: 1px;
  margin: 0 0.2vw;
  border: solid 1px;
}

.multiLineChart-legend-scope {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5vw;
  font-size: 1vw;
  cursor: pointer;
}

.multiLineChart-scope {
  display: flex;
  justify-content: center;
  margin-top: $defaultMargin;
}

.multiLineChart-label {
  color: #5d6576;
  font-size: 1rem;
}
