@import '../../../styles/library.scss';

.menu-export-csv-wrapper {
  position: relative;
  font-size: 1.2rem;
  color: $gray5;
  margin-left: 8px;

  .menu-export-csv-icon {
    border: 1px solid #dadde0;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &.button {
      padding: 6px 8px;
    }
    &:hover {
      cursor: pointer;
      background: #f6f8ff85;
    }
  }
}

.menu-export-csv-header-wrapper {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.menu-export-csv-body-wrapper {
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  position: absolute;
  background: #ffffff;
  z-index: 1;
  right: 32px;
  top: -5px;
  padding: 4px;
}

.menu-export-csv-header {
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  position: absolute;
  background: #ffffff;
  z-index: 1;
  right: 5px;
  top: 35px;
  padding: 4px;
}

.menu-export-csv-item-scope {
  display: flex;
  align-items: center;
  // margin-bottom: $marginHalf;
  padding: 8px;
  white-space: nowrap;
  border-radius: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  &:hover {
    cursor: pointer;
    background: #f6f8ff;
  }
}

.menu-export-csv-label {
  // margin: 0 0 0 5px;
}
