@import 'styles/library.scss';

.SubscribeDropdown {
  // position: relative;

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .dropdown {
    display: flex;
    align-items: center;
    padding: 1px 6px;
    font-size: 12px;
    color: #6d6d7a;
    background-color: #fff;
    border: 1px solid #dadde0;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    padding: 4px 8px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: #e8e8e8;
      cursor: pointer;
    }

    .chevron-icon {
      margin-left: 4px;
      position: relative;
      top: 2px;
      svg {
        &.rotate {
          transform: scale(1.2) rotate(180deg);
        }

        &.urotate {
          transform: scale(1.2) rotate(0deg);
        }
      }
    }
    // .icon {
    //   margin-left: 4px;
    //   position: relative;
    //   top: 2px;
    //   svg {
    //     transform: scale(1.2);
    //   }
    // }
  }

  .option-item {
    display: flex;
    align-items: center;

    &.selected {
      background-color: #f6f8ff;
      .filter-item {
        color: #000;
      }
    }
    &.selected:hover {
      background-color: #ecedff;
      // background-color: #f1f3f460;
      color: #8c909a !important;
    }
  }
}
