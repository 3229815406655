@import '../../../styles/library.scss';

.grid {
  display: flex;
  height: 100%;
  width: 100%;
  .saved-views {
    flex-basis: 25px;
  }
}

.grid-wrapper {
  background-color: $white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .utilities-row {
    background: #f5f6ff;
    padding: $paddingHalf $defaultPadding;
    // display: flex;
    //justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  & .close-trigger {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}

.grid-loading {
  margin-top: $defaultMargin;
  font-size: 1.2rem;
}

.grid-scope {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}

.MuiDataGrid-root .MuiDataGrid-row {
  font-size: 1.3rem;
  color: $gray5;

  &:last-child {
    &:not(:first-child) {
      .UserRolesDropdown {
        ul {
          bottom: 40px;
          top: auto;
        }
      }
    }
  }
}

.MuiDataGrid-columnHeaderTitle {
  user-select: none;
  font-weight: 400 !important;
  line-height: 16px !important;
  white-space: normal !important;
}

.MuiTooltip-popper * {
  font-size: 1.1rem !important;
}

// .MuiDataGrid-row {
// cursor: pointer !important;
// [data-field=fqdn], [data-field=hosting_provider],
// [data-field=ip], [data-field=whois_emails],
// [data-field=registrar], [data-field=registrant_contact_name],
// [data-field=registrant_organization], [data-field=registrant_organization],
// [data-field=whois_name_servers], [data-field=whois_status],
// [data-field=ip_network], [data-field=ip_network_name],
// [data-field=ip_network_org_id], [data-field=ip_network_org_name],
// [data-field=ip_network_country], [data-field=ip_network_source],
// [data-field=technologies], [data-field=open_ports], [data-field=technologies_names],
// [data-field=type], [data-field=host], [data-field=title],
// [data-field=name], [data-field=category], [data-field=info],
// [data-field=to_target], [data-field=connected_asset_type],
// [data-field=remarks]  {
//     user-select: text;
//     cursor: text !important;
// }
// }

.MuiDataGrid-root {
  border: none !important;
  font-family: Rubik !important;
  height: calc(100% - 34px) !important;

  .MuiDataGrid-viewport {
    overflow: visible !important;
  }
}

.MuiDataGrid-row.Mui-selected {
  background-color: #ffffff00 !important;
}

.MuiSvgIcon-root {
  width: 1rem !important;
  height: 1rem !important;
}

.MuiDataGrid-root .MuiDataGrid-main {
  margin: 0 0.94rem 0.1rem 0;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  color: $gray4;
  font-size: 1.1rem;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding-left: 0 !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader {
  padding-right: 0 !important;
}

.MuiDataGrid-windowContainer ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: solid transparent 1px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: solid transparent 1px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  line-height: normal !important;
  display: flex !important;
  align-items: center;
  a {
    color: $gray5;
    &:hover {
      color: $primaryBlue;
    }
  }
}

.MuiDataGrid-columnSeparator {
  position: static !important;
}

.MuiDataGrid-pinnedColumnHeaders {
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background: #ffffff;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background: #999999;
}
