@import 'styles/library.scss';

.ConfirmationPopup {
  position: fixed;
  background: $white;
  width: $popUpWidth;
  border-radius: $borderRadiusSmall;

  .popup-inner {
    padding: $defaultPadding * 1.5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 150%;

    .popup-main {
      font-size: 1.4rem;
      text-align: left;
      width: 100%;
      line-height: 140%;
      margin-left: 50px;
      position: relative;
      font-weight: 300;
      span {
        font-weight: 700;
      }
      &:before {
        content: '!';
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid $gray5;
        position: absolute;
        left: -30px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray5;
        font-size: 1.2rem;
        font-weight: 700;
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      margin: $defaultMargin * 2 0 0 0;
      width: 100%;
      button {
        margin-left: $marginHalf;
      }
    }
  }
}
