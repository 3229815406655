@import 'styles/library.scss';

.CVESidePanel {
  h6 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 10px 0;
    img {
      margin-right: 4px;
    }
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0 0 10px 0;
  }

  .main-data-area {
    overflow-y: auto;
    height: calc(100vh - 160px);
    .data-wrapper {
      display: flex;
      margin-bottom: 20px;
      section.left {
        flex: 4;
        margin-right: 20px;
        .box {
          min-height: 100px;
          margin-top: 20px;
          background: $lighterBlue;
          border-radius: 6px;
          padding: $defaultPadding;
          box-sizing: border-box;
          h5 {
            color: $gray4;
            font-weight: 300;
            margin: 16px 0 4px 0;
            font-size: 1.2rem;
          }
          > a {
            color: $primaryBlue;
            font-size: 1.2rem;
          }
          p {
            color: $gray7;
            margin: 0;
            font-size: 1.2rem;
            a {
              color: $primaryBlue;
              display: block;
            }
          }
          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
              font-size: 1.2rem;
              color: $gray7;
            }
          }
          .read-more {
            color: $primaryBlue;
            font-weight: 400;
            cursor: pointer;
            font-size: 1.2rem;
            margin-top: 3px;
            display: inline-block;
          }
          .wrapper {
            flex: 1 0;
            flex-wrap: wrap;
            display: flex;
            > div {
              flex-basis: 33%;
              margin-top: 16px;
              h5 {
                margin-top: 0;
              }
            }
          }
          .info {
            background: $white;
            border-radius: 6px;
            height: 60px;
            display: flex;
            align-items: center;
            > div {
              flex: 1;
              padding-left: 12px;
              h5 {
                margin-top: 0;
              }
            }
            > span {
              background: $gray1;
              width: 1px;
              height: 40px;
            }
          }
        }
        .boxes-wrapper {
          display: flex;
          .box {
            min-height: 100px;
            &:first-child {
              margin-right: 20px;
              flex: 3;
            }
            &:last-child {
              flex: 4;
            }
          }
        }
      }
      section.right {
        margin-top: 20px;
        flex: 3;
        border-radius: 6px;
      }
    }
  }

  .buttons-container {
    border-top: 1px solid #eeeeee;
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    padding: 0 20px;
    box-sizing: border-box;
    box-shadow: 0 -1px 10px #efefef;
    a {
      color: $primaryBlue;
      font-size: 1.2rem;
    }
    .buttons-wrapper {
      flex: 1 0;
      justify-content: flex-end;
      display: flex;

      .Button {
        margin-left: 10px;
        width: 100px;
        &:last-child {
          min-width: 150px;
        }
      }
    }
  }
}
