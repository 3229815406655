@import '../../../../styles/library.scss';

.releaseRequest {
  width: $popUpWidth;
  border-radius: $borderRadiusBig;
  font-size: 1.2rem;
  position: absolute;
  z-index: 5;
  background: $white;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  transform: translateY(-50%);
}
.releaseRequest-popup-body {
  margin: $defaultMargin;
}

.releaseRequest-header-scope {
  background: #2c2c30;
  margin: 8px 5px;
  border-radius: 6px;
  position: relative;
  color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.releaseRequest-remove {
  transform: rotate(45deg);
  font-size: 3rem;
  font-weight: 100;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.releaseRequest-header-text {
  padding: 0 $defaultPadding;
  font-size: 2rem;
}

.releaseRequest-warning-scope {
  background: rgba(244, 102, 102, 0.1);
  border: 2px solid #f46666;
  padding: $defaultPadding * 2;
  margin: $defaultMargin 0;
  text-align: center;
  border-radius: $defaultBorderRadius * 2;
  img {
    margin-bottom: $marginHalf;
  }
}

.releaseRequest-warning-header {
  font-size: 1.4rem;
  font-weight: $fontBold;
}

.releaseRequest-textarea {
  width: -webkit-fill-available;
  height: 150px;
  margin-bottom: $defaultMargin;
  padding: $paddingHalf 0 0 $paddingHalf;
  background: #f8fafc;
  border: 1px solid #d1d2d3;
  box-sizing: border-box;
  border-radius: 8px;
  resize: none;
}

.releaseRequest-event-scope {
  text-align: right;
}

.releaseRequest-buttom {
  border-radius: 2px;
  margin-left: $marginHalf;
  padding: 4px 0;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
}

.releaseRequest-save {
  background: $primaryBlue;
  border: solid 1px $primaryBlue;
  color: #fff;
}

.releaseRequest-cancel {
  border: solid 1px $primaryBlue;
  color: $primaryBlue;
}

.releaseRequest-headline {
  font-weight: $fontThin;
}

.releaseRequest-hosts {
  font-weight: $fontBold;
  margin-top: $marginSmall;
}

.releaseRequest-warning-sub-header {
  padding: $paddingHalf ($defaultPadding * 2);
  color: $gray6;
  line-height: 140%;
}
