@import '../../../../../styles/library.scss';

.tabActionItems {
  padding: $paddingHalf 0 0 0;
  background-color: $white;
  height: 100%;
  width: 100%;
  border-radius: $defaultBorderRadius;
  box-sizing: border-box;
}

.tabActionItems-scope {
  padding: $paddingHalf;
  background-color: $white;
  height: 100%;
  border-radius: $defaultBorderRadius;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: hidden;
}

.tabActionItems-head {
  background: $gray7;
  box-shadow: 0 1px 0 rgba(236, 236, 236, 0.2);
  color: $gray3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  padding: $paddingHalf $defaultPadding;
  border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;
  > div {
    display: flex;
  }
}
    
  
.tabActionItems-headline {
  margin-right: 6px;
}

.tabActionItems-head-value {
  color: #ffff;
  display: inline-flex;
  align-items: center;
  svg {
    margin-right: 6px;
  }
}

.tabActionItems-head-circle {
  width: 4px; // actual size of the circle
  height: 4px; // actual size of the circle
  border-radius: 50%;
  margin: 0 1rem;
  background-color: $gray4;
}

.tabActionItems-item-body-scope {
  padding: $defaultPadding;
}

.tabActionItems-head-header-right-scope {
  display: flex;
  margin: 0;
  align-items: center;
}

.tabActionItems-body-title {
  font-weight: $fontBold;
  font-size: 1.4rem;
  color: $gray1;
  text-transform: capitalize;
}

.tabActionItems-body-summary {
  font-weight: $fontBold;
  font-size: 1.2rem;
  margin-top: $marginHalf;
}

.tabActionItems-foot-scope {
  display: flex;
  justify-content: flex-end;
  margin: 0 $defaultMargin;
  width: -webkit-fill-available;
  border-top: 1px solid $gray5;
  padding: $defaultPadding 0;
}

.tabActionItems-items-scope {
  background: $gray8;
  margin-bottom: $marginHalf;
  color: $gray3;
  position: relative;
  border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;
  &:last-child {
    margin-bottom: 60px;
  }
}

.tabActionItems-border-left {
  position: absolute;
  width: 1rem;
  height: 50%;
  left: -0.5rem;
  top: 25%;
  border-radius: $defaultBorderRadius;
}

.tabActionItems-show-more {
  cursor: pointer;
  font-size: 1.2rem;
  color: $cpBlue;
  text-decoration: underline;
}
