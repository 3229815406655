@import 'styles/library.scss';

.SideAlert {
  header {
    > div:first-child {
      display: flex;
      align-items: center;
      h5 {
        padding: 0;
        margin: 0;
        font-size: 1.3rem;
        font-weight: 500;
      }
      img {
        height: 16px;
        margin-right: $marginSmall;
      }
    }
    h3 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: $marginHalf 0 0 0;
      word-break: break-all;
      padding-right: $defaultPadding * 2;
    }
    p {
      margin: $marginHalf 0 0 0;
      font-size: 1.4rem;
      font-weight: 500;
      word-break: break-all;
      padding-right: $defaultPadding * 2;
    }
  }
  .main-data-area {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    margin-top: $defaultMargin;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        border-top: 1px solid $gray13;
        padding: 12px 0;
        h6 {
          color: $gray4;
          font-weight: 300;
          margin: 0;
          font-size: 1.2rem;
        }
        p {
          color: $gray7;
          font-weight: 400;
          font-size: 1.3rem;
          margin: 0;
          margin-top: 3px;
          word-break: break-all;
          padding-right: $defaultPadding;
          box-sizing: border-box;
        }
      }
    }
  }
}
