@import '../../../styles/library.scss';

.quickViewPanel {
  position: absolute;
  width: $quickViewPanelSize;
  max-height: $quickViewPanelSize * 1.2;
  min-height: $quickViewPanelSize;
  right: 0;
  bottom: 0;
  background: #2c2c30;
  border-radius: 0.63rem 0 0 0.63rem;
  color: #ababac;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 4px $gray4;
}

.quickViewPanel-head {
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  padding: $defaultPadding;
  align-items: center;
}

.quickViewPanel-header-text {
  color: #fff;
  font-size: 1.4rem;
  margin: 0 $marginHalf;
  max-width: 300px;
  word-break: break-all;
}

.quickViewPanel-header-scope {
  display: flex;
  align-items: center;
}

.quickViewPanel-header-general-score {
  display: flex;
  margin-top: $defaultMargin * 1.25;
  .quickViewPanel-risk-score-scope,
  .riskScoreByCategory {
    margin-left: $defaultMargin;
  }
}

.quickViewPanel-b {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-size: 1rem;
  margin-bottom: $marginHalf;
}

.quickViewPanel-c {
  display: flex;
  font-size: 1rem;
}

.quickViewPanel-risk-score-level {
  font-size: 3.5rem;
}

.quickViewPanel-label {
  margin: 0 $marginHalf;
  &:last-child {
    margin: 0;
  }
}

.quickViewPanel-number {
  margin: 0 $marginHalf;
  color: $white;
}

.quickViewPanel-close-popup {
  font-size: 3rem;
  cursor: pointer;
  line-height: 1;
  margin-left: $marginHalf;
  transform: rotate(45deg);
}

.qvp-view-single-asset-scope {
  display: flex;
}

.qvp-view-single-asset-link-scope {
  background: rgba(135, 150, 240, 0.2);
  padding: $paddingHalf;
  border: 1px solid #8796f0;
  border-radius: $borderRadiusSmall;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  white-space: pre;
}

.qvp-view-single-asset-link:link {
  color: #fff !important;
}

.qvp-view-single-asset-link:visited {
  color: #fff !important;
}

.qvp-view-single-asset-link:hover {
  color: #fff !important;
}

.qvp-view-single-asset-link:active {
  color: #fff !important;
}

.quickViewPanel-d {
  width: 100%;
  display: flex;
  justify-content: center;
}

.quickViewPanel-connection-map {
  width: 100%;
  height: 200px;
}
