@import '../../../../../styles/library.scss';

.action-items-pdf-header {
  display: flex;
  flex-direction: row;
  background-color: $PDFHeaderBlue;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 80px;
  padding: 10px 50px;
  flex-grow: 1;

  & .header-logo {
    svg {
      width: 100px;
      height: 35px;
    }
  }
}
