@import 'styles/library.scss';

.MSSPAccountInfoBoxes {
  .box {
    background: $lighterBlue;
    border-radius: 6px;
    padding: $defaultPadding;
    margin: $defaultMargin 0;
    h6 {
      margin: $defaultMargin * 1.5 0 $defaultMargin 0;
      color: $black;
      font-size: 1.2rem;
      font-weight: 500;
      &:first-child {
        margin-top: 0;
      }
    }
    .list {
      margin: 0 0 $defaultMargin $marginHalf;
      font-size: 1.3rem;
      .key {
        color: $gray4;
        margin-bottom: $marginHalf;
      }
      ul.row-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          margin-bottom: $marginHalf;
          color: $gray7;
        }
      }
      ul.column-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        li {
          margin-right: $defaultMargin;
          h6 {
            color: $gray4;
            margin: 0 0 $marginHalf 0;
            font-size: 1.3rem;
            font-weight: 400;
          }
          > div {
            color: $gray7;
            word-break: break-all;
          }
        }
      }
    }
  }
}
