.ClusterGridTable {
  position: relative;
  min-height: 115px;
  .MuiDataGrid-root {
    height: 115px !important;
  }
  &.count-1 {
    .MuiDataGrid-root {
      height: 117px !important;
    }
  }
  &.count-2 {
    .MuiDataGrid-root {
      height: 169px !important;
    }
  }
  &.count-3 {
    .MuiDataGrid-root {
      height: 220px !important;
    }
  }
  &.count-4 {
    .MuiDataGrid-root {
      height: 265px !important;
    }
  }
  &.count-5 {
    .MuiDataGrid-root {
      height: 325px !important;
    }
  }
}
