.ThreatsList {
  border-left: 1px solid #e8e8e8;

  &.threats-list-month {
    .threat-item {
      padding-right: 20px;
      padding-left: 40px;
      &:last-child {
        position: relative;
        border-left: 1px solid #fff;
        left: -1px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -1px;
          width: 1px;
          height: 50%;
          border-left: 1px solid #e8e8e8;
        }
      }
    }
  }

  &.threats-list-earlier {
    position: relative;

    .threat-item {
      padding-right: 20px;
      padding-left: 40px;
      &:last-child {
        position: relative;
        border-left: 1px solid #fff;
        left: -1px;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -1px;
          width: 1px;
          height: 50%;
          border-left: 1px solid #e8e8e8;
        }
      }
    }
  }

  .threat-center-block {
    position: sticky;
    top: 0;
    left: 0px;
    height: 45px;
    z-index: 2;
    background-color: #fff;
    padding-left: 40px;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -5px;
      background-color: #eaeaea;
      width: 40px;
      height: 1px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -1px;
      width: 1px;
      height: 23px;
      border-left: 1px solid #fff;
    }
  }

  .threat-item {
    padding-right: 20px;
    padding-left: 40px;
  }
}
