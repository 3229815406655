@import 'styles/library.scss';

.MainInputs {
  .inputs-group {
    margin-top: 20px;
  }
  .inputs-wrapper {
    margin-left: $defaultMargin;
    border-left: 1px solid $paleBlue;

    .input-wrapper {
      display: flex;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        height: 15px;
        width: 2px;
        left: -1px;
        bottom: 0;
        background: white;
      }
      label {
        position: absolute;
      }
      .divider {
        flex-basis: 20px;
        height: 1px;
        margin-top: 36px;
        background: $paleBlue;
      }
      input {
        margin: 20px 0 0 0;
      }
    }
  }
}
