@import 'styles/library.scss';

.IntegrationCompletedItem {
  border: 1px solid #ecedff;
  border-radius: 4px;
  padding: 20px 12px 20px 20px;
  margin-bottom: 16px;

  &.disabled {
    background-color: #f9faff;
  }

  .header {
    display: flex;
    // align-items: center;

    .delete-icon {
      margin-left: 12px;
      svg {
        transform: scale(1.2);
      }
      &:hover {
        cursor: pointer;
      }
    }
    .icon-menu {
      margin-left: 6px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .header-clickable {
      display: flex;
      svg {
        &.rotate {
          transform: rotate(180deg) scale(1.3);
        }

        &.urotate {
          transform: rotate(0deg) scale(1.3);
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .block-content {
    padding-left: 4px;
    padding-top: 8px;

    &.inActive {
      opacity: 0.5 !important;
      pointer-events: none;
    }
    &.active {
      opacity: 1 !important;
      pointer-events: auto;
    }
  }
}
