.areaChart-scope {
  position: relative !important;
  width: 100%;
  max-width: 500px;
}

.area {
  fill: url(#area-gradient);
  strokewidth: 0px;
}

.display-y {
  color: aliceblue;
  font-size: 1.2rem;
}

.display-x {
  color: aliceblue;
  font-size: 1.4rem;
}

.udisplay-y {
  color: #f0f8ff00;
}

.udisplay-x {
  color: #f0f8ff00;
}

.y-axis {
  .tick {
    opacity: 1;
    color: #5d6576 !important;
    stroke-dasharray: 7;
  }
  path {
    stroke: none;
  }
}

.areaChart-x g > line {
  display: none;
}
