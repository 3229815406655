@import 'styles/library.scss';

.cluster-trigger {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f8;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #d2d2d2;
  position: relative;
  cursor: pointer;
  &.active {
    background: #27272a;
    box-shadow: 0 0 0 1px #27272a;
    width: 36px;
    > img {
      width: 8px;
      height: 8px;
      margin-left: 6px;
    }
  }
  ul {
    list-style-type: none;
    background: #ffffff;
    pointer-events: none;
    z-index: -1;
    max-height: 0;
    opacity: 0;
    transition: all 0.2s;
    position: absolute;
    top: 30px;
    right: 0;
    margin: 0;
    padding: 5px 0;
    box-shadow: 0 5px 8px rgba(#2c2c30, 0.2);
    border-radius: 6px;
    &.visible {
      opacity: 1;
      max-height: 500px;
      z-index: 1;
      pointer-events: all;
    }
    li {
      padding: 10px 20px;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      &.with-bottom-line {
        border-bottom: 1px solid #d2d2d2;
        margin-bottom: 5px;
        padding-bottom: 15px;
      }

      &.active {
        background-color: #27272a;
        color: $white;
      }
      &:first-child {
        font-weight: 500;
      }
      &:not(:first-child) {
        cursor: pointer;
        &:not(.active) {
          &:hover {
            background-color: $gray23;
          }
        }
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
  }
}
