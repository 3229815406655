@import '../../../../../styles/library.scss';

.rowCount-wrapper {
  display: flex;
  color: #8c909a;
  font-size: 1.2rem;
  padding: 20px;
  padding-bottom: 0;
  &.isClusterView {
    background: #f5f6ff;
    padding: 0 0 16px 18px;
  }
  // background-color: $white;
}

.row-count {
  // margin: 0 0.2rem;
  // font-weight: $fontBold;
}
