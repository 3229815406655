@import 'styles/library.scss';

.asm-wrapper {
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  position: relative;

  .asm-legend-wrapper {
    z-index: -1;
    position: absolute;
    top: 50px;
    right: 40px;
    width: 750px;
    height: auto;
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.2s;
    transform-origin: 50% 50%;
    box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05),
      0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04),
      0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
    background: #fefefe;
    border-radius: 10px;
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      width: 20px;
      height: 20px;
      right: 50px;
      background: #fefefe;
      transform: rotate(45deg);
    }
    &.show {
      z-index: 2;
      opacity: 1;
      transform: scale(1);
    }
  }
  &.expand {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
    .asm-legend-wrapper {
      z-index: 10001;
    }
    header {
      .actions {
        .ToggleButtons {
          display: none;
        }
      }
    }
    > .table,
    > .graph {
      overflow: hidden;
      height: calc(100vh - 45px);
      display: flex;
      align-items: center;
      justify-content: center;
      .ASMWidget {
        transform: none;
      }
    }
  }

  > div {
    overflow: hidden;
    height: 510px;
    position: relative;
    width: 100%;
    .top-gradient {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100px;
      left: 0;
      opacity: 0;
      background-image: linear-gradient(rgba(#5886fb, 0.12), transparent);
      transition: opacity 1s;
      &.show {
        opacity: 1;
      }
    }
    .gradient {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      opacity: 0;
      background-image: radial-gradient($white 10%, $white 70%, #eee 100%);
      transition: opacity 1s;
      z-index: -1;
      &.show {
        opacity: 1;
      }
    }
    &.table {
      max-height: 510px;
      background-color: rgba($backgroundBlue, 0.25);
      .top-gradient,
      .gradient {
        display: none;
      }
    }
    &.graph {
      background-repeat: repeat;
      background-size: 15px;
    }

    .content-wrapper {
      width: 100%;

      .widget-wrapper {
        display: none;
        flex: 1 0;
        width: 100%;
        &.show {
          display: flex;
        }
      }

      .scrollbar-common {
        width: 100%;
      }

      .table-wrapper {
        overflow-y: auto;
        max-height: 510px;
        display: none;
        flex: 1 0;
        width: 100%;
        > div {
          width: 100%;
        }

        &.show {
          display: flex;
        }
      }
    }
    #AppLoading {
      font-size: 1.4rem;
      background: transparent;
    }
    .ASMWidget {
      transform: translateY(-18%);
    }
  }
  &.table {
    header {
      .actions {
        .size-change {
          pointer-events: none;
          opacity: 0.5;
          cursor: default;
        }
      }
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 14px;
    border-bottom: 1px solid #eee;
    border-radius: 4px 4px 0 0;
    font-size: 1.3rem;
    color: $gray19;
    font-weight: 400;
    position: relative;

    .actions {
      display: flex;
      align-items: stretch;
      height: 24px;
      .Button {
        margin-right: 10px;
        width: auto;
        box-shadow: none;
        height: 24px;
        box-sizing: border-box;
        padding-right: 5px;
        padding-left: 10px;
      }
      .size-change {
        display: flex;
        align-self: center;
        cursor: pointer;
      }
    }
  }
}
