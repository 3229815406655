.ActionItemsPopSideHeader {
  display: flex;
  flex-direction: column;

  .header-row {
    display: flex;
    align-items: center;
    padding: 17px 20px;

    .sides-border {
      border-left: 1px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;
      padding: 0 8px;
      display: flex;
    }
    .right-border {
      border-right: 1px solid #e7e7e7;
      padding: 0 8px;
      display: flex;
      align-items: center;
    }
    .icon-greater {
      margin: 0 10px;
    }

    .action-header-buttons {
      display: flex;
      align-items: center;
      position: relative;
      right: 45px;

      .btn-popside-navigate {
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        // top: 230px;
        height: 24px;
        width: 24px;
        border: 1px solid #e8e8e8;
        background-color: #fff;
        border-radius: 4px;
        // left: 22px;
        box-shadow: 0 1px 2px #cccccc;

        &.up {
          svg {
            transform: rotate(270deg);
          }
          margin-right: 12px;
        }
        &.down {
          svg {
            transform: rotate(90deg);
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #f9faff;
          border-color: draken(#e8e8e8, 5%);
        }
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
      .copy-link {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        margin-right: 8px;
        background-color: #f9faff;
        border-radius: 4px;
        // flex-grow: 1;

        &:hover {
          cursor: pointer;
          background-color: darken(#f9faff, 1%);
        }
      }
    }
  }
  .header-details {
    display: flex;
    align-items: center;
    height: 100px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    .urgency-with-pointer-wrapper {
      position: relative;
      height: 100%;
      width: 19px;
      overflow: hidden;
      .pointer-right {
        &:before {
          content: '';
          width: 1px;
          position: absolute;
          left: 0;
          top: 0;
          height: calc(50% + 4px);
          background: #e7e7e7;
          transform-origin: 0 0;
          transform: rotate(-20deg);
        }
        &:after {
          content: '';
          width: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          height: calc(50% + 4px);
          background: #e7e7e7;
          transform-origin: 0 100%;
          transform: rotate(20deg);
        }
      }
    }

    .left-indicator-line {
      width: 5px;
      height: 100%;
    }
    .header-urgency {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      // border-right: 1px solid #e7e7e7;
    }
    .right-side {
      display: flex;
      flex-direction: column;
      padding: 10px;

      .chips-details {
        display: flex;
        align-items: center;
      }
    }
  }
}
