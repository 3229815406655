@import '../../../styles/library.scss';

.highcharts-container {
  width: 100% !important;
  svg {
    width: 100% !important;
  }
}

.top-panel-container {
  position: sticky;
  top: 59px;
  z-index: 3;
  background-color: $backgroundBlue;
  // height: 100%;
  width: 100%;
  // padding-bottom: 11px;
  // height: 145px;

  & .top-panel-collapse-btn {
    left: auto !important;
  }
  & .top-panel-wrapper {
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 20px;

    & .top-panel-section {
      // padding: 15px 20px;
    }
  }
  & .panel-title {
    // padding-top: 6px;
  }

  & .collapse-panel-wrapper {
    // width: 100%;
    height: 145px;
    // display: flex;
    // flex-direction: column;
    // justify-content: start;
    // align-items: center;

    & .horizontal-border {
      // border-top: 1px solid $gray7;
    }

    & .data-panel-container {
      padding: 0;
    }
    & .panel {
      // padding: 25px 0px 0 0;
      margin-top: 5px;
      border-top: 1px solid $gray7;
    }

    & .panel-with-devider {
      display: flex;
    }

    & .vertical-devider {
      height: 145px;
      width: 1px;
      background-color: $gray7;
      margin: 0px 25px;
    }

    & .vertical-devider-collapse {
      height: 30px;
      width: 1px;
      background-color: $gray7;
      margin: 10px 25px;
    }
  }
}
