.IconChevron_scope {
  cursor: pointer;
}

.IconChevron_arrows {
  transform: translate3d(23px, -4px, 15px);
  transform-origin: center center;
  transform-box: fill-box;
}
.IconChevron_rotate {
  transform: translate3d(23px, -4px, 15px) rotate(0deg) !important;
}

.IconChevron_urotate {
  transform: translate3d(23px, -7px, 15px) rotate(180deg) !important;
}
