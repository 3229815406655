@import 'styles/library.scss';

.UnlinkedAssets {
  width: $popUpWidth * 1.1;
  min-height: 200px;
  height: auto;
  border-radius: 13px;
  font-size: 1.4rem;
  position: fixed;
  z-index: 2;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
}
.UnlinkedAssets-popup-body {
  margin: $defaultMargin;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 450px;
}

.UnlinkedAssets-header-scope {
  background: #2c2c30;
  margin: 8px 5px;
  border-radius: 6px;
  position: relative;
  color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UnlinkedAssets-remove {
  transform: rotate(45deg);
  font-size: 3rem;
  font-weight: 100;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.UnlinkedAssets-header-text {
  padding-left: $defaultPadding;
  font-size: 2rem;
  display: flex;
  align-items: center;
  .small {
    font-size: 1.4rem;
    display: inline-block;
    margin-left: $marginHalf;
  }
}

.UnlinkedAssets-event-scope {
  text-align: right;
}

.UnlinkedAssets-hosts {
  font-weight: bold;
}

.UnlinkedAssets-field-scope {
  margin: $defaultMargin 0;
  &:first-child {
    margin: 0;
  }
}

.UnlinkedAssets-field-header {
  color: #8c909a;
  display: flex;
  &.clickable {
    cursor: pointer;
  }
}

.UnlinkedAssets-field-value {
  margin: $marginHalf 0 $defaultMargin 0;
  a {
    color: $primaryBlue;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .evidences-list {
  }
  .raw-data-wrapper {
    padding: 0 $defaultPadding;
    box-sizing: border-box;
    display: none;
    &.visible {
      display: block;
    }
  }
}
