@import 'styles/library.scss';

.filterGrid_scope {
  position: absolute;
  color: #6d6d7a;
  background: $white;
  box-shadow: 0 0 0.6em rgba(24, 29, 78, 0.1);
  border-radius: $defaultBorderRadius;
  z-index: 1;
  top: 35px;
  padding: $defaultPadding $paddingHalf $paddingHalf $paddingHalf;
  width: 200px;
  font-size: 1.2rem;

  .filter-freetext-multi-inputs {
    .bg-div {
      top: 8px !important;
      right: 2px !important;
    }
    .SingleInput {
      display: block;
    }
    .close-trigger {
      height: 12px !important;
      top: 10px !important;
      right: 5px !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .add-button {
      button {
        font-weight: 400;
      }
    }
    .SingleInput-wrapper {
      &:not(:first-child) {
        width: calc(100% - 24px) !important;
      }
    }
  }
}

.filterGrid-header-scope {
  display: flex;
  justify-content: space-between;
  color: #3455dd;
  border-bottom: solid 2px #3455dd;
  cursor: pointer;
}

.filterGrid-item {
  margin: 0.8em 0;
  cursor: pointer;
}

.input-type {
  border-bottom: solid 1px #ececec;
  margin: 0.75rem 0 0 0;
}

.filterGrid-button-scope {
  display: flex;
  justify-content: center;
  padding-top: $paddingHalf;
  > button {
    flex: 1 0;
    font-weight: 400;
  }
}

.filterGrid-button {
  // padding: 0 $defaultPadding;
  width: 100%;
  border-radius: $defaultBorderRadius;
  font-size: 1.2rem;
}

.filterGrid-button-apply {
  background: #3455dd;
  color: #ffffff;
  font-size: 1.2rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.filterFreeText-input {
  width: 100% !important;
  margin: $marginHalf 0;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    text-decoration: line-through;
    &::placeholder {
      color: $white;
    }
  }
  &.error {
    &::placeholder {
      color: $red;
    }
  }
}
