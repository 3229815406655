@import 'styles/library.scss';

.NoIssuesIcon {
  background-color: #ffffff;
  padding: 25px 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  .icon-middle {
    position: absolute;
    top: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon-middle-card {
    position: relative;
    top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
