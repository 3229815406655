@import '../../../../styles/library.scss';

.collapse-panel-activeProtection {
  & .top-panel-active-protection-expand {
    // padding: 20px 20px 0 20px;
    // align-items: center;
    & .small-padding {
      margin-top: 4px;
    }
    & .chart-wrapper {
      flex-wrap: nowrap;
    }
    & .protected-data-panel:not(:last-child) {
      padding-right: 20px;
    }
    & .protected-data-panel {
      padding-top: 44px;

      & .last-30 {
        flex-wrap: nowrap;
        padding-top: 15px;
      }
      &.protected-data-removed {
        margin-right: 0;
      }
      & .content-class-name {
        padding-top: 12px;
      }
    }
    & .insecure-data-panel {
      padding-top: 40px;
      margin-right: 8px;

      & .insecure-right-text {
        padding-top: 14px;
        padding-left: 5px;
      }
    }
    & .active-protected-pie-wrapper {
      align-items: center;
      padding-top: 10px;
      width: 260px;
      & .chart-tooltip {
        width: auto;
        height: auto;
        padding: 10px;
      }
      & .MuiGrid-container {
        width: auto;
      }
    }
  }

  & .top-panel-active-protection-collapse {
    align-items: center;

    & .active-protected-title {
      padding-left: 16px;
    }
    & .active-protected-title-inner {
      padding-left: 16px;
    }
    & .active-protected-value {
      position: relative;
      margin-left: 8px;
      top: -1px;
    }

    & .line-chart {
      width: 300px;
    }
  }
}
