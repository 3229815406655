@import 'styles/library.scss';

.IntegrationBox {
  .PopSide {
    .PopSide-inner {
      padding-right: 8px !important;
    }
  }
}
.IntegrationList {
  display: flex;
  flex-direction: column;
  height: 100%;

  .show-delete-popside {
    opacity: 0.5 !important;
    pointer-events: none;
  }
  .list-header {
    display: flex;
    align-items: center;

    .Search {
      border: solid 1px #b7bbc5;
      background: $white;
      padding: 2px $paddingHalf;
      border-radius: 4px;
      .input-scope {
        position: relative;
        .input-cleanText {
          position: absolute;
          right: 0;
        }
      }
      .input-icon {
        width: 13px;
      }
    }
  }
  .integrations-container {
    height: calc(100vh - 245px);
    overflow-y: scroll;

    .icon-empty-search {
      position: relative;
      top: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .add-button {
    border: 1px solid #dadde0;
    color: #5d6576;
    background-color: #ffffff;
    width: 110px;
    height: 28px;
    font-size: 12px;

    &:active {
      color: #3455dd;
      border: 1px solid #3455dd;
      background-color: #ffffff;
    }
  }
  .integration-box {
    display: flex;
    align-items: center;
    margin: 16px 0;

    &.in-progress {
      border: 1px solid #ecedff;
      border-radius: 4px;
      padding: 20px 12px 20px 20px;
    }

    .trash-icon {
      svg {
        transform: scale(1.2);
      }
      &:hover {
        cursor: pointer;
      }
    }
    .continue-progress {
      font-size: 12px;
      margin-right: 10px;
      font-family: Rubik;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
