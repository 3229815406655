@import '../../../../../../../styles/library.scss';

.filterDate_scope {
  position: absolute;
  width: 280px;
  color: $gray5;
  background: $white;
  box-shadow: 0 0 0.5rem rgba(24, 29, 78, 0.1);
  border-radius: $defaultBorderRadius;
  z-index: 1;
  padding: $paddingHalf;
  font-size: 1.2rem;
  top: 35px;
  input {
    padding: 2px 4px !important;
    font-size: 1rem !important;
  }
}

// .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
//   padding: 1px 2px !important;
//   font-size: 0.9rem !important;
// }

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 0.75rem !important;
}

.MuiIconButton-root {
  padding: 0.2rem !important;
}

.css-1laqsz7-MuiInputAdornment-root {
  margin-left: 0 !important;
}

.filterDate-item-scope {
  display: flex;
  align-items: center;
  margin: $marginHalf 0;
}

.filterDate_button-scope {
  display: flex;
  justify-content: center;
  border-top: solid 1.5px #ececec;
  padding-top: $paddingHalf;
  font-size: 1.2rem;
  > button {
    flex: 1 0;
  }
}

.filterDate-label {
  margin: 0 $marginHalf;
}

.filterDate_button {
  padding: 0 $defaultPadding;
  border-radius: $defaultBorderRadius;
  width: 100%;
  font-size: 1.2rem;
  cursor: pointer;
}

.filterDate_button-apply {
  background: $primaryBlue;
  color: $white;
}

.filterDate_range-scope {
  display: flex;
  align-items: center;
}

.filterDate_RadioButton {
  position: relative;
}
