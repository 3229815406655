@import 'styles/library.scss';

.manageNotData-scope {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
}

.manageNotData-org-score {
  background-color: #ffffff;
  height: 100%;
}

.manageNotData-single-asset {
  background-color: #ffffff;
  height: 100%;
  z-index: 2;
  .manageNotData-icon {
    width: 70%;
    margin-top: 100px;
  }
}
