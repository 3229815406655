@import 'styles/library.scss';

.AdditionalInputs {
  margin-top: 30px;
  .input-wrapper {
    position: relative;
  }
  .line {
    width: 1px;
    height: 15px;
    background: #dfe1e4;
    margin-left: 15px;
  }
  .add {
    border: 1px solid #dfe1e4;
    width: 20px;
    height: 20px;
    font-size: 1.6rem;
    font-weight: 300;
    color: $primaryBlue;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
  }
  .inputs-wrapper {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 50px;
    }
  }
}
