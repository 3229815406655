@import '../../../styles/library.scss';

#AppLoading {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.transparent {
    background: transparent;
  }
  > div {
    margin-top: 20px;
    transform: scale(0.8);
    opacity: 0;
    animation: show 2s 1 forwards;
  }
  @keyframes show {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  // svg { transform: scale(1);  }

  &.full-screen {
    position: fixed;
    z-index: 99999999999999;
  }
}
