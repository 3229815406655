@import 'styles/library.scss';

.MainSeedFlow {
  border: 1px solid $paleBlue;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  margin-top: $defaultMargin * 1.5;
  color: $gray7;
  display: flex;
  flex-direction: column;
  background: $white;
  &.scroll {
    position: absolute;
    width: 100%;
    bottom: 70px;
    z-index: 1;
  }

  .header {
    padding: $defaultPadding;
    display: flex;
    align-items: center;
    flex: 0;
    justify-content: space-between;
    .search-field {
      //   width: 200px;
      flex-basis: 250px;
      font-size: 1.2rem;
      //
    }
    .selection-handles {
      font-size: 1.4rem;
      font-weight: 500;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 3px 0;
      button {
        margin-left: $marginHalf;
        background: $white;
        padding: 4px 12px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        cursor: pointer;
        box-shadow: 0 0 3px #ccc;
        img {
          height: 14px;
          margin-right: $marginSmall;
        }
        &.approve-btn {
          border: 1px solid #1aa884;
          color: #1aa884;
        }
        &.reject-btn {
          border: 1px solid #fb5858;
          color: #fb5858;
        }
      }
      > img {
        height: 12px;
        margin-left: $marginHalf;
        cursor: pointer;
      }
    }
    h4 {
      font-size: 1.4rem;
      margin: 0;
      padding: 0;
      //   flex: 3;
    }
    > div {
      //   flex: 1;
      > div {
        padding-left: $paddingHalf;
      }
      input {
        outline: 0;
        padding: $paddingHalf $paddingHalf $paddingHalf 0;
        &::placeholder {
          color: transparent;
        }
      }
    }
  }
  .info {
    font-size: 1.3rem;
    border-top: 1px solid $paleBlue;
    border-bottom: 0;
    background: $lighterBlue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 0;
    padding: 0 $defaultPadding;
    height: auto;
    overflow: hidden;
    transition: all 0.2s;
    flex: 0;
    &.visible {
      max-height: 100px;
      padding: $defaultPadding * 1.2 $defaultPadding;
      border-bottom: 1px solid $paleBlue;
    }
    img {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
}
