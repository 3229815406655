.test-result-render {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  font-size: 0.75rem;
  font-weight: bold;
}

.test-result-background {
  height: 1.3rem;
  width: 1.3rem;
  background: rebeccapurple;
  opacity: 0.2;
  position: absolute;
  transform: rotate(45deg);
}

.test-result-border {
  height: 1.3rem;
  width: 1.3rem;
  border: solid 1px rebeccapurple;
  position: absolute;
  transform: rotate(45deg);
}
