@import 'styles/library.scss';

.MuiTooltip-popper:has(> .MuiTooltip-tooltip) {
  z-index: 9999999 !important;
}

.technologies-CVEs-Popside {
  height: 100%;

  width: 906px;
  padding: 0 !important;

  .header-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ededed;
    padding: 20px 0 20px 20px;

    svg {
      margin-left: 2px;
      transform: scale(1.2);
    }
    .icon-arrow {
      transform: rotate(-90deg);
      position: relative;
      top: 2px;
      margin: 0 4px;
    }
  }

  .tech-row {
    display: flex;
    align-items: center;

    .tech-image {
      padding: 0.75rem;
      min-width: 80px;
      height: 80px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #e7e7e7;

      .app-logo-image {
        width: 42px;
        height: 42px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .app-custom-logo {
        font-size: 3.2rem;
        color: $gray2;
      }
    }
    .tech-row-details {
      display: flex;
      flex-direction: column;
      background-color: #fff;

      .eol-badge {
        display: flex;
        align-items: center;
        padding: 2px 6px 2px 4px;
        background-color: #fef5e9;
        border-radius: 4px;
        width: fit-content;
        margin-left: 12px;
        margin-bottom: 4px;

        .icon {
          position: relative;
          top: 1px;
        }
      }
      .technical-details {
        width: fit-content;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .PopSideCvesTab {
    display: flex;
    flex-direction: column;
    background-color: #eaeaea;
    overflow-y: scroll;
    height: calc(100vh - 200px);

    .cve-item {
      display: flex;
      flex-direction: column;
      // margin: 6px 0;
      background-color: #fff;

      &:not(:first-child) {
        margin-top: 12px;
      }

      .cve-item-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;

        .color-line {
          width: 5px;
          height: 50px;
          background-color: #3455dd;
        }
        .cve-item-name-wrapper {
          padding: 6px 10px;
          // background-color: #f9faff;
          background-color: #e5f0ff;
          border-radius: 4px;
          margin-left: 20px;
          display: flex;
          align-items: center;

          .cve-item-name {
            font-size: 13px;
            color: #4a79e7;
            &:active {
              color: #4a79e7;
            }
          }
          &:hover {
            background-color: darken(#e5f0ff, 3%);
            cursor: pointer;
          }
        }
        .cve-item-details {
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;
          padding-right: 24px;
          font-size: 13px;
          color: #5d6476;
        }
        .item-exploited {
          padding: 6px 10px;
          background-color: #ffeeee;
          margin-right: 24px;
          border-radius: 4px;
          font-size: 13px;
          a {
            color: #fb5858 !important;
          }
          &:hover {
            background-color: darken(#ffeeee, 3%);
            cursor: pointer;
          }
        }
      }
      .cve-item-body {
        display: flex;
        align-items: center;
        font-size: 13px;
        padding: 12px 10px 12px 26px;
        //height: 40px;
        // border-bottom: 1px solid #e8e8e8;
      }
      .cwe-item {
        // border-left: 1px solid #e8e8e8;
        padding-left: 0px;
        margin-left: 12px;
        display: flex;
      }
      .cve-item-description {
        padding: 16px 26px;
        // font-size: 13px;
        // color: #5d6476;
        .cyberpion-ui-text {
          line-height: 1.4;
        }
      }
    }
    // .cve-item:not(:last-child) {
    //   margin-bottom: 12px;
    //   background-color: aqua;
    // }
    &:first-child {
      .cve-item {
        margin-top: 12px;
      }
    }

    .AI-Cwe-Tooltip {
      display: flex;
      flex-direction: column;
      padding: 6px 8px;
      background-color: #fff;
      max-width: 400px;
      border-radius: 4px;
      font-size: 13px;

      .cwe-main-title {
        font-size: 15px;
        font-weight: 600;
        color: #000;
        font-family: Rubik;
        margin-bottom: 10px;
      }
      .cwe-link {
        margin-bottom: 10px;
        padding: 4px 6px;
        border-radius: 4px;
        background-color: #e5f0ff;
        width: fit-content;
        a {
          color: #3455dd;
          &:active {
            color: #3455dd;
          }
        }
        &:hover {
          background-color: darken(#e5f0ff, 3%);
          cursor: pointer;
        }
      }
      .cwe-description {
        color: #5d6576;
      }
    }
    .AI-Cvss-Tooltip {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      padding: 12px;
      background-color: #fff;
      border-radius: 4px;

      .main-title {
        font-size: 15px;
        font-weight: 600;
        color: #000;
        font-family: Rubik;
      }
      .row-item {
        display: flex;
        flex-direction: column;
        .title {
          color: #8c909a;
        }
        .value {
          color: #000;
        }
        .vector-string {
          display: flex;
        }
      }
      .score-row {
        display: flex;
        align-items: center;
        background-color: #f9faff;
        padding: 10px 22px;
        border-radius: 4px;
        margin: 12px 0;

        .score-row-item {
          display: flex;
          flex-direction: column;
          padding: 0 20px;
          .title {
            color: #8c909a;
          }
          .value {
            color: #000;
            text-transform: capitalize;
          }
          &:first-child {
            padding-left: 0;
            padding-right: 30px;
          }
          &:last-child {
            padding-right: 0;
            padding-left: 30px;
          }
          &:not(:last-child) {
            border-right: 1px solid #eaeaeb;
          }
        }
      }
      .tt-main {
        display: flex;
        // align-items: center;
        margin-top: 16px;

        .col-item-wrapper {
          display: flex;
          flex-direction: column;
          margin: 0 20px;

          // margin: 12px 0;

          .col-item {
            display: flex;
            flex-direction: column;
            // padding: 0 22px;
            margin: 8px 0;
            .title {
              color: #8c909a;
            }
            .value {
              color: #000;
              text-transform: capitalize;
              font-weight: 400;

              &.selected {
                color: #4a79e7;
                font-weight: 600;
              }
            }
            &:first-child {
              margin-top: 0;
              margin-bottom: 8px;
            }
            &:last-child {
              margin-bottom: 0;
              margin-top: 8px;
            }
          }
          &:first-child {
            margin-left: 0;
            margin-right: 20px;
          }
          &:last-child {
            margin-right: 0;
            margin-left: 20px;
          }
        }
      }
    }
    div:has(> .AI-Cvss-Tooltip),
    div:has(> .AI-Cwe-Tooltip) {
      background-color: #fff;
      box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05),
        0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04),
        0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
    }
  }

  .ActionItemsPopside {
    display: flex;
    flex-direction: column;
    // overflow-x: hidden;

    .scrollbar-common {
      scrollbar-color: white #fff !important;
    }

    .ActionItemsPopSideFooter {
      display: flex;
      flex-direction: column;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);

      .required-action {
        padding: 20px 20px 0 20px;
      }
      .actions-buttons {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding: 16px;
        align-items: center;
        button {
          font-size: 13px !important;
        }
        .ScanStatus {
          button {
            height: 28px;
            text-transform: none;
            padding: 0 12px;
            // width: 100px;
          }
        }

        .btn-next {
          width: 120px;
          height: 28px;
          margin-left: 16px;
        }
      }
    }
  }
}
