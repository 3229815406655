.tags-render-scope {
  display: flex;
  align-items: center;
}

.tag-render {
  display: flex;
  margin: 0 0.3rem;
  height: 1.9rem;
  align-items: center;
  color: #3455dd;
  background-color: #ecedff;
}

.tag-value-render {
  padding: 0 0.2rem;
  font-weight: bold;
}

.tag-text-render {
  padding: 0 0.2rem;
}

.count {
  color: #b7bbc5;
}
