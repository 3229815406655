@import 'styles/library.scss';

.CveList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;
  &.visible {
    display: block;
  }

  > li {
    margin-bottom: $defaultMargin !important;
    padding-left: $defaultPadding;
    .headline {
      color: $primaryBlue;
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      > span {
        color: $gray5;
        margin-left: 4px;
      }
      a:not(.cisa):hover {
        text-decoration: underline;
      }
      .cisa {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1px $paddingSmall;
        margin-left: $marginHalf;
        font-size: 1rem;
        border: 1px solid $red;
        color: $white !important;
        background: rgba($red, 0.4);
        border-radius: $borderRadiusSmall;
      }
    }
    .sub-headline {
      font-size: 1.2rem;
      margin-bottom: 6px;
      color: $gray5;
      font-weight: 500;
      display: inline-block;
      cursor: default;
    }
    ul.sub-headline {
      padding: 0;
      list-style-type: none;
      display: block;

      li {
        display: inline-block;
        margin-bottom: 0;
        padding: 0;
        margin-right: 4px;
        a {
          color: $cpBlue !important;
          // font-weight: 400;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .description {
      color: $gray5;
      font-size: 1.2rem;
      padding-right: 100px;
    }
  }
}

.Nist-Tooltip,
.CWE-Tooltip {
  font-size: 1.2rem;
  min-width: 300px;
  max-width: 300px;
  padding: 5px;
  &.CWE-Tooltip {
    > div {
      &:last-child {
        font-weight: 400;
        line-height: 130%;
      }
    }
  }
  > div {
    font-weight: 500;
    a {
      color: $cpBlue;
      &:hover {
        text-decoration: underline;
      }
    }
    &.divider {
      height: 1px;
      background: $gray19;
      margin: 10px 0;
    }
    span {
      font-weight: 400;
    }
  }
  .description {
    font-weight: 400;
  }
}
