.HighlightsTable {
  font-family: Rubik;
  width: 100%;
  color: #454549;
  thead {
    font-size: 12px;
    font-weight: 500;
    tr {
      td {
        position: relative;
        padding: 26px 0 8px 0px;
      }
    }
  }
  tbody {
    font-size: 11px;
    tr {
      td {
        padding: 7px 0;
      }
    }
  }
  tr {
    td {
      padding: 0px;
      border-bottom: 3px solid #fff;

      &:nth-child(1) {
        width: 150px;
        font-weight: 500;
        padding-right: 15px;
      }
      &:nth-child(2) {
        padding-right: 10px;
      }
      &:nth-child(3) {
        width: 165px;
        padding-right: 10px;
        svg {
          transform: scale(0.7);
        }
      }
      &:nth-child(4) {
        width: 140px;
        padding-right: 5px;
      }
      &:nth-child(5) {
        padding-right: 15px;
        svg {
          transform: scale(1.4);
        }
      }
    }
  }
  .link {
    &:hover {
      cursor: pointer;
    }
  }
}
