@import 'styles/library.scss';

.cnapp-alert-popside {
  .PopSide {
    .PopSide-inner {
      width: 905px;
      padding: 0 !important;

      .close-trigger {
        top: 21px !important;
      }
      .CnappAlertPopside {
        display: flex;
        flex-direction: column;
        // overflow-x: hidden;
        .scrollbar-common {
          scrollbar-color: white #fff !important;

          .popside-content {
            overflow: scroll;
            height: calc(100vh - 240px);
            position: relative;
          }
        }
      }
    }
  }
}
