@import 'styles/library.scss';

.RadioButtons {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .radio-buttons {
    display: flex;
    .radio-button {
      border: 1px solid #dadde0;
      color: #5d6576;
      background-color: #ffffff;

      &.active {
        color: #3455dd;
        border: 1px solid #3455dd;
        background-color: #ffffff;
      }

      &.marginLeft {
        margin-left: 10px;
      }

      &.marginRight {
        margin-right: 10px;
      }
    }
  }
}
