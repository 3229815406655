.discovery-investigation-asset-rules-popover {
  .MuiPaper-root {
    min-width: 300px;
    max-width: 450px;
  }
}

.DiscoveryScanApproved {
  display: flex;
  align-items: center;
  // background-color: #e8f6f3;
  border-radius: 4px;
  padding: 3px 6px;

  &.canHover {
    &:hover {
      cursor: pointer;
    }
  }
}
