@import '../../../styles/library.scss';

.acknowledgeReason {
  width: 712px;
  border-radius: $borderRadiusBig;
  font-size: 1.4rem;
  position: absolute;
  z-index: 5;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
}

.acknowledgeReason-popup-body {
  margin: 1vw;
  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:first-child {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
      }
    }
    svg {
      margin-right: 5px;
    }
  }
}

.acknowledgeReason-header-scope {
  background: #2c2c30;
  margin: 8px 5px;
  border-radius: 6px;
  position: relative;
  color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.acknowledgeReason-remove {
  transform: rotate(45deg);
  font-size: 2.5vw;
  font-weight: 100;
  position: absolute;
  right: 0.5vw;
  cursor: pointer;
}

.acknowledgeReason-header-text {
  font-size: 2rem;
  margin-left: $defaultMargin;
}

.acknowledgeReason-warning-scope {
  background: rgba(244, 102, 102, 0.1);
  border: 2px solid #f46666;
  padding: 2vw;
  margin: 1vw 0;
  text-align: center;
  border-radius: 8px;
}

.acknowledgeReason-warning-header {
  font-size: 1vw;
  font-weight: bold;
}

.acknowledgeReason-textarea {
  width: -webkit-fill-available;
  height: 12vw;
  margin: 1vw 0;
  padding: 0.5vw 0 0 0.5vw;
  background: #f8fafc;
  border: 1px solid #d1d2d3;
  box-sizing: border-box;
  border-radius: 8px;
  resize: none;
}

.acknowledgeReason-event-scope {
  text-align: right;
  flex: 1 0;
  display: flex;
  justify-content: flex-end;
  .Button {
    width: 100px;
    margin-left: 10px;
  }
}

.acknowledgeReason-buttom {
  border-radius: $borderRadiusSmall;
  margin: 0 0.5vw;
  height: 2vw;
  width: 6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.9vw;
}

.acknowledgeReason-hosts {
  font-weight: $fontBold;
}
