@import '../../../../styles/library.scss';

.panelSide-scope {
  transition: all 0.2s linear;
  height: 100%;
}

.panelSide {
  width: 272px;
  height: calc(100vh - #{$headerMarginTop});
  color: #8c909a;
  font-size: 1.2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: -240px;
  box-sizing: border-box;
  filter: drop-shadow(0 0.2rem 0.63rem rgba(24, 29, 78, 0.1));
  transition: margin-left 0.3s;
  &.panelSide-open {
    margin-left: 0;
  }
}

.panelSide-head {
  padding-top: 15px;
  background: #2c2c30;
  position: relative;
  border-radius: 0 $defaultBorderRadius 0 0;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  .panel-header-image {
    position: absolute;
    width: 272px;
    top: 53px;
    right: 0;
  }
}

.panelSide-body {
  background: #ffffff;
  flex: 1 0;
  overflow: hidden;
}

.panelSide-body-label {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 28px 0 12px 0;
}
.panelSide-title {
  margin-bottom: 4px;
}

.panelSide-value {
  color: #5d6576;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 2px;
    }
  }
}

.panelSide-sub-label-scope {
  margin-bottom: 12px;
  width: inherit;
  word-wrap: break-word;
}

.panelSide-collapse-tabs {
  position: absolute;
  right: -8px;
  top: 8px;
  cursor: pointer;
}
