@import 'styles/library.scss';

.CustomTagsRenderer {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  > li {
    &.deleted {
      display: none;
    }
    .chip-item {
      border: 1px solid #dfe1e4;
      background: #ffffff;
      border-radius: 4px;
      padding: 2px;
      margin-right: 4px;
      display: flex;
      font-size: 1rem;
      > div {
        padding: 2px 6px;
      }
      > span {
        border-left: 1px solid #dfe1e4;
        border-radius: 0 4px 4px 0;
        display: flex;
        align-items: center;
        width: 14px;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: #fbfbfb;
        }
        img {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}
