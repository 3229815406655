@import 'styles/library.scss';

.ScanStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .Button[disabled]:not(.scan-inprogress-btn):not(.scan-completed-btn) {
    opacity: 0.5;
  }
  .scan-btn {
    display: flex;
    align-items: center;
    box-shadow: 0 0 3px #eee;
    border: 1px solid #dadde0;
    border-radius: 4px;

    .Button.secondary {
      border: 0px solid #fff;
    }
    .icon-play {
      margin-left: 12px;
      padding-top: 2px;
    }
  }
  .scan-inprogress-btn {
    background: $orange !important;
    color: $white !important;
    border-color: $orange !important;
    &:hover {
      background-color: $orange !important;
      color: $white !important;
    }
  }
  .completed-wrapper {
    display: flex;
    align-items: center;
    .scan-completed-btn {
      flex-direction: row-reverse;
      background: #f7f7f7;
      color: #666666;
      border-color: #ccc;
      box-shadow: 0 0 3px #eee;
      margin-right: 6px;
      cursor: default;
      &:hover {
        background-color: #f7f7f7 !important;
        color: #666666 !important;
      }
    }
    img {
      margin-top: -1px;
      height: 13px;
      width: 13px;
      cursor: pointer;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}
