@import 'styles/library.scss';

.SeedDataAdditionalInfo {
  position: fixed;
  width: 220px;
  // height: 200px;
  background: $white;
  z-index: 999;
  transform: translateY(-50%);
  box-shadow: 0 0 4px rgba(#333, 0.25);
  border-radius: 6px;
  h4,
  h5 {
    margin: 0;
    padding: 0;
    color: $gray7;
    font-weight: 500;
  }
  h4 {
    font-size: 1.8rem;
    margin: 20px 20px $defaultMargin 20px;
    text-transform: capitalize;
    color: #454549;
    &.domain {
      text-transform: lowercase;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        border-radius: 3px;
        margin-right: 4px;
      }
    }
    &.small {
      font-size: 1.4rem;
    }
  }
  h5 {
    margin: $defaultMargin 0;
    font-size: 1.2rem;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: $defaultMargin;
      &:last-child {
        margin-bottom: 0;
      }
      h6 {
        color: $gray4;
        font-size: 1.2rem;
        margin: $marginSmall 0;
        font-weight: 400;
      }
      p {
        font-size: 1.3rem;
        margin: 0;
        padding: 0;
      }
      .link-wrapper {
        margin-bottom: 2px;
        a {
          color: #60646a;
          font-size: 1.2rem;
          word-break: break-all;
          &:hover {
            font-weight: 500;
          }
        }
      }
    }
  }
}
