@import 'styles/library.scss';

.ThreatCenter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: $headerMarginTop;
  height: calc(100vh - #{$headerMarginTop} - #{$addintionalBottomSpace});
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  z-index: 1;

  .scrollbar-common {
    scrollbar-color: lighten(#ccc, 5%) transparent !important;
  }

  .ThreatCenterHeader {
    #AppLoading {
      position: relative;
      background: transparent;
      height: 41px;
    }
  }

  .threat-center-main {
    display: flex;
    flex: 1;
    position: relative;
  }
}
