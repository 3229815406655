.Discovery-Results-Header {
  .title {
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
    }
  }
}
