@import 'styles/library.scss';

.SubMenu {
  &.open {
    ul {
      max-height: 200px;
      margin: $defaultMargin 0 !important;
      border-left: 1px solid $gray6;
    }
    .sub-menu-header {
      color: $darkBlue;
    }
  }
  .sub-menu-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    svg {
      transition: transform 0.3s;
    }
  }
  ul {
    max-height: 0;
    overflow: hidden;
    margin: 0 !important;
    transition: all 0.3s;
    border-left: 1px solid transparent;
  }
}
