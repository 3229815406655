.__react_component_tooltip {
  &.description-tt {
    max-width: 250px;
    line-height: 130% !important;
    font-size: 1.1rem !important;
  }
  &.stay {
    font-size: 20px !important;
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
}
