@import '../../../../../styles/library.scss';

.tabTechnologies {
  padding: $paddingHalf 0 0 0;
  background-color: #ffff;
  height: 100%;
  border-radius: $defaultBorderRadius;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: hidden;
  .infinite-scroll-component__outerdiv {
    height: 100%;
  }

  .scrollbar-common {
    scrollbar-color: #fff #e8e8e8 !important;
  }

  .technologies-cves-popside {
    .PopSide.visible {
      .PopSide-inner {
        width: 906px;
        padding: 0 !important;

        .close-trigger {
          top: 25px !important;
        }
      }
    }
  }
}

.tabTechnologies-scope {
  flex: 1 0;
  position: relative;
  padding: 20px 0 20px 20px;
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;

  .tabTechnologies-items {
    // min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-right: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .tabTechnologies-item {
      // flex: 1 0 40%;
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      color: #8c909a;
      align-items: flex-start;
      position: relative;
      display: inline-flex;
      vertical-align: top;
      background-color: #f9faff;
      margin-bottom: 20px;
      border: 1px solid #e7e7e7;
      max-height: 140px;

      &:nth-child(odd) {
        margin-right: 30px;
      }

      .tech-image {
        padding: 0.75rem;
        width: 50px;
        height: 50px;
        // background: rgba(255, 255, 255, 0.04);
        // box-shadow: 0 0.25rem 0.45rem rgba(24, 29, 78, 0.06);
        // border-radius: 0 1rem;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e7e7e7;

        .app-logo-image {
          width: 42px;
          height: 42px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .app-custom-logo {
          font-size: 3.2rem;
          color: $gray2;
        }
      }
      .tech-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .tech-name-version {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          .eol-badge {
            display: flex;
            align-items: center;
            padding: 4px 6px;
            background-color: #f9f0e9;
            border-radius: 4px;
          }
        }

        .tech-details {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .tech-row-details {
            display: flex;
            flex-direction: column;
            margin-left: 16px;

            .technical-details {
              width: fit-content;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .cves-count {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.tabTechnologies-right-side {
  position: relative;
  margin-left: $marginHalf;
  box-sizing: border-box;
  flex: 1 0;
  display: flex;
  border-radius: 0 $borderRadiusBig $borderRadiusBig $borderRadiusBig;
  height: 100%;
  position: relative;
  height: 80px;
  .technology-content {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background: rgb(240, 242, 246);
    overflow: hidden;
    padding: $defaultPadding;
    padding-bottom: 0;
    box-sizing: border-box;
    transition: all 0.4s;
    border-radius: $defaultBorderRadius $defaultBorderRadius 0 $defaultBorderRadius;
    .bottom-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: $defaultPadding;
      background: rgb(240, 242, 246);
      padding-left: $defaultPadding;
      font-size: 12px;
      line-height: 8px;
    }
  }
}

.tabTechnologies-warning {
  position: absolute;
  right: 2.2rem;
  z-index: 10;
}

.tabTechnologies-name {
  font-weight: 400;
  color: $gray6;
  margin-bottom: 4px;
  display: block;
  max-width: 50%;
  font-size: 1.2rem;
}

.tabTechnologies-version {
  color: $gray4;
  margin-left: $marginSmall;
}
