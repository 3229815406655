@import 'styles/library.scss';

.Jira {
  .showDeletePopupHeader {
    opacity: 0.5;
    pointer-events: none;
  }
  .delete-button-block {
    margin-left: 8px;
    &:hover {
      cursor: pointer;
    }
  }
  .inActive {
    opacity: 0.5 !important;
    pointer-events: none;
  }
  .active {
    opacity: 1 !important;
    pointer-events: auto;
  }
  .add-integration {
    margin-top: 10px;
    // margin-bottom: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  .jira-not-first-block {
    margin-top: 16px;
  }
  .text-field-wrapper {
    .MuiOutlinedInput-root.Mui-error {
      border: 1px solid $red !important;
    }
  }
  .add-button {
    // margin-top: 15px;
    border: 1px solid #dadde0;
    color: #5d6576;
    background-color: #ffffff;
    width: 110px;
    height: 28px;
    font-size: 12px;

    &:active {
      color: #3455dd;
      border: 1px solid #3455dd;
      background-color: #ffffff;
    }
  }
}
