@import '../../../styles/library.scss';

.action-items-pdf-wrapper {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  & .divider {
    width: 820px;
    height: 1px;
    background-color: $backgroundBlue;
    position: relative;
    left: -65px;
    margin: 25px 0;
  }
}
