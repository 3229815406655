@import '../../../styles/library.scss';

.notifications {
  // margin-left: 15px;
  background-color: $white;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  &.disable {
    opacity: 0.4;
    pointer-events: none;
  }

  & .notifications-list {
    overflow-y: scroll;
    height: 100%;

    & .notification-group {
      height: 55px;
      width: 100%;
      padding-left: 40px;
      border-bottom: 1px solid $gray1;
    }
  }

  & .empty-state-button {
    > button {
      width: 250px;
    }
  }

  & .notifications-no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    > svg {
      transform: scale(1.3);
    }
  }
}
