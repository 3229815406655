@import '../../../../../../styles/library.scss';

.action-item-container {
  & .action-item-details {
    padding-top: 10px;

    & .padding {
      margin-top: 10px;
    }

    & .small-padding {
      margin-top: 4px;
    }

    & .technical-details-container {
      border: 1px solid $backgroundBlue;
      overflow-wrap: anywhere;

      & .technical-details-header-scope {
        margin: 8px !important;
      }
      & .technical-details {
        font-size: 11px;
      }
    }

    & .impact {
      white-space: pre-line;
    }

    & .cves {
      a,
      .description {
        font-size: 11px;
        line-height: 14px;
      }
      li {
        padding: 0;
      }
    }
  }
}
