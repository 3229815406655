@import 'styles/library.scss';

.formGraph-label-scope {
  display: flex;
  align-items: center;
}

.formGraph-icon {
  cursor: pointer;
}

.formGraph-value {
  font-size: 1.2rem;
}

.formGraph-header-scope {
  &.text-section {
  }
}
