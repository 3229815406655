.PopSideTabs {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  border-bottom: 1px solid #e7e7e7;
  // box-shadow: 0px 10px 14px -2px rgba(0, 0, 0, 0.1);

  .ai-popside-tab {
    display: flex;
    align-items: center;
    color: #6d6d7a;
    // padding: 10px 10px;
    border-radius: 4px;
    border: 1px solid #fff;

    .icon {
      background-color: #fff;
      border-radius: 4px;
      padding: 5px;
      // width: 25px;
      // height: 25px;
      display: flex;
      align-items: center;
      // justify-content: center;
      margin-right: 5px;
    }
    .active {
      background-color: #ecedff;
    }
    &:hover {
      cursor: pointer;
      .cyberpion-ui-text {
        color: #3455dd !important;
      }
      // .icon-tab {
      //   // fill: #3455dd;
      //   stroke: #3455dd !important;
      // }
      .icon-comments {
        path {
          stroke: #3455dd !important;
          fill: #3455dd !important;
          // fill: #3455dd !important;
        }
      }
    }
    .cyberpion-ui-text {
      &:hover {
        color: #3455dd !important;
      }
    }
    .preview {
      position: relative;
      top: -14px;
      margin-left: -20px;
      background: #3455dd;
      color: #ffffff;
      font-size: 0.8rem;
      padding: 1px 4px;
      border-radius: 4px;
    }
  }
  .DropDown {
    width: 125px;
  }
}
