@import 'styles/library.scss';

.ActionsRow {
  border-top: 1px solid $paleBlue;
  padding: 17px 0;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  background: $white;
  z-index: 1;
  &.scroll {
    position: absolute;
  }
  .Button {
    width: 100px;
  }
  .reset {
    display: flex;
    align-items: center;
    color: #fb5858;
    font-size: 1.2rem;
    cursor: pointer;
    img {
      margin-right: $marginSmall;
    }
  }
}
