@import '../../../../../../styles/library.scss';

.SliderBox {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  align-items: center;
  &.disabled {
    pointer-events: none;
    .MuiSlider-root {
      color: $gray2;
    }
  }
  .MuiSlider-valueLabel {
    background: $gray9;
    color: $gray5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px 2px 8px;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .MuiSlider-root {
    height: 1px;
    color: $brandBlue;
    transition: all 0.3s;
  }
  .MuiSlider-thumb {
    width: 13px;
    height: 13px;
    &:hover {
      background-color: darken($lightBlue2, 8%);
    }
    &:before,
    &:hover {
      box-shadow: 0 0 0 !important;
    }
  }
  .range {
    flex: 0;
    color: $gray4;
    font-size: 1.2rem;
    &:first-child {
      margin-right: $marginHalf * 1.5;
    }
    &:last-child {
      margin-left: $marginHalf * 1.5;
    }
  }
}
