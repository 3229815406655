@import 'styles/library.scss';

.ClusterGrid {
  height: calc(100% - 25px);
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;

  .ClusterSortPanel {
    border-bottom: 1px solid $gray1;
    box-sizing: border-box;
    padding-left: 32px;
    .sort-icon {
      cursor: pointer;
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  #ClusterGrid {
    position: absolute;
    width: 100%;
    top: 46px;
    height: calc(100% - 50px) !important;
    overflow: auto;
    background: #f5f6ff;
    .MuiDataGrid-main {
      margin-left: 10px;
      margin-right: 0px;
    }
    .MuiDataGrid-columnHeaders {
      background: $white;
    }
    .cluster-loader {
      &.isNextPage {
        position: relative;
      }
    }
  }

  > div {
    width: 100%;
    .clusters-wrapper {
      width: 100%;
      .ClusterCollapsePanel {
        margin-bottom: 8px;
        border-bottom: 1px solid $gray1;
        .urgency-item.empty {
          opacity: 0.2;
          filter: grayscale(1);
        }
        &:first-child {
          margin-top: 8px;
        }
        .ClusterCollapsePanel-row {
          background: $white;
          padding-left: 14px;
        }
      }
      .cluster-empty-state {
        height: calc(100vh - 400px);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
