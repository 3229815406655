@import 'styles/library.scss';

.Button {
  width: 100%;
  font-size: 1.3rem;
  height: 36px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  font-family: $fontFamilyMain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  box-shadow: 0 1px 2px lighten($black, 80%);
  transition: background-color 0.3s;
  .pointer {
    margin-left: 0px;
  }
  &.size-small {
    flex-basis: 75px;
    width: 75px;
    height: 28px;
    font-size: 1.2rem;
    padding: 0;
  }
  &.size-tiny {
    width: auto;
    height: auto;
    font-size: 0.9rem;
    padding: 2px 4px;
    text-transform: uppercase;
    text-align: center;
    box-shadow: none;

    img {
      width: 8px;
      height: 8px;
      margin-right: 0;
    }
  }
  &.loading {
    pointer-events: none;
    opacity: 0.5;
    background: $white;
    #AppLoading {
      background: $white;
    }
    svg {
      margin: 0;
    }
  }

  &:disabled:not(.secondary) {
    pointer-events: none;
    opacity: 0.5;
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &.main {
    background-color: $brandBlue;
    color: $white;
    &:hover:not(:disabled) {
      background-color: darken($lightBlue2, 8%);
    }
  }
  &.grayed {
    background: #f0f2f6;
    color: $gray4;
  }
  &.alert {
    background: #f46666;
    color: #ffffff;
  }
  &.secondary {
    background: $white;
    color: $gray17;
    border: 1px solid $gray14;
    &:disabled {
      pointer-events: none;
    }
    &:hover:not(:disabled) {
      border-color: darken($gray14, 10%);
      background-color: darken($gray22, 2%);
    }
  }
  > img {
    margin-right: $marginHalf;
  }
}
