@import 'styles/library.scss';
.AddRemoveTags {
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  position: absolute;
  background: #ffffff;
  z-index: 2;
  right: -17px;
  top: 25px;
  width: 210px;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray9;
  border-radius: 4px;
  overflow: hidden;
  .error {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 !important;
    background: $white;
    z-index: 2;
    flex-direction: column;
    > div {
      white-space: initial;
      text-align: center;
      line-height: 150%;
    }
    img {
      margin-bottom: $marginHalf;
      width: 22px;
      height: 22px;
    }
  }
  #AppLoading {
    > div {
      height: 40px !important;
    }
  }
  .Search {
    padding: 8px 6px;
    flex: 0;
    border-bottom: 1px solid $gray9;
    input {
      font-size: 1.1rem;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
  .create-new-wrapper {
    flex: 0;
    border-bottom: 1px solid $gray9;
    position: relative;
    input {
      padding: 8px 6px;
      font-size: 1.1rem;
      float: left;
      width: calc(100% - 50px);
    }
    .Button {
      position: absolute;
      top: 50%;
      right: 5px;
      width: 17px;
      transform: translateY(-50%);
    }
  }
  .scrollbar-common {
    flex: 1 0;
    > div {
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ul {
    list-style-type: none;
    padding: $defaultPadding;
    margin: 6px 0 0 0;
    padding: 0;
    max-height: 120px;
    min-height: 120px;
    overflow: scroll;
    margin-right: $marginSmall;
    li {
      padding: $paddingSmall;
      padding-left: 6px;
      margin-bottom: 6px;

      span {
        border: 1px solid $gray11;
        user-select: none;
        padding: 2px;
        border-radius: 2px;
        cursor: pointer;
        &.new {
          border-color: $primaryBlue;
          background: $lighterBlue;
        }
        &.remove {
          border-color: $red;
          background: lighten($red, 20%);
        }
      }
    }
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px 6px;
    background: $gray10;
    > div {
      max-width: 100px;
      white-space: pre-wrap;
      font-size: 1rem;
      position: relative;
      height: 25px;
      width: 100%;
      .success {
        span {
          color: $green;
          &:after {
            content: '\2713';
          }
        }
      }
      #AppLoading {
        background: transparent;
      }
    }
  }
}
