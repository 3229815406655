.integration-script-area {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;

  .script-field {
    display: flex;
    background-color: #f0f2f6;

    .clipboard {
      position: absolute;
      right: 4px;
      margin-top: 4px;
      width: 24px;
      height: 24px;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 4px;
      svg {
        transform: scale(1.2);
      }
      &:hover {
        cursor: pointer;
        background-color: #ecedff;
      }
    }
  }

  .error-text {
    margin-top: 12px;
  }
}
