// body {
//   background-color: #FFFFFF !important;
//   // width: 816px !important;
// }
.page-section {
  background-color: gray;
  margin-top: 25px;
  padding: 40px;
}
.generate-report-loading {
  position: relative;
  min-height: 100px;
  margin-top: 100px;
  div:first-child {
    position: relative;
    font-size: 1.2rem;
  }
  div:last-child {
    position: relative;
    height: 100px;
    #AppLoading {
      background: transparent;
    }
  }
}
