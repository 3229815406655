@import '../../../../../styles/library.scss';

.topActionItems {
  margin: $defaultMargin $defaultMargin;
}
.topActionItems-card-scope {
  background-color: #454549;
  color: #d1d2d3;
  border-radius: 0.2rem 0.2rem 1.18rem 0.2rem;
  position: relative;
  margin: $marginHalf 0;
  overflow: hidden;
}

.topActionItems-header {
  font-size: 1rem;
}

.topActionItems-sub-header {
  padding: $paddingHalf $defaultPadding;
}

.topActionItems-header-main {
  // display: flex;
  align-items: center;
  font-size: 1.4rem;
  // justify-content: flex-start;
  margin-bottom: $marginSmall;
  > div {
    text-transform: capitalize;
    font-size: 1rem;
    display: inline;
    &:before {
      content: '\2022';
      margin: 0 $marginSmall;
    }
    &:first-child {
      font-size: 1.4rem;
      color: $white;
      &:before {
        content: none;
      }
    }
  }
}

.topActionItems-border-left {
  height: 60%;
  position: absolute;
  width: 6px;
  left: -3px;
  top: 20%;
  border-radius: 1px;
}

.topActionItems-title {
  font-size: 1.4rem;
  color: #fff;
  font-weight: 400;
}

.topActionItems-add-circle {
  width: 0.2rem;
  height: 0.2rem;
  background: #6d6d7a;
  margin: 0 0.8vw;
  border-radius: 0.63rem;
}

.topActionItems-cut-text {
  width: inherit;
  // display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}

.topActionItems-type {
  white-space: pre;
}

.topActionItems-body-scope {
  display: flex;
  justify-content: space-between;
}

.topActionItems-assignee-scope {
  display: flex;
  justify-content: flex-end;
}

.topActionItems-summary {
  font-size: 1.2rem;
}
