@import 'styles/library.scss';

.AlertMulti {
  display: flex;
  flex-direction: column;
  height: 100%;

  .notice {
    font-size: 1.3rem;
    font-weight: 500;
    color: $gray6;
    flex: 0;
    margin: $marginHalf 0;
  }
  > div:last-child {
    flex: 1 0;
  }
  .notice-multi {
    font-size: 1.3rem;
    color: $gray6;
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex: 0;
    li {
      width: 30%;
      box-sizing: border-box;
      margin: 0 $defaultMargin * 2 $defaultMargin 0;
      .key {
        font-weight: 500;
        display: block;
      }
      .value {
        word-break: break-all;
        max-height: 100px;
        display: block;
        overflow-y: auto;
      }
      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
    }
  }
  .MuiDataGrid-virtualScroller {
    overflow-x: hidden;
  }
  .alert-single-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 0;
    // width: 100%;
    z-index: 0;
    overflow: hidden;
    transition: all 0.5s;
    &.show {
      z-index: 100;
      width: 100%;
      .AlertPopup {
        right: 0;
        opacity: 1;
      }
      .dark-layer {
        opacity: 0.5;
        background: $white;
      }
    }
    .AlertPopup {
      position: absolute;
      width: 50%;
      right: -50%;
      top: 0;
      height: 100%;
      z-index: 11;
      background: $white;
      transition: all 0.5s;
      opacity: 0;
      box-shadow: -1px 0 2px 0px $gray9;
      .PopupHeader {
        display: none;
      }
      .notice {
        font-size: 1.4rem;
        font-weight: 600;
      }
      .popup-inner {
        height: calc(100% - 50px);
        position: absolute;
        padding: $defaultPadding * 1.5;
        padding-bottom: $defaultPadding;
      }
    }
    .dark-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $white;
      top: 0;
      left: 0;
      z-index: 9;
      opacity: 0;
      transition: opacity 0.5s;
    }
  }
}
