@import 'styles/library.scss';

.FilterGroupsDropdown {
  position: relative;
  cursor: pointer;
  &.disabled {
    header {
      pointer-events: none;
      background: transparent;
      svg {
        display: none;
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    span {
      transform: rotate(90deg);
      font-size: 1.6rem;
      color: $gray4;
    }
    svg {
      transition: transform 0.3s;
    }
    &.visible {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  ul {
    list-style-type: none;
    position: fixed;
    padding: 0 $defaultPadding;
    background: $white;
    border-radius: $defaultBorderRadius;
    box-shadow: 0 0 0;
    z-index: 1;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;
    transform: translate(-45%, 2px);
    &.visible {
      max-height: 60px;
      box-shadow: 0 0 1px 1px $gray9 inset;
    }
    li {
      margin: $marginHalf 0;
      cursor: pointer;
      &:hover {
        font-weight: 600;
      }
    }
  }
}
