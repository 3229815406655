@import '../../../../styles/library.scss';

.top-panel-container {
  & .collapse-panel {
    & .collapse-panel-singleAsset-connections,
    .collapse-panel-singleAsset-evidence {
      height: 50px;
    }
    & .top-panel-evidence {
      display: flex;
      align-items: center;
    }
  }
  & .collapse-button {
    // height: 0 !important;
  }
}
