@import 'styles/library.scss';

.TabGraph {
  width: 100%;
  height: 100%;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999999999;
  }
  #GRAPH {
    height: 100%;
    width: 100%;
    background-repeat: repeat;
    background-size: 15px;
    .top-gradient {
      position: absolute;
      top: 0;
      width: 100%;
      height: 150px;
      left: 0;
      opacity: 1;
      background-image: linear-gradient(rgba(#5886fb, 0.2), transparent);
      transition: opacity 1s;
    }
    // .gradient {
    //   position: absolute;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   left: 0;
    //   opacity: 1;
    //   background-image: radial-gradient($white 10%, $white 70%, #eee 100%);
    //   transition: opacity 1s;
    //   z-index: 0;
    // }
  }
}
