@import '../../../../../../styles/library.scss';

.panel-body-container {
  margin: 0 10px 0 0px;
  font-size: 1.1rem;
  font-family: Rubik;
  // margin-left: 25px;

  & .panel-body-title {
    font-weight: 400;
    color: #454549;
    background-color: #f9faff;
    padding: 10px 25px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
  }
  & .panel-body-field {
    color: #8c909a;
    padding-left: 25px;
    padding-bottom: 3px;
  }
  & .panel-body-field-text {
    color: #454549;
    padding-top: 5px;
    font-weight: 200;
    padding: 0 25px;
    word-break: break-word;
    max-width: 260px;
    &.internet_paths {
      padding: 5px 0 0 25px;
      .level-wrapper:first-child {
        > div:last-child {
          max-width: 175px !important;
        }
      }
      .level-text {
        max-width: 130px;
        font-size: 0.9rem;
      }
    }
    &.internal_networks {
      padding: 5px 0 0 25px;
      .level-wrapper {
        .last-third-level-middle {
          &:after {
            top: -11px;
          }
        }
      }
      .level-wrapper:first-child {
        > div:last-child {
          max-width: 150px !important;
        }
      }
      .level-text {
        max-width: 110px !important;
        font-size: 0.9rem;
      }
    }
  }

  & .panel-body-divider {
    border-color: #eaedf5 !important;
    position: relative;
    // left: -25px;
    width: 100%;
  }
  & .collapse-fields-title {
    padding: 14px 25px;
    font-weight: 400;
    color: #454549;
    background-color: #f9faff;
  }

  .panel-body-tags,
  .panel-body-hostings,
  .panel-body-groups {
    padding: 2px 20px 8px 24px;
    overflow: hidden;
    transition: all 0.4s;
    height: auto;
    max-height: 700px;
    &.collapsed {
      max-height: 66px;
    }
    .panel-body-tag,
    .panel-body-hosting,
    .panel-body-group {
      padding: 4px 6px;
      border: 1px solid $gray2;
      border-radius: 4px;
      margin-right: 6px;
      margin-top: 6px;
    }
  }
  .panel-body-tags-expand,
  .panel-body-hostings-expand,
  .panel-body-groups-expand {
    padding: 4px 20px 8px 20px;
    color: $primaryBlue;
    cursor: pointer;
  }
  .fqdn-link {
    > div:hover {
      color: $primaryBlue;
      font-weight: 400;
    }
  }
  .fqdns-count {
    border-radius: 4px;
    padding: 5px 8px;
    margin: 6px 0 25px 25px;
    background-color: #f0f2fc;
    color: #3455dd;
    cursor: pointer;
    display: inline-flex;
  }
}
