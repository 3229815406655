@import '../../../styles/library.scss';

.user-scope {
  border-radius: 50%;
  border: solid 1px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  color: $primaryBlue;
  position: relative;
  &:hover {
    background-color: rgba(25, 118, 210, 0.14);
  }

  .user-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    + div {
      position: absolute !important;
      left: calc(100% + 12px) !important;
      top: -40px !important;
      .MuiPaper-root {
        &:before {
          content: '';
          position: absolute;
          background: $gray9;
          width: 8px;
          height: 8px;
          left: -4px;
          bottom: 13px;
          transform: rotate(-45deg);
          z-index: -1;
          // box-shadow: -1px -1px 1px #ccc;
        }
      }
    }
  }

  .MuiPaper-root {
    position: absolute;
    box-shadow: none;
    border: 1px solid $gray9;
    ul {
      // padding-right: 4px;
      // padding-left: 4px;
      li {
        font-size: 1.2rem;
        display: flex;
        background: $white;
        padding: 5px $defaultPadding;
        color: $gray7;
        justify-content: flex-start;
        &.active {
          pointer-events: none;
        }
        svg {
          margin-left: $marginHalf;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background: $gray9;
        }
      }
    }
  }
}
