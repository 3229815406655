@import 'styles/library.scss';

.DownloadReports {
  position: relative;
  margin-right: $marginHalf;
  padding-right: $paddingHalf;
  border-right: 1px solid $gray3;
  header {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: $primaryBlue;
    cursor: pointer;
    img {
      margin-right: $marginSmall;
    }
    .trigger-wrapper {
      margin: ($marginSmall * 0.5) 0 0 $marginSmall;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 30px;
      max-width: 120px;
      border-bottom: 1px solid $primaryBlue;
      position: relative;
      padding-left: $paddingHalf;
      color: $primaryBlue;
      user-select: none;
      cursor: pointer;
      svg {
        transition: transform 0.3s;
        margin-left: $marginSmall;
      }
    }
  }
  .reports-wrapper {
    background: $white;
    border-radius: $defaultBorderRadius;
    height: 0;
    width: 640px;
    position: absolute;
    top: 23px;
    right: 0;
    transition: all 0.3s;
    z-index: 0;
    overflow: hidden;
    opacity: 0;
    display: flex;
    &.open {
      z-index: 100;
      height: 360px;
      box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05),
        0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04),
        0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
      opacity: 1;
    }
    > div {
      &:first-child {
        flex: 2 0;
        background: $gray12;
        padding: $defaultPadding 0;
        box-sizing: border-box;

        li {
          display: flex;
          padding: 3px 0;
          align-items: center;
          margin-bottom: 12px;
          font-size: 12px;
          color: $gray5;
          font-size: 1.2rem;
          cursor: pointer;
          &.disabled {
            pointer-events: none;
            opacity: 0.4;
          }
          .RadioButton_scope {
            margin-right: $marginHalf;
          }
        }
      }
      &:last-child {
        flex: 5 0;
        padding: 12px 16px 12px 0;
        box-sizing: border-box;
        li {
          cursor: default;
        }
      }
      h6 {
        font-size: 1.2rem;
        margin: 0 0 $defaultMargin 0;
        border-bottom: 1px solid $gray9;
        padding-bottom: $paddingHalf;
        font-weight: 400;
        span {
          &.asm {
            text-transform: uppercase;
          }
          &.executive {
            text-transform: capitalize;
          }
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}
