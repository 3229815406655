@import 'styles/library.scss';

$boxDelay: 0.5s;

.LoginBox {
  width: 327px;
  // height: 293px;
  background: $white;
  position: relative;
  z-index: 1;
  // box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  border-radius: 33px;
  transform: scale(1.2);
  opacity: 0;
  transition: opacity 1s $boxDelay, transform 1s $boxDelay, box-shadow 0.3s, max-height 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 43px 37px;
  box-sizing: border-box;
  max-height: 500px;
  #AppLoading {
    opacity: 0;
    z-index: -1;
    transition: all 0.5s;
  }
  &.loading {
    max-height: 216px;
    #AppLoading {
      opacity: 1;
      z-index: 1000;
    }
    svg {
      margin: 0;
    }
  }
  a {
    text-align: center;
    color: $lightBlue2;
    font-size: 1.3rem;
    &:hover {
      text-decoration: underline;
    }
  }
  .animation & {
    transition-delay: 0;
    transform: scale(1);
    opacity: 1;
    box-shadow: 4.16667px 20px 68.3333px rgba(88, 134, 251, 0.38);
    > img {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
    .bottom {
      opacity: 1;
    }
    .main-form-area {
      opacity: 1;
    }
  }
  h6 {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0 0 $defaultMargin * 1.5 0;
  }
  > img {
    position: absolute;
    width: 158px;
    height: 45px;
    left: 50%;
    transform: translateX(-50%) translateY(60px);
    top: -133px;
    opacity: 0;
    transform-origin: 50%;
    transition: all 1s $boxDelay;
  }
  .main-form-area {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s $boxDelay;
    overflow: hidden;
    #AppLoading {
      box-shadow: 0 0 0 3px $white;
    }
  }
  .bottom {
    position: absolute;
    width: 100%;
    bottom: -100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 37px;
    box-sizing: border-box;
    flex-direction: column;
    opacity: 0;
    transition: opacity 1s $boxDelay;
    font-size: 1.2rem;
    > div {
      width: 100%;
      text-align: center;
      &.buttons {
        border-bottom: 1px solid $gray14;
        padding-bottom: $defaultPadding;
        margin-bottom: $defaultMargin;
        font-size: 1.2rem;
      }
      &.support {
        color: $gray18;
        font-size: 1.2rem;
        line-height: 140%;
        padding: 0 $defaultPadding * 1.5;
        box-sizing: border-box;
        a {
          color: darken($gray18, 10%);
          font-size: 1.2rem;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
