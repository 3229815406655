@import 'styles/library.scss';

.EditAccount {
  padding: 10px 0px 0 0;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  h2 {
    margin: 0 0 0 0;
    padding: 0;
    color: $gray7;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    img {
      margin-right: $marginHalf;
    }
  }
  .scrollbar-common {
    scrollbar-color: transparent transparent !important;
  }
  .scrollbar-common ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  .main-scrollable-area {
    overflow-y: auto;
    height: calc(100vh - 190px);
    position: relative;
  }
  h3 {
    font-weight: 500;
    font-size: 1.8rem;
    margin: $defaultMargin 0 $defaultMargin 0;
  }
  .headline {
    display: flex;
    align-items: center;
    margin: $defaultMargin * 1.5 0;
    h5 {
      font-size: 1.2rem;
      white-space: pre;
      margin: 0 20px 0 0;
      font-weight: 500;
      color: $black;
    }
    &:after {
      content: '';
      display: flex;
      width: 100%;
      height: 1px;
      background: #f5f6ff;
      flex-basis: 100%;
      flex: 1;
    }
  }
  .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $white;
    display: flex;
    align-items: center;
    padding: $defaultPadding;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 -4px 6px #eee;
    &.edit-mode {
      justify-content: flex-end;
      .deactivate-account {
        display: flex;
      }
      .edit-trigger {
        display: none;
      }
    }
    .Button {
      width: auto;
      min-width: 120px;
    }
    .deactivate-account {
      display: none;
      color: $red;
      font-size: 1.2rem;
      cursor: pointer;
    }
    .edit-trigger {
      display: flex;
      align-items: flex-end;
      color: $gray5;
      font-size: 1.2rem;

      cursor: pointer;
      img {
        margin-right: $marginSmall;
      }
    }
  }
}
