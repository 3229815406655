@import '../../../../styles/library.scss';

.pieChartLegend-scope {
  height: inherit;
  display: flex;
  flex-direction: column;
  margin-left: $defaultMargin * 2;
  justify-content: center;
}

.pieChartLegend-row-scope {
  margin-top: $defaultMargin;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  .summary-from-scope1 & {
    margin-top: 10px;
    font-size: 1rem;
  }
}

.pieChartLegend-percent-scope {
  display: flex;
  align-items: center;
}

.pieChartLegend-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: $marginHalf;
}

.pieChartLegend-number {
  font-weight: bold;
}

.pieChartLegend-header {
  font-size: 1.2rem;
  padding-left: $paddingHalf;
  color: $gray6;
}

.pieChartLegend-label {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  padding: 0 0 0 $paddingHalf;
  a {
    color: #7c8390;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.pieChartLegend-header-scope {
  display: flex;
  border-bottom: solid 1px $gray1;
  width: fit-content;
  padding-bottom: $paddingHalf;
}
