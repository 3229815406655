@import 'styles/library.scss';

li.Integration {
  width: 327px;
  border: 1px solid $gray1;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;

  &.inactive {
    background-color: #f9faff;
  }
  .integration-box-top {
    display: flex;
    align-items: center;

    h3 {
      color: $gray5;
      font-size: 1.2rem;
      margin: 0;
      font-weight: 700;
    }
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    min-height: 28px;
    button {
      margin-right: $defaultMargin;
    }
    a {
      font-size: 1.2rem;
      color: $primaryBlue;
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: underline;
      }
      &:after {
        content: '';
        width: 5px;
        height: 5px;
        border: 1px solid $primaryBlue;
        display: inline-block;
        border-bottom: 0;
        border-left: 0;
        transform: rotate(45deg);
        margin-left: 2px;
      }
    }
  }
  .integration-subtitle {
    margin: 15px 0 20px 0;
    color: $gray6;
    font-size: 1.2rem;
  }
}
