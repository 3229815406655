@import 'styles/library.scss';

.EditPlan {
  margin-top: 20px;
  h5 {
    font-size: 1.2rem;
    white-space: pre;
    margin: 0 20px 10px 0;
    font-weight: 500;
    color: $black;
  }
  .drop-down-label {
    display: none;
  }
  // ul {
  //   list-style-type: none;
  //   display: flex;
  //   padding: 0;
  //   flex-wrap: wrap;
  //   li {
  //     border: 1px solid $paleBlue;
  //     min-height: 70px;
  //     flex-basis: 96px;
  //     margin-right: $defaultMargin;
  //     margin-bottom: $defaultMargin;
  //     border-radius: 4px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     flex-direction: column;
  //     cursor: pointer;
  //     img {
  //       height: 20px;
  //     }
  //     &.prospect,
  //     &.research,
  //     &.inactive {
  //       margin-bottom: 0;
  //     }
  //     &.selected {
  //       background: $lighterBlue;
  //       border-color: $primaryBlue;
  //     }
  //     &:last-child {
  //       margin-right: 0;
  //     }
  //     p {
  //       padding: 0;
  //       margin: $marginHalf 0 0 0;
  //       color: $gray6;
  //       font-size: 1.2rem;
  //       user-select: none;
  //     }
  //   }
  // }
}
