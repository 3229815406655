.action-item-container {
  & .action-item-risk {
    padding-right: 10px;
    svg {
      transform: scale(1.6);
    }
  }
  & .action-item-urgency {
    padding-right: 22px;
    padding-bottom: 3px;
  }

  & .action-item-header-data {
    & .action-item-header-importance {
      // position: relative;
      // top: 0px;
      // left: -12px;
      svg {
        transform: scale(4);
        width: 10px;
        padding-left: 6px;
      }
    }

    & .action-item-header-host {
      padding-right: 12px;
      color: #8c909a;
    }

    & .action-item-header-type {
      border-left: 1px solid #eaeaea;
      padding: 0 12px;
      color: #8c909a;
    }

    & .action-item-header-groups {
      border-left: 1px solid #eaeaea;
      padding: 0 12px;
      color: #8c909a;
    }
    & .action-item-header-last-validated {
      &.border {
        border-left: 1px solid #eaeaea;
      }
      padding: 0 12px;
      color: #8c909a;
      &.padding-right {
        padding: 0 12px 0 0;
      }
    }
  }
}
