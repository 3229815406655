@import '../../../../styles/library.scss';

.notification-item {
  padding: 0 5px 0px 40px;
  flex-wrap: nowrap !important;

  border-bottom: 1px solid $gray1;

  &:hover {
    background-color: $lighterBlue;
    & .sticky-action {
      background-color: $lighterBlue;
    }
  }

  .channel-disabled {
    background-color: #fb5758;
    padding: 3px 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    width: fit-content;

    .info {
      svg {
        color: #ffffff;
      }
    }
  }

  & .notification-item-name-close {
    > div > div {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 1024px;
    }
  }
  & .notification-item-name-open {
    > div > div {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 600px;
    }
  }

  & .selected-notification-item {
    width: 6px;
    height: 74px;
    &.active {
      position: relative;
      left: -40px;
      background-color: #3455dd;
      margin-top: -1px;
      border-radius: 0 4px 4px 0;
    }
    &.integration-type {
      height: 104px;
    }
  }

  & .sticky-action {
    position: sticky;
    right: 10px;
    left: 0px;
    display: flex;
    flex-direction: row-reverse;
    height: 73px;
    align-items: center;
    margin-left: auto;

    .switch-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    & .notification-item-menu {
      & .icon-menu {
        cursor: pointer;
        svg {
          transform: scale(1.2);
          padding: 3px;
        }
        :hover {
          background-color: #f9faff;
        }
      }
    }
  }
}
