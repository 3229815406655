.discovery-integration-whois-org {
  & .MuiPaper-root {
    margin-top: 10px;
    max-height: 300px;
    min-width: 200px;
    overflow: hidden;
    border: 1px solid #ecedff;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05),
      0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04),
      0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
    & .options-wrapper {
      overflow-y: auto;
      max-height: 260px;
      & .option-item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        .popover-chip-item {
          border-radius: 4px;
          padding: 1px 3px;

          margin-left: 6px;
        }
      }
    }
  }
}

.DiscoveryPopoverListWithChips {
  display: flex;
  align-items: center;
  .popover-chip-item {
    border-radius: 4px;
    padding: 1px 3px;

    margin-left: 6px;
  }
  .more-chip {
    border-radius: 4px;
    padding: 3px 6px;
    margin-left: 8px;
    // multiline
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }
}
