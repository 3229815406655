.rotate {
  transform: rotate(180deg);
}

.urotate {
  transform: rotate(0deg);
}

.pointer {
  cursor: pointer;
}
