@import 'styles/library.scss';

.GroupRules {
  height: calc(100vh - 270px);
  display: flex;
  .scrollbar-common {
    flex: 1;
    display: flex;
    .popside-content {
      flex: 1;
      overflow: auto;
      .rules-wrapper {
        padding-right: 20px;
        padding-left: 60px;
      }
    }
  }
}
