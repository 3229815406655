@import '../../../../../../styles/library.scss';

.index-grade-scope {
  display: flex;
  span {
    display: inline-block;
    // margin-right: 2rem;
    width: 5rem;
  }
}
