@import 'styles/library.scss';

.ScanInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  position: relative;

  img {
    cursor: pointer;
  }

  .scan-info-content {
    position: absolute;
    bottom: -4px;
    right: -434px;
    width: 420px;
    padding: $defaultPadding;
    box-sizing: border-box;
    font-size: 1.2rem;
    background: $gray8;
    border: 1px solid $cpBlue;
    z-index: 9;
    border-radius: $defaultBorderRadius;
    opacity: 0;
    transform: scale(0);
    transform-origin: 0 100%;
    transition: all 0.3s;
    #AppLoading {
      background: transparent;
    }
    &.open {
      opacity: 1;
      transform: scale(1);
      min-height: 80px;
    }
    &:after {
      content: '';
      position: absolute;
      left: -8px;
      bottom: 10px;
      width: 14px;
      height: 14px;
      background: $gray8;
      border-top: 1px solid $cpBlue;
      border-left: 1px solid $cpBlue;
      transform: rotate(-45deg);
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      margin-bottom: $marginHalf;
      li {
        margin-bottom: $marginSmall;
        color: $gray4;
        span {
          color: $gray1;
          font-weight: $fontExtraBold;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .notice {
      color: $gray2;
    }
  }
}
