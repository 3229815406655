@import 'styles/library.scss';

.cp-toastr-stack {
  position: fixed;
  bottom: 0;
  z-index: 10000000;
  width: 100%;
}

.cp-toastr {
  position: relative;
  margin: 0;
  transition: all 0.2s linear;
  bottom: -100px;
}

.cp-show-toastr {
  bottom: 0;
}

.card-toastr {
  position: relative;
  display: flex;
  background: #2c2c30;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding: $defaultPadding;
  &.danger {
    background: $red;
  }
  &.success {
    background: $green;
  }
}

// .cp-toastr:hover {
//   border: solid 2px #fff;
// }

.img-toastr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 $marginHalf;
}

.section-text-toastr {
  color: white;
  overflow-y: auto;
  font-weight: bold;
  white-space: normal;
  overflow-x: hidden;
}

.close-toastr {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 2.3rem;
  transform: rotate(45deg);
  color: #ffffff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.cp-code-toastr {
  position: absolute;
  right: 8px;
  bottom: 3px;
  color: white;
  font-weight: bold;
}

.cp-toster-background {
  position: absolute;
  right: 0;
}
