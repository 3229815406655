@import 'styles/library.scss';

.InputField {
  width: 100%;
  margin-bottom: $defaultMargin;
  > input {
    width: 100%;
    border: 1px solid $gray14;
    border-radius: 4px;
    height: 36px;
    color: $gray15;
    padding: $paddingHalf;
    box-sizing: border-box;
    outline: 0;
    transition: border-color 0.3s;
    &:hover {
      border-color: darken($gray14, 15%);
    }
    &:focus {
      border-color: $lightBlue2;
    }
    &:disabled {
      color: lighten($gray15, 20%);
      background: $gray10;
    }
  }
  .InputField-Error {
    color: $red;
    margin-top: $marginSmall;
    text-align: left;
  }
}
