@import 'styles/library.scss';

.IntegrationWizard {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 10px;

  .WizardHeader {
    display: flex;
    align-items: center;
    padding-top: 8px;

    .wizard-title {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
    }
  }
  .WizardContent {
    height: 100%;
    overflow: hidden;

    .fields-content {
      padding: 24px 0;

      .cyberpion-ui-input {
        .icon-help-only {
          top: 27px !important;
        }
      }
      .DropDown {
        .MuiSelect-select {
          min-height: auto !important;
        }
      }
      .input {
        margin-top: $defaultMargin;
        font-size: 12px !important;

        label {
          padding: 0 5px;
          color: $gray4 !important;
          margin-left: -1px;
        }
        .MuiInputLabel-root {
          padding-left: 0;
        }
        label:not(.Mui-focused):not(.MuiFormLabel-filled) {
          transform: translate(14px, 13px) scale(1);
          color: red;
          font-size: 1.1rem;
        }
        label.MuiFormLabel-filled:not(.Mui-focused) {
          color: $gray19;
          background: $white;
        }
        label.Mui-focused {
          color: $gray19;
          background: $white;
        }
        label.MuiInputLabel-animated {
          transform: translate(14px, -7px) scale(1);
          font-size: 1rem;
        }
        input {
          padding: 12px;
          outline: 0;
          color: $gray19;
          font-size: 13px;
        }
        fieldset {
          border-color: $paleBlue;
          border-width: 1px;
        }
      }
    }
  }
  .WizardFooter {
    display: flex;
    margin-top: 16px;
    // margin-right: 16px;
    justify-content: flex-end;

    .btn-next {
      margin-left: 10px;
    }
  }
}
