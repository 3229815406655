@import 'styles/library.scss';

.ExposureReportOrgScore {
  .overall_score {
    display: flex;
    align-items: center;
    padding: 48px 40px 24px 72px;
  }
  .all-scores {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 740px;

    .org-score-item {
      display: flex;
      align-items: center;
      padding: 20px;
      padding: 24px 32px 24px 82px;

      &.vertical-border {
        border-right: 1px solid #e0e0e0;
      }
      &.top-border {
        border-top: 1px solid #e0e0e0;
      }
      &.padding {
        padding-left: 60px;
      }
    }
  }
}
