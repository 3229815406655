@import '../../../../../../../styles/library.scss';

.moreOptions_scope {
  position: absolute !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.moreOptions_list {
  box-shadow: 0px 4px 10px rgba(24, 29, 78, 0.1);
  position: absolute;
  background: #ffffff;
  z-index: 1;
  top: 30px;
  right: 0;
  width: 220px;
  padding-right: 2px;
  > div:first-child {
    padding: $defaultPadding;
    border-bottom: solid 1px #f5f5f5;
  }
  .select-items-scope {
    padding: $defaultPadding;
  }
}

.moreOptions_add-more {
  display: flex;
  width: 100%;
  justify-content: center;
  color: $gray5;
  font-size: 1.2rem;
  align-items: center;
  button {
    font-weight: $fontNormal;
  }
  > div:first-child {
    margin-right: 2px;
    font-weight: 400;
  }
}
