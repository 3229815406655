@import 'styles/library.scss';

li.GroupsList,
li.SubsidiariesList {
  margin: $defaultMargin 0;
  font-size: 1.2rem;
  color: $gray5;
  > div:first-child {
    display: flex;
    justify-content: space-between;
  }
  a {
    color: $primaryBlue;
  }
  ul {
    list-style-type: none;
    margin: $marginHalf 0 0 0;
    padding: 0;
    max-height: 86px;
    overflow: auto;
    li {
      margin-bottom: $marginSmall;
      cursor: pointer;
      display: flex;
      align-items: center;
      .RadioButton_scope {
        margin-right: $marginSmall;
      }
      &:last-child {
        margin-bottom: 0;
      }
      span {
        display: inline-block;
        max-width: 230px;
        word-break: break-word;
      }
    }
  }
}
