@import 'styles/library.scss';

.GroupFilter {
  position: relative;
  header {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: $gray5;
    img {
      margin-right: $marginSmall;
    }
    .trigger-wrapper {
      margin: ($marginSmall * 0.5) 0 0 $marginSmall;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 30px;
      max-width: 120px;
      border-bottom: 1px solid $primaryBlue;
      position: relative;
      padding-left: $paddingHalf;
      color: $primaryBlue;
      user-select: none;
      cursor: pointer;
      svg {
        transition: transform 0.3s;
        margin-left: $marginSmall;
      }
    }
  }
  > div {
    background: $white;
    border-radius: $defaultBorderRadius;
    padding: $paddingSmall $paddingHalf;
    max-height: 0;
    width: 200px;
    position: absolute;
    top: 23px;
    right: 0;
    transition: all 0.3s;
    z-index: 0;
    overflow: hidden;
    opacity: 0;
    &.open {
      z-index: 100;
      max-height: 600px;
      box-shadow: 0 1px 5px $gray4;
      opacity: 1;
    }
    .parent-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    .buttons-wrapper {
      border-top: 1px solid $gray2;
      padding: $paddingHalf 0;
      display: flex;
      button {
        flex: 1 0;
        &:first-child {
          margin-right: $marginSmall;
        }
        &:last-child {
          margin-left: $marginSmall;
        }
      }
    }
  }
}
