@import 'styles/library.scss';

.SeedData {
  width: 1000px;
  position: relative;
  margin: 0 auto;
  color: #60646a;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  .Header,
  .SubHeader {
    flex: 0;
  }
  .MainSeedFlow {
    flex: 1;
  }

  // &.fixed {
  //   .MainSeedFlow {
  //     position: absolute;
  //     top: 0;
  //     margin-top: 0;
  //     width: calc(100% - 2px);
  //     background: white;
  //     height: 100%;
  //   }
  // }
  // .main-scrollable-area {
  //   overflow-y: auto;
  //   height: 100vh;
  //   position: relative;
  // }
  // .scrollbar-common {
  //   scrollbar-color: transparent transparent !important;
  // }

  // .scrollbar-common ::-webkit-scrollbar-thumb {
  //   background-color: transparent !important;
  //   cursor: pointer;
  //   border-radius: 2px !important;
  // }

  // .scrollbar-common ::-webkit-scrollbar-track {
  //   background: transparent !important;
  //   border-radius: 2px !important;
  // }
}
