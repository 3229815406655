@import 'styles/library.scss';

.collapse-fields-title {
  padding: 10px 25px;
  font-weight: 500;
  color: #454549;
  background-color: #f9faff;
  font-size: 1.2rem;
}
.collapse-fields-field {
  color: #8c909a;
  padding-left: 25px;
}
.collapse-fields-text {
  color: #454549;
  padding-top: 5px;
  font-weight: 200;
  padding-left: 25px;
}
.collapse-fields-inner-field {
  color: #8c909a;
  padding-left: 20px;
  margin-left: 28px !important;
  padding-bottom: 0px;
  border-left: 1px solid #eaeaea;
}
.collapse-fields-inner-text {
  color: #454549;
  padding-top: 0px;
  font-weight: 200;
  padding-left: 20px;
  margin-left: 28px !important;
  border-left: 1px solid #eaeaea;
}
.CollapseFields {
  .loading {
    position: relative;
    min-height: 40px;
    margin-left: -$defaultMargin !important;
    svg {
      margin: 0 !important;
    }
  }
}
