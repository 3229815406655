@import '../../../../../styles/library.scss';

.notification-view-panel {
  height: 100%;
  padding: 24px 0px 24px 0;

  & .edit-mode {
    cursor: pointer;

    &:hover {
      color: $lightBlue2;
      > svg {
        fill: $lightBlue2;
      }
    }
  }
  & .view-panel-content {
    height: 100%;
    width: 515px;
    overflow-y: scroll;
  }
  & .notification-properties {
    overflow: hidden;
    flex-wrap: nowrap;
    padding-top: 8px;

    & .divider {
      height: 1px;
      background-color: $gray1;
      padding: 0;
    }

    & .chips-list {
      padding-top: 8px;

      & .MuiChip-root {
        border-radius: 4px;
        height: 22px;
        margin: 0;
        margin-right: 8px;
        margin-bottom: 10px;

        & .MuiChip-label {
          padding: 3px 7px;
          background-color: $gray13;
          color: $gray5;
          font-size: 13px;
        }
      }
    }
  }
}
