@import 'styles/library.scss';

.HelpCenter {
  position: fixed;
  top: -50px;
  right: 12px;
  background: #5886fb;
  border-radius: 0 0 4px 4px;
  padding: 5px 12px;
  z-index: 2;
  font-size: 1.2rem;
  color: $white;
  transition: top 0.5s ease 2s, background-color 0.5s ease 0s;
  display: block;
  &.move-to-bottom {
    z-index: -1;
  }
  &:hover {
    background-color: darken(#5886fb, 8%);
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.visible {
    top: 0;
  }
  img {
    margin-right: 6px;
    width: 12px;
    height: 12px;
  }
}
