@import 'styles/library.scss';

.SidePanel {
  width: 250px;
  height: 100%;
  color: #8c909a;
  font-size: 1.4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: -195px;
  box-sizing: border-box;
  background: $gray8;
  border-radius: 0 $borderRadiusBig $borderRadiusBig 0;
  filter: drop-shadow(0 0.2rem 0.63rem rgba(24, 29, 78, 0.1));
  transition: margin-left 0.3s;
  justify-content: space-between;
  padding: $defaultPadding;
  z-index: 10;
  &.open {
    margin-left: 0;
    ul {
      opacity: 1;
    }
  }
  .collapse-wrapper {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: -8px;
  }

  > ul {
    list-style-type: none;
    padding: 0;
    opacity: 0;
    transition: opacity 0.4s;
    li {
      margin-bottom: $defaultMargin;
      color: $gray1;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $gray1;
        &.disabled {
          color: $gray7;
          pointer-events: none;
        }
        &.active {
          color: $darkBlue;
          background: #3e4156;
          padding: $paddingSmall;
          border-radius: $borderRadiusSmall;
          display: inline-block;
        }
      }
      &:first-child {
        // margin-bottom: 0;
        ul:first-child {
          margin-bottom: 0;
        }
      }

      ul {
        margin: $defaultMargin 0;
        padding-left: $defaultPadding;
        list-style-type: none;
        li {
          margin-bottom: $marginHalf;
          a {
            color: $gray1;
            &.disabled {
              color: $gray7;
              // opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
